/**
 * All static text in the portal
 */
export const TextResources = {
  // Generic
  NORNE_LABEL: "Norne",
  LOG_IN_PAGE_TITLE: "Logg inn",
  LOG_OUT_PAGE_TITLE: "Du er nå logget ut",
  LOG_OUT_LOGIN_AS_ADVISOR: "Logg inn som rådgiver",

  // Menu
  MAIN_MENU_MY_PAGES_HEADER: "Mine sider",
  MAIN_MENU_FUND_HEADER: "Fond",
  MAIN_MENU_STOCK_HEADER: "Aksjer",
  MAIN_MENU_ACCOUNT_HEADER: "Konto",
  MAIN_MENU_OTHER_HEADER: "Annet",

  MENU_DASHBOARD: "Min side",
  MENU_EQUITIES: "Min beholdning",
  MENU_HISTORIC_EQUITIES: "Historisk beholdning",
  MENU_FUNDS: "Alle fond",
  MENU_ORDERS: "Ordre",
  MENU_HISTORIC_ORDERS: "Historiske aksjeordre",
  MENU_TRANSACTIONS: "Transaksjoner",
  MENU_LEDGER_TRANSACTIONS: "Pengetransaksjoner",
  MENU_ROBOT: "Sparerobot",
  MENU_CREATE_AGREEMENT: "Opprett spareavtale",
  MENU_PORTFOLIOS: "Kontooversikt",

  MENU_STOCK_LIST: "Aksjeliste",
  MENU_STOCK_LIST_NOTC: "Unoterte aksjer",
  MENU_TRADER_DASHBOARD_FROM_FUNDS: "Aksjehandel",
  MENU_TRADER_DASHBOARD: "Markedsoversikt",
  MENU_TRADER_NEWS: "Nyheter",
  MENU_TRADER_CALENDAR: "Finanskalender",
  MENU_TRADER_REPORTS: "Analyserapporter",
  MENU_TRADER_FAVORTIES: "Favoritter",
  MENU_TRADER_ALARMS: "Alarmer",
  MENU_SAVINGS_ROBOT: "Sparerobot",
  MENU_EPK: "Egen pensjonskonto",
  MENU_UNLISTED_SECURITIES: "Unoterte aksjer og egenkapitalbevis",

  MENU_SWITCH_USER: "Bytt bruker",
  MENU_SWITCH_USER_LOADING: "Bytter bruker...",
  MENU_MAILBOX: "Postkasse",
  MENU_TRANSFER_WITHDRAWAL: "Uttak",
  MENU_TRANSFER_WITHDRAWAL_AGREEMENT: "Uttaksavtale",
  MENU_TRANSFER_DEPOSIT: "Innskudd",
  MENU_MY_PROFILE: "Min profil",
  MENU_DOCUMENTS: "Dokumenter",
  MENU_SIGNING: "Signering",
  MENU_POA: "Fullmakter",
  MENU_EXIT_IMPERSONATION: "Avslutt kundesesjon",
  MENU_LOGOUT: "Logg ut",
  MENU_COSTS: "Kostnader",
  MENU_MOVE_ASK: "ASK flytt",
  MENU_MOVE_ASK_OVERVIEW: "Aktive ASK flyttinger",
  MENU_ASK_DETAILS: "ASK detaljer",
  MENU_FUND_AS_GIFT: "Fond i gave",
  MENU_PENSION: "Pensjon",
  MENU_SUBSCRIPTIONS: "Mine abonnementer",
  MENU_NEW_ACCOUNT: "Registrer ny bankkonto",
  MENU_CREATE_ASK: "Opprett ASK",
  MENU_CREATE_ACCOUNT: "Opprett verdipapirkonto",
  MENU_HOLDINGS_TRANSFER: "Overføring av verdipapir (aksjer)",
  MENU_IPS_TRANSFER: "Flytting av IPS",

  // Footer Norne
  FOOTER_NORNE_CONTACT_HEADER: "Kontakt oss",
  FOOTER_NORNE_OPEN_HOURS: "Mandag - fredag 08:30 - 16:30",
  FOOTER_NORNE_PHONE_NUMBER: "55 55 91 30",
  FOOTER_NORNE_EMAIL: "kundeservice@norne.no",
  FOOTER_NORNE_ABOUT_LINK: "Om Norne",
  FOOTER_NORNE_NAME: "Norne Securities AS",
  FOOTER_NORNE_ADDRESS: "Postboks 7801 | 5020 Bergen",
  FOOTER_NORNE_ORG_NUMBER: "Org.nr: 992.881.828",

  // Footer Sparebanken Møre
  FOOTER_SBM_CONTACT_HEADER: "Kontakt Kundeservice Sparebanken Møre",
  FOOTER_SBM_OPEN_HOURS: "Mandag - fredag 08:00 - 20:00",
  FOOTER_SBM_PHONE_NUMBER: "70 11 30 00",

  // Equities
  EQUITIES_NO_STOCKS: "Ingen aksjer",
  EQUITIES_NO_FUNDS: "Ingen fond",
  EQUITIES_TRADE: "Handle",
  EQUITIES_FROM_LABEL: "Fra",
  EQUITIES_TO_LABEL: "Til",

  // Orders
  ORDER_DETAILS: "Ordredetaljer",
  ORDER_NO_ACTIVE: "Ingen aktive ordre",
  ORDER_NUMBER: "Ordrenummer",
  ORDER_DATE: "Ordredato",
  ORDER_FUND_OR_STOCK: "Fond/Aksje",
  ORDER_ACCEPTED: "Akseptert",
  ORDER_NOT_ACCEPTED: "Ikke godkjent",
  ORDER_MANUAL: "Manuell",
  ORDER_PENDING: "Venter",
  ORDER_COMPLETED: "Fullført",
  ORDER_CANCELLED: "Kansellert",
  ORDER_UNKNOWN: "Ukjent",
  ORDER_EXPIRED: "Utløpt",
  ORDER_FUTURE: "Fremtidig",
  PENDING_MATCH: "Venter på match",
  EXTERNAL_COVERAGE: "Ekstern dekning",
  ORDER_REQUIRES_APPROVAL: "Venter på godkjenning",
  ORDER_CUT_OFF_LABEL: "Cut-off",
  ORDER_SEARCH_LABEL: "Søk",
  ORDER_STATUS: "Status",
  ORDER_HISTORIC_NAVIGATE: "Gå til Historiske aksjeordre",
  ORDER_NOT_FOUND: "Det oppstod en feil ved henting av din ordre. Vennligst last siden på nytt.",

  // Stocks
  STOCK_ORDER_STOCKS_LABEL: "Aksje",
  STOCK_ORDER_TICKER_LABEL: "Aksje",
  STOCK_ORDER_ORDER_NUMBER_LABEL: "Ordrenummer",
  STOCK_ORDER_TYPE_LABEL: "Type",
  STOCK_ORDER_STATUS_LABEL: "Status",
  STOCK_ORDER_ACCOUNT_LABEL: "Konto",
  STOCK_ORDER_SHARES_LABEL: "Antall",
  STOCK_ORDER_VALID_UNTIL_LABEL: "Utløpsdato",
  STOCK_ORDER_QUANTITY_LABEL: "Antall",
  STOCK_ORDER_REST_LABEL: "Gjenværende",
  STOCK_ORDER_LIMIT_LABEL: "Limit",
  STOCK_ORDER_LAST_LABEL: "Siste",
  STOCK_ORDER_CHANGE_FROM_LIMIT_LABEL: "+/-",
  STOCK_ORDER_STOP_LOSS_LABEL: "Stop loss trigger",
  STOCK_ORDER_ORDER_DATE_LABEL: "Ordredato",
  STOCK_ORDER_CUT_OFF_LABEL: "Cut-off",
  STOCK_ORDER_SHARES_REST_LABEL: "Rest",
  STOCK_ORDER_EXPIRY_LABEL: "Utløpsdato",
  STOCK_DETAILS_UPDATE_ORDER: "Endre",
  STOCK_DETAILS_ORDER_DELETE: "Slett",
  STOCK_DETAILS_ORDER_NOTIFY: "Varsle",
  STOCK_DETAILS_ORDER_REMOVE_NOTIFY: "Fjern varsel",
  STOCK_TRADE_LABEL: "Handle aksjer",
  STOCK_TOTAL: "Total",
  STOCK_ORDER_LABEL: "Aksjeordre",
  STOCK_TICKER_LABEL: "Ticker",
  STOCK_AVERAGE_PRICE: "Snittkurs",
  STOCK_QUANTITY: "Antall",
  STOCK_PRICE: "Kurs (NOK)",
  STOCK_PRICE_LABEL: "Kurs",
  STOCK_CHANGE_TODAY: "+/- i dag",
  STOCK_MARKET_VALUE_CHANGE_TODAY: "Markedsverdi +/- i dag",
  STOCK_COST_PRICE: "Kostpris",
  STOCK_MARKET_VALUE: "Markedsverdi",
  STOCK_VALUE: "Verdi",
  STOCK_MARGIN: "Margin",
  STOCK_MARGIN_RATE: "Margin grad",
  STOCK_KEY_INFO: "Nøkkelinformasjon",
  STOCK_TRANSACTIONS: "Transaksjoner",
  STOCK_SHARES_LABEL: "Andeler",
  STOCK_LABEL: "Aksjer",
  STOCK_MARKET_VALUE_LABEL: "Verdi",
  STOCK_REALIZED_LABEL: "Realisert",
  STOCK_UNREALIZED_LABEL: "Urealisert",
  STOCK_UNREALIZED_PERCENTAGE_LABEL: "Urealisert(%)",
  STOCK_NAME_LABEL: "Navn",
  STOCK_PORTFOLIO_LABEL: "Konto",
  STOCK_BUY_LABEL: "Kjøp",
  STOCK_SELL_LABEL: "Selg",

  // Equities
  EQUITIES_HEADING: "Min beholdning",
  EQUITIES_HISTORIC_NAVIGATE: "Gå til Historisk beholdning",
  EQUITIES_HISTORIC: "Historisk beholdning",
  EQUITIES_POSITIONS_CLOSED: "Vis avsluttede posisjoner",
  EQUITIES_SUM: "Sum",
  EQUITIES_ACCOUNT_LABEL: "Konto",

  // Transactions
  TRANSACTIONS: "Transaksjoner",
  TRANSACTIONS_SELECT: "Velg en konto i nedtrekkslisten for å se transaksjoner",
  TRANSACTIONS_EMPTY: "Ingen transaksjoner",
  TRANSACTIONS_EXECUTED: "Utført",
  TRANSACTION_DOWNLOAD_EXCEL_REPORT: "Eksporter",
  TRANSACTIONS_SEARCH_LABEL: "Fond/aksje",
  TRANSACTIONS_DISPLAY_TEC_TRANSACTIONS_LABEL: "Vis tekniske transaksjoner",
  TRANSACTIONS_NAME_LABEL: "Navn",
  TRANSACTIONS_PRICE_LABEL: "Kurs",
  TRANSACTIONS_RATE_DATE_LABEL: "Kursdato",
  TRANSACTIONS_AMOUNT_LABEL: "Beløp",
  TRANSACTIONS_CASH_ACCOUNT_LABEL: "Bankkonto:",
  TRANSACTIONS_FROM_LABEL: "Fra",
  TRANSACTIONS_TO_LABEL: "Til",
  TRANSACTIONS_TYPE_LABEL: "Type",
  TRANSACTIONS_ACCOUNT_LABEL: "Konto",
  TRANSACTIONS_QUANTITY_LABEL: "Antall",
  TRANSACTIONS_ISIN_LABEL: "ISIN",
  TRANSACTIONS_TRANSACTION_ID_LABEL: "Transaksjonsnummer",
  TRANSACTIONS_TRANSACTION_DETAILS_TITLE: "Transaksjonsdetaljer",
  TRANSACTIONS_LEDGER_TRANSACTIONS_LINK_TEXT: "Pengetransaksjoner, inklusive utbytter",

  LEDGER_TRANSACTIONS: "Pengetransaksjoner",
  LEDGER_TRANSACTIONS_ACCOUNT_LABEL: "Konto",
  LEDGER_TRANSACTIONS_FROM_LABEL: "Fra",
  LEDGER_TRANSACTIONS_TO_LABEL: "Til",
  LEDGER_TRANSACTIONS_EXECUTED: "Utført",
  LEDGER_TRANSACTIONS_TYPE_LABEL: "Type",
  LEDGER_TRANSACTIONS_STOCK_FUND_LABEL: "Fond/Aksje",
  LEDGER_TRANSACTIONS_AMOUNT_LABEL: "Beløp",

  // Fund transactions
  INSTRUMENT_TRANSACTION_GO_TO_TRANSACTIONPAGE: "Åpne i transaksjonsvisning",
  INSTRUMENT_TRANSACTION_GET_TRANSACTIONS_FAILED: "Kunne ikke hente transaksjoner. Vennligst prøv igjen senere.",
  INSTRUMENT_TRANSACTIONS_MISSING_PORTFOLIO_ID_OR_INSTRUMENT_ID: "En feil oppstod. Vennligst prøv igjen senere.",

  ASK_NET_DEPOSIT_AMOUNT_LABEL: "Netto innskutt beløp",
  ASK_NET_DEPOSIT_AMOUNT_HELP_TEXT:
    "Netto innskutt viser summen av alle innskudd/kjøp på aksjesparekontoen minus alle uttak. Beløpet viser negativt dersom du har hatt større uttak enn innskudd",

  ACCOUNT_MANAGED_RISK_LEVEL_LABEL: "Risikoprofil",
  ACCOUNT_MANAGED_RISK_LEVEL_HELP_TEXT: "Risikoprofilen viser hvilken risikoprofil du har valgt for din forvaltede portefølje",

  // Funds
  FUND: "Fond",
  FUND_LIST: "Fondsliste",
  FUND_ACCOUNT_LABEL: "Konto",
  FUND_TYPE_LABEL: "Type",
  FUND_COST_PRICE: "Kostpris",
  FUND_COST_PRICE_HELP_TEXT:
    "Dette er beløpet du har kjøpt aksjer og fond for. Kostpris/kjøpesum er det du har betalt for aksjene og fondsandelene du eier idag inkludert eventuelle kjøpsomkostninger. Dersom du foretar/har foretatt fondsbytter er ikke kostpris lik opprinnelig innskutt beløp. Om noen av porteføljene er flyttet inn viser kostpris verdien på flyttetidspunktet",
  FUND_COST: "Årlig fondskostnad",
  FUND_CASH: "Egenkapital",
  FUND_CASH_WITHDRAW: "Ta ut kontanter",
  FUND_OUTSIDE_ASK: "Fond/Aksjer utenfor ASK",
  FUND_TAX_FREE: "Skattefritt uttak",
  FUND_BUY_LABEL: "Kjøp",
  FUND_SELL_LABEL: "Selg",
  FUND_BUY: "Kjøp fond",
  FUND_SELL: "Selg fond",
  FUND_SELECT: "Velg fond",
  FUND_SWITCH: "Bytt fond",
  FUND_SWITCH_ALL: "Bytt alt",
  FUND_SELL_ACCOUNT: "Selg fra konto",
  FUND_SELL_ALL: "Selg alt",
  FUND_DEPOSIT: "Innskudd",
  FUND_AGREEMENTS: "Spareavtaler",
  FUND_OVERVIEW: "Oversikt",
  FUND_RETURN: "Avkastning",
  FUND_ETC: "Pris og bærekraft",
  FUND_PRICE: "Kurs (NOK)",
  FUND_PRICE_LABEL: "Kurs",
  FUND_KEY_INFO: "Nøkkelinformasjon",
  FUND_TRANSACTIONS: "Transaksjoner siste år",
  FUND_DATE: "Kursdato",
  FUND_CHANGE_TODAY: "+/- i dag",
  FUND_ORDER: "Fondsordre",
  FUND_SFDR: "SFDR",
  FUND_SFDR_HELP_TEXT_1: "Artikkel 9 fond: Hensyntar bærekraft gjennom konkrete målsetninger.",
  FUND_SFDR_HELP_TEXT_2:
    "Artikkel 8 fond: Hensyntar bærekraft gjennom fondets egenskaper: fremme miljømessige eller sosiale egenskaper.",
  FUND_SFDR_HELP_TEXT_3:
    "Artikkel 6 fond: Fremmer ikke miljømessige eller sosiale egenskaper. Det finansielle produktets underliggende investeringer tar ikke hensyn til EU-kriteriene for miljømessig bærekraftige økonomiske aktiviteter.",
  FUND_INDEX: "Indeks",
  FUND_GROUP: "Fondsgruppe",
  FUND_MONTH_1: "1 mnd",
  FUND_MONTH_12: "12 mnd",
  FUND_YEAR_3: "3 år (gj snitt)",
  FUND_YEAR_5: "5 år (gj snitt)",
  FUND_YEAR_10: "10 år (gj snitt)",
  FUND_NAV_DATE: "Kursdato",
  FUND_TODAY: "i dag",
  FUND_YEAR_TO_DATE: "hittil i år",
  FUND_THIS_YEAR: "1 år",
  FUND_ACCOUNT_NORNE: "Trekk fra konto hos Norne?",
  FUND_ACCOUNT_WITHDRAW: "Velg konto beløpet skal trekkes fra",
  FUND_ACCOUNT_WITHDRAW_MANUAL: "Skriv inn kontonummeret beløpet skal trekkes fra",
  FUND_ACCOUNT_OTHER: "Annen konto",
  FUND_ACCOUNT_SELL: "Velg konto beløpet skal overføres til",
  CHOOSE_BANKACCOUNT_LABEL: "Velg bankkonto",
  FUND_CONFIRM: "Bekreft kjøp",
  FUND_SAVE: "Spares i",
  FUND_RECEIPT_HEADER: "Kjøpsordre er sendt",
  FUND_RECEIPT_INFO_TEXT: "Ordren er sendt til fondsforvalter. Du kan følge med på statusen i ordreoversikten.",
  FUND_RECEIPT_STATUS_LABEL: "Status",
  FUND_RECEIPT_AMOUNT_LABEL: "Beløp",
  FUND_RECEIPT_FUND_LABEL: "Fond",
  FUND_RECEIPT_TO_ORDER_LIST_LINK_TEXT: "Gå til ordreoversikt",
  FUND_FILTER: "Filtre",
  FUND_FILTER_POPULAR: "Populære filtre",
  FUND_FILTER_ACTIVE: "Aktive filtre",
  FUND_SELECTED: "Utvalgte fond",
  FUND_TRADER: "Forvalter",
  FUND_REGION: "Region",
  FUND_FEE: "Forvaltningshonorar",
  FUND_FEE_NET: "Netto forvaltningshonorar",
  FUND_FEE_RETURN: "Tilbakeført av forvaltningshonorar",
  FUND_BANK_FEE: "Honorar til bank/Norne",
  FUND_BANK_FEE_HELP_TEXT: "Honorar til bank/Norne er angitt for din standardkonto. Honoraret kan variere for andre kontoer.",
  FUND_BANK_FEE_HELP_TEXT_PORTFOLIO_LABEL: "Velg konto for å se honorar for andre kontoer",
  FUND_CUSTOMER_PRICE: "Årlig kundepris",
  FUND_PRIIP_KIID_LABEL: "Nøkkelinformasjon fra forvalter ",
  FUND_FACT_SHEET_LABEL: "Faktaark",
  FUND_AMOUNT_NORNE: "Få beløpet på konto hos Norne?",
  FUND_ORDER_CREATED: "Salgsordre opprettet",
  FUND_SWITCH_ORDER_CREATED: "Bytteordre opprettet",
  FUND_EQUALS: "Tilsvarer",
  FUND_LIST_SHOW_MORE: "Vis mer",
  FUND_SWITCH_CHOOSE_FUND_TEXT: "Velg fond i listen under",
  FUND_CONFIRM_SELL: "Bekreft salgsordre",
  FUND_SELL_OPTION_A: "10%",
  FUND_SELL_OPTION_B: "25%",
  FUND_SELL_OPTION_C: "50%",
  FUND_SELL_OPTION_D: "Selg alt",
  FUND_START_BUY: "Du har ingen fond. Ønsker du å starte med fondssparing?",
  FUND_MARKET_VALUE_LABEL: "Verdi",
  FUND_REALIZED_LABEL: "Realisert",
  FUND_UNREALIZED_LABEL: "Urealisert",
  FUND_UNREALIZED_PERCENTAGE_LABEL: "Urealisert(%)",
  FUND_AMOUNT_LABEL: "Beløp",
  FUND_KEYDATA_FOOTER_1: "Kostnaden vil ha effekt på avkastningen.",
  FUND_KEYDATA_FOOTER_2:
    "Eksempel: med 10.000kr investert i et fond med årlig avkastning på 8 % / 800 kroner og årlig kundepris på 1 % / 100 kroner, vil årlig avkastningen til kunde være 7 % / 700 kr.",

  FUND_NORNE_AKSJE_NORGE_ATTENTION_TEXT_1: "Norne Aksje Norge er et algoritmestyrt norsk aksjefond.",
  FUND_NORNE_AKSJE_NORGE_ATTENTION_TEXT_2:
    "Lavkost og datadrevet aksjeplukking på Oslo Børs, med over 15% meravkastning mot fondsindeksen siden oppstart av fondet i 2021.",

  FUND_SELL_ORDER_HEADER: "Salgsordre er sendt",
  FUND_SELL_ORDER_INFO_TEXT: "Ordren er sendt til fondsforvalter. Du kan følge med på statusen i ordreoversikten.",
  FUND_SELL_ORDER_SHARES_LABEL: "Antall andeler",
  FUND_SELL_ORDER_STATUS_LABEL: "Status",
  FUND_SELL_ORDER_FUND_LABEL: "Fond",
  FUND_SELL_ORDER_ORDER_LIST_LINK_TEXT: "Gå til ordreoversikt",
  FUND_SELL_ORDER_DISCLAIMER_TEXT:
    "Salgskursen som oppnås er ukjent på handelstidspunktet, og faktisk utbetalt beløp kan derfor avvike noe fra ordrebeløpet.",
  FUND_SELL_ORDER_NOT_AVAILABLE_FOR_IPS_TEXT: "Salg av fond er ikke tilgjengelig for IPS-kontoer.",

  FUND_LIST_FEE: "Forvaltnings-honorar",
  FUND_LIST_FEE_WIDE: "Forvaltningshonorar",
  FUND_LIST_FEE_RETURN: "Tilbakeført av forvaltnings-honorar",
  FUND_LIST_FEE_RETURN_WIDE: "Tilbakeført av forvaltningshonorar",
  FUND_LIST_BANK_FEE: "Honorar til bank/Norne",
  FUND_LIST_FEE_NET: "Forvaltnings-kostnad",
  FUND_LIST_CUSTOMER_FEE: "Årlig kundepris",
  FUND_LIST_KIID: "KIID",
  FUND_LIST_FACT_SHEET: "Faktaark",
  FUND_LIST_SUSTAINABILITY: "Bærekraft",
  FUND_FILTER_INDEX_LABEL: "Indeks",

  FUND_CUSTOMER_FEE_HELP_TEXT:
    "Årlig kundepris kan avhenge av om du har investert over eller under 1 mill / 10 mill. Satsen som vises her er for en investering i fondet dersom investeringen gjøres fra din først opprettede konto.",

  FUND_AGREEMENT_HEADER: "Fond i denne spareavtalen",
  FUND_AGREEMENT_INSTRUCTION: "Velg beløp for fondene dine. Minstebeløp for en spareavtale er totalt 250 NOK.",
  FUND_AGREEMENT_USER_MESSAGE: "Minstebeløp for en spareavtale er totalt 250 NOK.",
  FUND_TOTAL: "Total",
  FUND_SWITCH_LABEL: "Bytt",

  FUND_ORDER_FUND_LABEL: "Fond",
  FUND_ORDER_ORDER_NUMBER_LABEL: "Ordrenummer",
  FUND_ORDER_TYPE_LABEL: "Type",
  FUND_ORDER_STATUS_LABEL: "Status",
  FUND_ORDER_ACCOUNT_LABEL: "Konto",
  FUND_ORDER_CASH_ACCOUNT_LABEL: "Bankkonto",
  FUND_ORDER_SHARES_LABEL: "Andeler",
  FUND_ORDER_AMOUNT_LABEL: "Beløp",
  FUND_ORDER_ORDER_DATE_LABEL: "Ordredato",
  FUND_ORDER_CUT_OFF_LABEL: "Cut-off",
  FUND_ORDER_DELETE: "Slett",

  // Fund order status
  FUND_ORDER_ACTIVE: "Aktiv",
  FUND_ORDER_SETTLED: "Under behandling",
  FUND_ORDER_CANCELLED: "Kansellert",
  FUND_ORDER_SENT_TO_PAYMENT_AGENT: "Sendt til betalingsagent",
  FUND_ORDER_EXECUTED: "Under behandling",
  FUND_ORDER_REJECTED: "Avvist",
  FUND_ORDER_EXPIRED: "Utløpt",
  FUND_ORDER_DELETED: "Slettet",
  FUND_ORDER_CHANGED: "Endret",
  FUND_ORDER_REMOVED_FROM_PARENT_ORDER: "Fjernet fra hovedordre",
  FUND_ORDER_AWAITING_PRICE_FROM_EXTERNAL_FUND_MANAGER: "Venter på pris fra ekstern fondsforvalter",
  FUND_ORDER_DEPENDEND_ON_ANOTHER_ORDER: "Avhengig av annen ordre",
  FUND_ORDER_DEACTIVATED: "Deaktivert",
  FUND_ORDER_EXECUTING_LIQUIDATION_ORDER: "Utfører avviklingsordre",
  FUND_ORDER_UNKNOWN: "Ukjent",

  // Accounts
  ACCOUNTS_LABEL: "Kontoer",
  ACCOUNT_LABEL: "Verdipapirkonto",
  ACCOUNT_ASK_LABEL: "Aksjesparekonto",
  ACCOUNT_IPS_LABEL: "Pensjonssparekonto",
  ACCOUNT_NUMBER: "Kontonummer",
  ACCOUNT_OVERVIEW: "Totaloversikt",
  ACCOUNT_TOTAL_VALUE: "Totalverdi",
  ACCOUNT_TOTAL_VALUE_HELP_TEXT:
    "Den samlede verdien av alle dine fond, aksjer og kontanter. Totalverdi består av kostpris på fond og aksjer, urealisert gevinst/tap og kontanter på aksjesparekonto (ASK) og klientkonto.",
  ACCOUNT_OPEN: "Åpne konto",
  ACCOUNT_CLOSE: "Avslutt konto",
  ACCOUNT_DEPOSIT: "Sett inn penger",
  ACCOUNT_ASSOCIATED: "Tilknyttet bankkonto",
  ACCOUNT_VPS: "VPS-kontonummer",
  ACCOUNT_CREATE_VPS_ACCOUNT: "Opprett VPS konto",
  ACCOUNT_ASK: "Aksjesparekonto (ASK)",
  ACCOUNT_MOVE_ASK: "Opprett/flytt til ASK",
  ACCOUNT_CREATE_ASK: "Opprett ny ASK",
  ACCOUNT_SHOW_MORE: "Vis mer",
  ACCOUNT_SHOW_LESS: "Vis mindre",
  ACCOUNT_SHOW_ALL: "Vis alle konti",
  ACCOUNT_SELECT: "Velg konto",
  ACCOUNT_DEFAULT_AVATAR: "V",
  ACCOUNT_KID: "KID-nummer",
  ACCOUNT_TYPE: "Kontotype",
  ACCOUNT_NAME: "Kontonavn",
  ACCOUNT_INFO: "Kontoinformasjon",
  ACCOUNT_HISTORY: "Kontoutvikling",
  ACCOUNT_CASH_NAME: "Navn",
  ACCOUNT_CASH_VALUE: "Verdi",
  ACCOUNT_FUND_INFO: "Fond",
  ACCOUNT_STOCK_INFO: "Aksjer",
  CASH_DEPOSIT_TITLE: "Innskudd",
  ACCOUNT_BALANCE_LABEL: "Saldo",
  ACCOUNT_CASH_LABEL: "Kontanter",
  ACCOUNT_ASK_DETAILS: "ASK detaljer",
  ACCOUNT_GIFT_CARD_BUTTON_TEXT: "Motta gave",
  ACCOUNT_CLOSE_BUTTON_TEXT: "Avslutt konto",
  ACCOUNT_DEPOSIT_TEXT: "For å gjøre et innskudd til denne kontoen kan du gå til din nettbank og opprette en regningsbetaling.",
  ACCOUNT_DEPOSIT_KID_LABEL: "KID-nummer",
  ACCOUNT_DEPOSIT_BANK_ACCOUNT_LABEL: "Bankkonto",
  ACCOUNT_WIN_LOSS: "Gevinst/Tap",
  ACCOUNT_WIN_LOSS_HELP_TEXT:
    "Gevinst/tap viser differansen mellom kostpris og markedsverdi på alle fond og aksjer du eier idag på tvers av porteføljer. Gå inn på Aksjesparekontoen for å se Gevinst/tap opp mot opprinnelige innskutte beløp.",
  ACCOUNT_EQUITY_HELP_TEXT:
    "Den samlede verdien av alle dine fond, aksjer og kontanter. Totalverdi består av kostpris på fond og aksjer, urealisert gevinst/tap og kontanter på aksjesparekonto (ASK).",
  ACCOUNT_IPS_EQUITY_HELP_TEXT:
    "Den samlede verdien av alle dine fond og kontanter. Totalverdi består av kostpris på fond og urealisert gevinst/tap",
  ASK_ACCOUNT_UNREALIZED_HELP_TEXT:
    "Gevinst/tap viser differansen mellom netto innskutt beløp og markedsverdi på aksjesparekontoen. Dersom netto innskutt (uttak overstiger innskudd) er negativt kan ikke prosentavkastning beregnes",
  IPS_ACCOUNT_UNREALIZED_HELP_TEXT:
    "Gevinst/tap viser differansen mellom netto innskutt beløp og markedsverdi på IPS-kontoen. Dersom netto innskutt er negativt kan ikke prosentavkastning beregnes.",
  ACCOUNT_COST_PRICE_HELP_TEXT:
    "Dette er beløpet du har kjøpt aksjer og fond for. Kostpris/kjøpesum er det du har betalt for aksjene og fondsandelene dine inkludert eventuelle kjøpsomkostninger. For en aksjesparekonto som er flyttet inn vil kostpris, før noen kjøp/salg/bytt er foretatt er foretatt, vise markedsverdiene på flyttetidspunktet. Kostpris finner man som «Skattefritt uttak»",
  ACCOUNT_IPS_COST_PRICE_HELP_TEXT:
    "Gevinst/tap viser differansen mellom kostpris og dagens totalverdi på dine fond du eier idag",
  ACCOUNT_FUND_COST_HELP_TEXT:
    "Kostnader er løpende årlige kostnader som beregnes ut fra dagens totalverdi på din fondsportefølje. Alle kostnader trekkes i de underliggende fondsplasseringer. Kostnadstallet består av det underliggende fonds årlige faste forvaltningshonorar og honorar Norne/bank. Løpende kostnader er allerede fratrukket de summene som vises på «Min side». Det vil si at totalverdi og urealisert gevinst/tap på din portefølje er etter at totalkostnadene er trukket fra",
  ACCOUNT_UNREALIZED_HELP_TEXT:
    "Her vises urealisert gevinst/tap og er differansen mellom opprinnelig kostpris og dagens totalverdi på dine aksjer og fond.",
  ACCOUNT_CASH_HELP_TEXT:
    "Kontanter på klientkonto eller aksjesparekonto får du når du a. selger/innløser aksjer eller fond på klientkonto b. mottar utbytte på aksjer eller c. selv setter inn penger på din klientkonto. Kontantene kan benyttes til å investere i aksjer eller aksjefond eller overføres til din bankkonto. Etter loven opptjenes det ikke renter på kontantbeholdning på aksjesparekonto.",
  ACCOUNT_FUND_TAX_FREE_HELP_TEXT:
    "Du kan ta ut opptil $TAX_FREE_AMOUNT NOK fra din aksjesparekonto (ASK) uten å utløse skatt. Består av kostpris og kalkulert skjermingsfradrag.",
  ACCOUNT_TWR_NUMBER_HELP_TEXT:
    "Avkastningstallet er årlig avkastning siste tre år. Dersom porteføljen har kortere levetid enn tre år vises årlig avkastning siden oppstart.",
  IPS_DEPOSIT_THIS_YEAR: "Innskutt hittil i år",
  IPS_DEPOSIT_THIS_YEAR_HELP_TEXT: "Du kan spare inntil 15.000 i året og få 3.300 (22% per 2023) kroner i skattefradrag",

  ACCOUNT_SELL_FUND_DIALOG_TITLE: "Selg fond",
  ACCOUNT_SELL_FUND_DIALOG_TEXT: "For å selge fond, trykk på fondet du ønsker å selge og velg «Selg» derfra",

  ACCOUNT_OVERVIEW_MARKET_VALUE_CHART: "Markedsverdi",
  ACCOUNT_OVERVIEW_MARKET_TYPE_CHART: "Markedsfordeling",

  // Widgets
  WIDGET_SAVING: "Månedlig sparing",
  WIDGET_RESULT_TEXT_A: "Topp ",
  WIDGET_RESULT_TEXT_B: "% av alle sparere",
  WIDGET_START_SAVING: "Start sparing her",
  WIDGET_ASK_HEADER: "Aksjesparekonto",
  WIDGET_ASK_TEXT: "Sparing i aksjer og fond med skattefordeler.",
  WIDGET_ASK_BUTTON: "Åpne ASK-konto",
  WIDGET_ASK_MOVE_BUTTON: "ASK flytt",
  WIDGET_IPS_HEADER: "IPS: Egen Pensjonssparing",
  WIDGET_IPS_TEXT: "Privat sparing til pensjon med skattefordeler.",
  WIDGET_IPS_BUTTON: "Åpne IPS-konto",
  WIDGET_HIGHLIGHTEDSTOCK_HEADER: "Smart sparing: Algoritmestyrt norsk aksjefond",
  WIDGET_HIGHLIGHTEDSTOCK_TEXT:
    "Lavkost og datadrevet aksjeplukking på Oslo Børs, med over 15% meravkastning mot fondsindeksen siden oppstart av fondet i 2021.",
  WIDGET_HIGHLIGHTEDSTOCK_BUTTON: "Kjøp Norne Aksje Norge",
  WIDGET_FUNDS_HEADER: "Fondssparing",
  WIDGET_FUNDS_TEXT: "Oversikt alle fond.",
  WIDGET_FUNDS_BUTTON: "Kjøp fond her",
  WIDGET_FUNDS_AS_GIFT_BUTTON: "Fond i gave",
  WIDGET_STOCKS_HEADER: "Aksjehandel",
  WIDGET_STOCKS_TEXT: "Alle aksjekurser i gratis sanntid.",
  WIDGET_STOCKS_BUTTON: "Handle aksjer",
  WIDGET_NOTIFICATION_HEADER: "Flekkefjord Sparebank",
  WIDGET_NOTIFICATION_TEXT: "Flekkefjord Sparebank skal børsnoteres.",
  WIDGET_NOTIFICATION_BUTTON: "Les mer og tegne egenkapitalbevis her",

  // Agreements
  AGREEMENT: "Spareavtale",
  AGREEMENTS: "Mine spareavtaler",
  AGREEMENTS_HEADER: "Spareavtaler",
  AGREEMENT_CREATE_NEW: "Opprett ny spareavtale",
  AGREEMENT_CREATE_PORTFOLIO: "Hvilken konto ønsker du å spare i?",
  AGREEMENT_TOTAL_ANNUAL_AMOUNT: "Total årlig beløp",
  AGREEMENT_PORTFOLIO: "Tilknyttet konto",
  AGREEMENT_BANKACCOUNT: "Bankkonto",
  AGREEMENT_VALUE: "Verdi",
  AGREEMENT_FREQUENCY: "Sparefrekvens",
  AGREEMENT_MONTH_TABLE_LABEL: "Måneder uten trekk",
  AGREEMENT_WITHDRAWAL_DATE: "Trekkdato",
  AGREEMENT_NEXT_DATE: "Neste trekkdato",
  AGREEMENT_LAST_DATE: "Siste trekkdato",
  AGREEMENT_ANNUAL_AMOUNT: "Årlig beløp",
  AGREEMENT_TOTAL_AMOUNT: "Total spart beløp",

  AGREEMENT_CHANGE: "Endre",
  AGREEMENT_DELETE: "Er du sikker på at du vil slette ",
  AGREEMENT_DELETE_SUCCESS: "Avtalen din er nå slettet.",
  AGREEMENT_DELETE_ERROR: "Noe gikk galt ved sletting av din spareavtale.",
  AGREEMENT_WITHDRAWAL: "Trekkes fast",
  AGREEMENT_NONE: "Du har ingen spareavtaler å vise.",
  AGREEMENT_EDIT: "Endre spareavtale",
  AGREEMENT_NAME: "Navn på spareavtalen",
  AGREEMENT_FUND_SELECT: "Velg ett eller flere fond",
  AGREEMENT_PERIOD_DAY_NUMBER: "Velg trekkdag i måneden",
  AGREEMENT_DATE_END: "Sluttdato",
  AGREEMENT_DATE_START: "Startdato",
  AGREEMENT_END: "Jeg ønsker å sette sluttdato på spareavtalen",
  AGREEMENT_START: "Jeg ønsker å sette startdato på spareavtalen",
  AGREEMENT_NEXT: "Neste trekkdato på spareavtale",
  AGREEMENT_INTERVAL: "Trekkes fast",
  AGREEMENT_YEARLY_INCREASE: "Årlig økning sparebeløp",
  AGREEMENT_ADD_YEARLY_INCREASE: "Legg til årlig økning",
  AGREEMENT_CONFIRM_YEARLY_INCREASE_CHANGE: "Bekreft endring",
  AGREEMENT_ABORT_YEARLY_INCREASE_CHANGE: "Avbryt",
  AGREEMENT_YEARLY_INCREASE_TEASER:
    "Visste du at du kan legge til årlig økning på spareavtalene dine? Klikk på spareavtalen for å legge til.",
  AGREEMENT_YEARLY_INCREASE_TEXT: "Her kan du velge å automatisk justere opp spareavtalen din hvert år",
  AGREEMENT_AMOUNT: "Sparebeløp",
  AGREEMENT_SKIP_PERIOD: "Hopp over neste trekk",
  AGREEMENT_SKIP_PERIOD_NEXT_DATE: "Neste trekkdato: $NEXT_DATE",
  AGREEMENT_KID_LABEL: "KID",
  AGREEMENT_CONFIRM: "Bekreft spareavtale",
  AGREEMENT_COMPLETED: "Spareavtale opprettet.",
  AGREEMENT_SUCCESS_HEADER: "Spareavtale opprettet",
  AGREEMENT_SUCCESS: "Spareavtalen er nå opprettet. På kontosiden kan du til enhver tid oppdatere denne om ønskelig.",
  AGREEMENT_UPDATE_SUCCESS: "Spareavtalen er nå oppdatert. På kontosiden kan du til enhver tid oppdatere denne om ønskelig.",
  AGREEMENT_CONFIRM_NEW: "Bekreft ny spareavtale",
  AGREEMENT_CONFIRM_UPDATE: "Bekreft spareavtale",
  AGREEMENT_ACCOUNT_SELECT: "Velg hvilken bankkonto pengene skal trekkes fra",
  AGREEMENT_TEXT_A:
    "Se over spareavtalen din og se at alle feltene stemmer. Er det noe du ønsker å endre kan du gå tilbake til de forskjellige stegene og gjøre endringer der. Du kan også når som helst endre på en spareavtale om du ønsker det.",
  AGREEMENT_TEXT_B: "Jeg bekrefter at jeg vil opprette spareavtalen.",
  AGREEMENT_TEXT_C: "Jeg bekrefter at jeg vil oppdatere spareavtalen.",
  AGREEMENT_PERIODTYPE_W: "Ukentlig",
  AGREEMENT_PERIODTYPE_A: "Annenhver uke",
  AGREEMENT_PERIODTYPE_M: "Månedlig",
  AGREEMENT_PERIODTYPE_Y: "Årlig",
  AGREEMENT_ERROR: "Noe gikk galt ved opprettelse av din spareavtale",
  AGREEMENT_MINIMUM_AMOUNT: "Minimum månedlig sparebeløp er 250 NOK",
  AGREEMENT_PERIOD_DAY_INSTRUCTION: "Den valgte trekkdagen må være et tall mellom 1 og 31.",
  AGREEMENT_NAME_INSTRUCTION: "Navnet på spareavtalen kan ikke være tomt.",
  AGREEMENT_SHOW_LESS: "Vis mindre",
  AGREEMENT_SHOW_ALL: "Vis alle avtaler",
  AGREEMENT_AVTALE_GIRO_TEXT: "Ønsker du å opprette avtalegiro for spareavtalen?",
  AGREEMENT_AVTALE_GIRO_BUTTON_TEXT: "Opprett AvtaleGiro",
  AGREEMENT_AVTALEGIRO_CREATED: "AvtaleGiro er opprettet",
  AGREEMENT_AVTALEGIRO_CREATED_WITH_NAME: "AvtaleGiro er opprettet for $NAME",
  AGREEMENT_AVTALEGIRO_FAILED: "AvtaleGiro kunne ikke opprettes",
  AGREEMENT_AVTALEGIRO_FAILED_CREATING: "Automatisk opprettelse av AvtaleGiro feilet.",
  AGREEMENT_AVTALEGIRO_IN_ONLINE_BANKING: "Vennligst register avtalegiro i din nettbank med følgende",
  AGREEMENT_AVTALEGIRO_IN_ONLINE_BANKING_KID_LABEL: "KID",
  AGREEMENT_AVTALEGIRO_IN_ONLINE_BANKING_ACCOUNT_LABEL: "Mottakerkonto",
  AGREEMENT_AVTALEGIRO_IN_ONLINE_BANKING_ACCOUNT_NUMBER: "3626 30 29539",
  AGREEMENT_NOT_VALID: "Vennligst kontroller spareavtalen.",
  AGREEMENT_MISSING_FUND: "Du må velge minst ett fond.",
  AGREEMENT_MISSING_AMOUNT: "En av fondene mangler sparebeløp.",
  AGREEMENT_AMOUNT_BELOW_LIMIT: "Sparebeløpet er under minimumsgrensen på kr 250.",
  AGREEMENT_NOT_FOUND: "Finner ikke spareavtalen.",
  AGREEMENT_CREATE_CLOSE_LABEL: "Lukk",
  AGREEMENT_UPDATE_CLOSE_LABEL: "Lukk",
  AGREEMENT_CONFIRM_LABEL: "Bekreft",
  AGREEMENT_ABORT_HEADER: "Avbryt",
  AGREEMENT_NEXT_HEADER: "Neste",
  AGREEMENT_BACK_HEADER: "Tilbake",
  AGREEMENT_AVTALEGIRO_GO_HOME: "Gå til Min side",

  AGREEMENT_AVTALEGIRO_UPDATE: "AvtaleGiro oppdatering",

  AGREEMENT_NAME_PLACEHOLDER: "Spareavtale",
  AGREEMENT_MISSING_AVTALEGIRO_WARNING: "Denne spareavtalen mangler en aktiv avtalegio. Du kan opprette en avtalegiro ",
  AGREEMENT_MISSING_AVTALEGIRO_LINK: "her.",

  AGREMENT_JANUARY: "Januar",
  AGREMENT_FEBRUARY: "Februar",
  AGREMENT_MARCH: "Mars",
  AGREMENT_APRIL: "April",
  AGREMENT_MAY: "Mai",
  AGREMENT_JUNE: "Juni",
  AGREMENT_JULY: "Juli",
  AGREMENT_AUGUST: "August",
  AGREMENT_SEPTEMBER: "September",
  AGREMENT_OCTOBER: "Oktober",
  AGREMENT_NOVEMBER: "November",
  AGREMENT_DECEMBER: "Desember",

  // IPS
  IPS: "IPS",
  IPS_CREATE: "Opprett IPS",
  IPS_MORE_INFO: "Mer info om IPS",
  IPS_INFO_HEADER_A: "Individuell pensjonssparing",
  IPS_ABORT_HEADER: "Avbryt",
  IPS_BACK_HEADER: "Tilbake",
  IPS_INFO_A:
    "Sparing i fond som er bundet til fremtidig pensjon. Pengene bindes til kontoen til du skal ta ut pensjonen. Du kan spare inntil 15.000 i året og få 3.300 (22 % per 2022) kroner i skattefradrag gjennom ordningen. Du kan starte utbetaling ved 62 år.",
  IPS_INFO_HEADER_B: "Hvem passer IPS for?",
  IPS_INFO_B:
    "Alle med skattbar inntekt. Du som tror din pensjon via folketrygd og arbeidsgiver ikke er tilstrekkelig. Du som gjerne vil ha mulighet til å pensjonere deg tidlig. Sparer langsiktig til pensjonsalder og har ikke behov for uttak før 62 år.",
  IPS_INFO_HEADER_C: "Fordeler med IPS",
  IPS_INFO_C:
    "Det koster ingenting å opprette IPS. Beløpet du sparer gir deg fradrag i alminnelig inntekt. Maks sparebeløp er kr 15 000. Du får dermed avkastning på penger som ellers skulle ha vært innbetalt i skatt. Innestående på IPS teller ikke med i grunnlaget for formuesskatt, og skatt på avkastning utsettes til pengene tas ut.",
  IPS_INFO_HEADER_D: "Risiko og forventet avkastning",
  IPS_INFO_D:
    "IPS er langsiktig sparing til pensjon. Du velger selv den sammensettingen av fond du ønsker, og den kan endres underveis uten ekstra kostnader. Skal du oppnå høy verdistigning, må du være villig til å påta deg risiko. Du kan ha høy risiko med bare aksjefond, lav risiko med bare rentefond eller du kan velge en kombinasjon av disse. Alle fond er merket med grad av risiko. Velger du høy risiko med langsiktig sparing i IPS, kan du forvente høyere avkastning enn om du velger lav risiko. Ved sparing i IPS binder du opp pengene til pensjonsalder.",
  IPS_INFO_HEADER_E: "Hvordan starte med IPS?",
  IPS_INFO_E:
    "Den enkleste måten å komme i gang er å velge menypunkttet «Opprett IPS-konto» i menyen. Deretter vil du gå gjennom en trinnvis prosess for opprettelsen av kontoen. Opprettelse av konto skjer med en gang du har bekreftet. Dersom du har spørsmål underveis kan du kontakte kundesenteret i banken.",
  IPS_HEADER_A: "IPS-veileder",
  IPS_A_1: "Jeg ønsker å spare til pensjon og samtidig få en skattefordel.",
  IPS_A_2: "Jeg har råd til å spare til pensjon.",
  IPS_A_3: "Jeg forstår at utbetalingen starter tidligst ved fylte 62 år.",
  IPS_A_4: "Jeg forstår at utbetaling som starter før jeg fyller 70 år minst må utbetales til jeg er 80 år. ",
  IPS_A_5: "Jeg forstår at dersom utbetaling starter etter fylte 70 år må den minst utbetales i 10 år.",
  IPS_HEADER_B: "Risiko",
  IPS_B_1:
    "Risiko betyr at verdien på investeringen din kan variere over tid. Historisk sett har aksjefond gitt større avkastning enn rentefond, men også større svingninger i verdien.",
  IPS_B_1_2:
    "Vi anbefaler derfor at du velger en lavere andel aksjefond hvis du har kort sparetid: Da er det mindre sannsynlighet for store svingninger i verdien rett før utbetaling.",
  IPS_B_2: "Hvilken risiko ønsker du på dine plasseringer i IPS?",
  IPS_B_3: "Lav",
  IPS_B_3_1:
    "Dersom du ønsker lav risiko på plasseringene bør du investere i rente- og obligasjonsfond kombinert med en aksjeandel på maksimalt 20%.",
  IPS_B_3_2: "Du kan selv velge hvilke fond du ønsker eller du kan plassere midlene i våre anbefalte portefølje for lav risiko.",
  IPS_B_4: "Middels",
  IPS_B_4_1:
    "Dersom du ønsker middels risiko på plasseringene bør du investere i rente- og obligasjonsfond kombinert med en aksjeandel på maksimalt 80%.",
  IPS_B_4_2:
    "Du kan selv velge hvilke fond du ønsker eller du kan plassere midlene i våre anbefalte portefølje for middels risiko.",
  IPS_B_5: "Høy",
  IPS_B_5_1: "Dersom du ønsker høy risiko på plasseringene bør du investere all sparingen i aksjefond.",
  IPS_B_5_2: "Du kan selv velge hvilke fond du ønsker eller du kan plassere midlene i våre anbefalte portefølje for høy risiko.",
  IPS_HEADER_C: "Informasjon om kostnader",
  IPS_C_1:
    "Opprettelse og drift av IPS-konto er gratis. Fondene har ikke tegnings- eller innløsningsgebyrer. Norne forbeholder seg retten til å endre prising etter gjeldende varslingsrutiner.",
  IPS_C_2:
    "Norne mottar en andel av fondenes forvaltningshonorar. Denne tilbakeføres deg som kunde i sin helhet. Gjeldende forvaltningshonorar finnes i nøkkelinformasjonen for det enkelte fond. Honorar til bank/Norne varierer med type fond og beløp. Oppdatert prisinformasjon finner du på norne.no/prislistefond.",

  IPS_C_3: "Jeg har motatt og forstått informasjon om kostnader ved IPS",
  IPS_HEADER_D: "Avtale om IPS-konto",
  IPS_D_1: "Last ned vilkår for IPS-konto.",
  IPS_D_2: "Avtalen er lest og forstått",
  IPS_NO_IPS_FOR_YOU: "Dersom du ikke bekrefter alle valgene passer ikke IPS-sparing for deg.",
  IPS_BUTTON_CONTINUE: "Gå videre",
  IPS_BUTTON_COMPLETE: "Aksepter og opprett konto",
  IPS_BUTTON_CLOSE: "Lukk",
  IPS_BUTTON_BACK: "Tilbake",
  IPS_CONFIRMATION_TEXT: "Din IPS-konto er nå opprettet.",
  IPS_CONFIRMATION_TEXT_ERROR_GENERIC: "Noe gikk galt ved opprettelse av din IPS-konto. Vennligst kontakt kundeservice.",
  IPS_CONFIRMATION_TEXT_ERROR_EXISTING_ACCOUNT:
    "Du har allerede en IPS-konto. Vennligst kontakt kundeservice dersom du har spørsmål om dette.",
  IPS_CORPORATE_ERROR_TEXT: "IPS er ikke tilgjengelig for bedrifter",

  // ASK
  ASK: "ASK",

  // Messages
  STATUS_BAR: "Sesjonen er utløpt. Refresh siden eller gå til ny side for å se oppdaterte data.",
  ADVISOR_MESSAGE_AML_NOT_VERIFIED:
    "Kunden har ikke godkjent kundekontroll (aml). Kundekontroll må gjennomføres for å handle fond/aksjer eller gjøre endringer på ordre og spareavtaler.",
  ADVISOR_MESSAGE_AML_NOT_VERIFIED_LINK: "Gå til kundekontroll",
  LIMITED_ACCESS_AT_SELECTED_USER: "Du har kun innsynsfullmakt på denne brukeren.",
  MESSAGE_AML_EXPIRES_SOON: "Din Kundekontroll utløper snart. Vennligst oppdater din informasjon for å unngå begrensninger.",
  MESSAGE_AML_EXPIRES_SOON_LINK: "Gå til Kundekontroll",

  // Mailbox
  MAIBOX_TITLE: "Postkasse",
  MAILBOX_INBOX_LABEL: "Innboks",
  MAILBOX_SENT_LABEL: "Sendte meldinger",
  MAILBOX_LINK: "Finn svaret ditt",
  MAILBOX_HELP: "Trenger du hjelp?",
  MAILBOX_INFO: "Her finner du svar på ofte stilte spørsmål.",

  MAILBOX_REPLY_LABEL: "Svar",
  MAILBOX_SEND_MESSAGE_BUTTON_TEXT: "Send",
  MAILBOX_FILE_NAME_LENGTH_ERROR: "Filnavnet kan ikke være lengre enn 255 tegn.",
  MAILBOX_FILE_SIZE_ERROR: "Vedleggstørrelse overskriver maksgrense på 100MB",
  MAILBOX_SUBJECT_MISSING: "Emne mangler",
  MAILBOX_MESSAGE_MISSING: "Melding mangler",
  MAILBOX_PRINT_MESSAGE_LABEL: "Skriv ut",
  MAILBOX_COMPOSE_MESSAGE_LABEL: "Skriv melding",
  MAILBOX_ADD_ATTACHMENT_LABEL: "Legg ved fil",
  MAILBOX_MESSAGE_SENT_LABEL: "Melding sendt!",
  MAILBOX_MESSAGE_TITLE: "Skriv melding",
  MAILBOX_MESSAGE_SUBJECT_LABEL: "Emne",
  MAILBOX_MESSAGE_BACK_TO_INBOX_LINK: "Tilbake til innboks",
  MAILBOX_GENERAL_SEND_MESSAGE_ERROR: "En feil oppstod ved sending av melding. Vennligst prøv igjen seinere.",

  // Transfer
  TRANSFER_TITLE: "Overfør penger",
  TRANSFER_DEPOSIT_TAB_TITLE: "Innskudd",
  TRANSFER_DEPOSIT_SUBTITLE: "Nytt innskudd",
  TRANSFER_DEPOSIT_NO_PORTFOLIOS: "Alle dine kontoer har oppgjør direkte mot bankkonto.",
  TRANSFER_WITHDRAWAL_TAB_TITLE: "Uttak",
  TRANSFER_WITHDRAWAL_AGREEMENT_TITLE: "Uttaksavtale",
  TRANSFER_PORTFOLIO_NAME_HEADER: "Konto",
  TRANSFER_PORTFOLIO_KID_HEADER: "KID",
  TRANSFER_DEPOSIT_INFO:
    "For å gjøre et innskudd til en av dine Norne-kontoer kan du gå til din nettbank og opprette en regningsbetaling. Bruk KID-nummeret tilhørende den aktuelle kontoen fra listen under.",
  TRANSFER_DEPOSIT_BANKACCOUNT_INFO_TEXT: "Betal til bankkontonummer:",
  TRANSFER_WITHDRAWAL_PORTFOLIO_LABEL: "Konto",
  TRANSFER_WITHDRAWAL_SUBHEADER: "Ta ut penger",
  TRANSFER_WITHDRAWAL_BANKACCOUNT_LABEL: "Bankkonto",
  TRANSFER_WITHDRAWAL_AMOUNT_REQUIRED: "Beløp er påkrevd",
  TRANSFER_WITHDRAWAL_AMOUNT_LESS_THAN_LIMIT: "Beløp er mindre enn minimumsgrensen på 250 NOK",
  TRANSFER_WITHDRAWAL_AMOUNT_NON_ZERO: "Beløp må være høyere enn 0.",
  TRANSFER_WITHDRAWAL_AMOUNT_GREATER_THAN_AVAILABLE: "Beløp er større enn tilgjengelig saldo",
  TRANSFER_WITHDRAWAL_AVAILABLE_TAX_FREE_AMOUNT_LABEL: "Tilgjengelig beløp for skattefritt uttak:",
  TRANSFER_WITHDRAWAL_AVAILABLE_TAX_FREE_AMOUNT_HELP_TEXT:
    "Dette er beløpet du totalt kan ta ut fra din portefølje av fond uten at det påløper skatt.",
  TRANSFER_WITHDRAWAL_AVAILABLE_FOR_WITHDRAWAL_AMOUNT_LABEL: "Tilgjengelig for uttak:",
  TRANSFER_WITHDRAWAL_AVAILABLE_FOR_WITHDRAWAL_AMOUNT_HELP_TEXT_PREFIX: "Kontanter på aksjesparekonto (ASK) får du når du",
  TRANSFER_WITHDRAWAL_AVAILABLE_FOR_WITHDRAWAL_AMOUNT_HELP_TEXT_A: "a. selger/innløser aksjer eller fond på ASK,",
  TRANSFER_WITHDRAWAL_AVAILABLE_FOR_WITHDRAWAL_AMOUNT_HELP_TEXT_B: "b. mottar utbytte på aksjer eller",
  TRANSFER_WITHDRAWAL_AVAILABLE_FOR_WITHDRAWAL_AMOUNT_HELP_TEXT_C: "c. setter selv inn penger på din ASK",
  TRANSFER_WITHDRAWAL_AVAILABLE_FOR_WITHDRAWAL_AMOUNT_HELP_TEXT_POSTFIX:
    "Kontantene benytter du til å reinvestere i aksjer eller aksjefond. Beløpet kan også overføres til din bankkonto utenfor ASK. Etter loven opptjenes det ikke renter på kontantbeholdningen.",
  TRANSFER_WITHDRAWAL_INFO_TEXT:
    "Merk at dersom kontoen er en aksjesparekonto vil uttak medføre skattemessig realisasjon, dersom beløpet overstiger skattemessig innskutt beløp.",
  TRANSFER_WITHDRAWAL_INFO_MONEY_NEXT_DAY: "Penger vil normalt være på konto neste bankdag.",
  TRANSFER_WITHDRAWAL_NEW_AGREEMENT_HEADER: "Opprett nytt uttaksavtale",
  TRANSFER_WITHDRAWAL_AGREEMENT_PERIOD_DAY_LABEL: "Utbetalingsdato",
  TRANSFER_WITHDRAWAL_AGREEMENT_MARKET_VALUE_HEADER: "Markedsverdi",
  TRANSFER_WITHDRAWAL_AGREEMENT_FUND_NAME_HEADER: "Fond",
  TRANSFER_WITHDRAWAL_AGREEMENT_AMOUNT_HEADER: "Beløp",
  TRANSFER_WITHDRAWAL_AGREEMENT_TOTAL_AMOUNT_LABEL: "Beløp totalt",
  TRANSFER_WITHDRAWAL_AGREEMENT_NO_FUND_AT_ACCOUNT: "Ingen fond på konto",
  TRANSFER_WITHDRAWAL_AGREEMENT_BANKACCOUNT_REQUIRED: "Du må velge en bankkonto",
  TRANSFER_WITHDRAWAL_AGREEMENT_FUND_ERROR: "Du må velge minst ett fond og beløpet må være større enn 250.",
  TRANSFER_WITHDRAWAL_AGREEMENT_INFO_TEXT:
    "Her kan du opprette en løpende uttaksavtale som hver måned selger litt av beholdningen og utbetaler beløpet til konto. Merk at dette kan medføre skattemessig realisasjon.",
  TRANSFER_WITHDRAWAL_AGREEMENT_INFO_TEXT_2: "Dersom du ønsker å selge fond må du gå til ",
  TRANSFER_WITHDRAWAL_AGREEMENT_INFO_TEXT_3: " og klikke på fondet du vil selge.",
  TRANSFER_WITHDRAWAL_AGREEMENT_INFO_LINK: "min beholdning,",
  TRANSFER_WITHDRAWAL_BANKACCOUNT_NO_ACCOUNT: "Ingen bankkonto registrert.",
  TRANSFER_WITHDRAWAL_BANKACCOUNT_NO_ACCOUNT_LINK: "Registrer bankkonto her",
  TRANSFER_NEW_CASH_ACCOUNT: "Registrer bankkonto",
  TRANSFER_WITHDRAWAL_SUCCESS_HEADING: "Uttak er registrert",
  TRANSFER_WITHDRAWAL_SUCCESS_TEXT:
    "Uttak er registrert, og vil bli behandlet i løpet av kort tid. Det vil normalt ta en til to virkedager før pengene er på konto.",
  TRANSFER_WITHDRAWAL_AGREEMENT_SUCCESS_TEXT: "Uttaksavtale er registrert",
  TRANSFER_WITHDRAWAL_ACTIVE_AGREEMENTS_HEADER: "Aktive uttaksavtaler",
  TRANSFER_WITHDRAWAL_ACTIVE_AGREEMENTS_PORTFOLIO_HEADER: "Konto",
  TRANSFER_WITHDRAWAL_ACTIVE_AGREEMENTS_BANKACCOUNT_HEADER: "Bankkonto",
  TRANSFER_WITHDRAWAL_ACTIVE_AGREEMENTS_NEXT_DATE_HEADER: "Neste trekkdato",
  TRANSFER_WITHDRAWAL_ACTIVE_AGREEMENTS_DAY_OF_MONTH_HEADER: "Utbetalingsdato",
  TRANSFER_WITHDRAWAL_ACTIVE_AGREEMENTS_INSTRUMENT_HEADER: "Fond",
  TRANSFER_WITHDRAWAL_ACTIVE_AGREEMENTS_AMOUNT_HEADER: "Beløp",
  TRANSFER_WITHDRAWAL_NO_ACTIVE_AGREEMENTS_TEXT: "Ingen aktive uttaksavtaler",
  TRANSFER_WITHDRAWAL_SEND: "Send inn",
  TRANSFER_WITHDRAWAL_BANKACCOUNT_REQUIRED: "Velg bankkonto",
  TRANSFER_WITHDRAWAL_PORTFOLIO_REQUIRED: "Velg konto",
  TRANSFER_CLOSE_LABEL: "Lukk",

  POA_TITLE: "Fullmakter",
  POA_INGRESS: "Her finner du en oversikt over hvem som har tilgang til dine kontoer og hvilke kontoer andre har tilgang til.",
  POA_OTHERS_TITLE: "Dine tilganger til andres kontoer",
  POA_EMPTY_LIST: "Du har ingen tilganger til andre sine kontoer.",
  POA_NAME_HEADER: "Navn",
  POA_PORTFOLIO_HEADER: "Konto (Type)",
  POA_SWITCH_USER: "Bytt til bruker",
  POA_SWITCH_BACK: "Bytt til din bruker",
  POA_SWITCH_ACTIVE_USER: "Aktiv bruker",
  POA_OWNERS_TITLE: "Andre sine tilganger på dine kontoer",
  POA_OWNERS_NAME_HEADER: "Navn",
  POA_OWNERS_PORTFOLIO_HEADER: "Konto",
  POA_OWNERS_TYPE_HEADER: "Type",
  POA_OWNERS_EMPTY_LIST: "Ingen har tilgang til dine kontoer.",

  NEW_CASH_ACCOUNT_TITLE: "Registrer bankkonto",
  NEW_CASH_ACCOUNT_SUBTITLE: "Registrer ny bankkonto",
  NEW_CASH_ADD_ACCOUNT_BUTTON_TEXT: "Legg til konto",
  NEW_CASH_CLOSE_BUTTON_TEXT: "Lukk",
  NEW_CASH_ACCOUNT_NUMBER_LABEL: "Kontonummer",
  NEW_CASH_ACCOUNT_CREATED_MESSAGE: "Konto er registrert",
  NEW_CASH_ACCOUNT_FAILED_CREATING_ACCOUNT: "Vennligst kontroller kontonummeret og prøv igjen.",
  NEW_CASH_ACCOUNT_FAILED_CUSTOMER_NOT_OWNER: "Kontonummeret er ikke registrert på deg.",
  NEW_CASH_ACCOUNT_INFO_TEXT: "Legg inn kontonummeret til den kontoen du ønsker å registrere.",
  NEW_CASH_ACCOUNT_ALREADY_EXISTS_ERROR: "Kontoen er allerede registrert.",

  ADD_CASH_ACCOUNT_BUTTON_TEXT: "Legg til konto",

  COSTS_HEADER: "Kostnader",
  COSTS_INGRESS:
    "Kostnader er løpende årlige kostnader som beregnes ut fra dagens totalverdi på din fondsportefølje. Alle kostnader trekkes i de underliggende fondsplasseringer.",
  COSTS_INGRESS_2: "Kostnadstallet består av det underliggende fonds årlige faste forvaltningshonorar og honorar til Norne/bank.",
  COSTS_INGRESS_3:
    "Løpende kostnader er allerede fratrukket de summene som vises under på «Min side». Det vil si at totalverdi og urealisert gevinst/tap på din portefølje er etter at totalkostnadene er trukket fra.",

  COSTS_REPORT_HEADER: "Kostnadsrapport",
  COSTS_DOWNLOAD_COST_REPORT: "Last ned kostnadsrapport",
  COSTS_REPORT_YEAR_PLACEHOLDER: "Velg år",

  COSTS_FUND_HEADER: "Kostnader per fond",
  COSTS_LIST_NAME_HEADER: "Navn",
  COSTS_LIST_COSTS_HEADER: "Kostnader",
  COSTS_LIST_PERCENT_HEADER: "Prosent",
  COSTS_LIST_MARKETVALUE_HEADER: "Markedsverdi",
  COSTS_ONETIME_COSTS_LABEL: "Engangskostnader",
  COSTS_TRANSACTION_COSTS_LABEL: "Transaksjonskostnader",
  COSTS_ADMINISTRATION_COSTS_LABEL: "Forvaltningshonorar",
  COSTS_RETURN_COMMISION_COSTS_LABEL: "-Tilbakeførte returprovisjoner",
  COSTS_NETTO_ADMINISTRATION_FEE_LABEL: "Netto forvaltningshonorar",
  COSTS_BANK_FEE_LABEL: "Honorar til Norne / Bank",
  COSTS_CUSTOMER_PRICE_LABEL: "Årlig kundepris",
  COSTS_EXPECTED_YIELD_LABEL: "Forventet avkastning",
  COSTS_EXPECTED_YIELD_VALUE: "8%",
  COSTS_COSTS_LABEL: "Kostnader",
  COSTS_COSTS_VALUE: "1%",
  COSTS_YIELD_AFTER_COSTS_LABEL: "Avkastning etter kostnader",
  COSTS_YIELD_AFTER_COSTS_VALUE: "7%",
  COSTS_TOTAL_COSTS_HEADER: "Totale kostnader",
  COSTS_TOTAL_YIELD_HEADER: "Total avkastning",
  COSTS_TOTAL_YIELD_INFO_TEXT: "* fremstillingen er eksemplifisert effekt av kostnader på avkastning",

  ORDER_STOCK_HIDE_PART_OF_ORDER: "Jeg vil skjule deler av min ordre",
  ORDER_STOCK_AMOUNT_PER_STOCK_LABEL: "Pris per aksje",
  ORDER_STOCK_NUMBER_OF_STOCKS_LABEL: "Antall aksjer",
  ORDER_STOCK_NUMBER_OF_VISIBLE_STOCKS_LABEL: "Synlige aksjer",
  ORDER_STOCK_STOP_LOSS_LABEL: "Trigger",
  ORDER_STOCK_SHOW_STOP_LOSS: "Stop loss",
  ORDER_STOCK_SHOW_STOP_LOSS_HELP_TEXT:
    "Ved å sette stop loss vil salgsorderen først bli lagt ut i markedet når prisen faller til eller under valgt nivå.",
  ORDER_STOCK_STOP_LOSS_MUST_BE_SET: "Ugyldig verdi",
  ORDER_STOCK_PORTFOLIO_LABEL: "Konto",
  ORDER_STOCK_PORTFOLIO_MISSING_FUNDS: "Totalt estimert beløp TOTAL_AMOUNT kr er større enn disponibelt BALANCE kr",
  BUY_STOCK_HEADER: "Kjøp",
  SELL_STOCK_HEADER: "Selg",
  ORDER_STOCK_AMOUNT_REQUIRED: "Pris per aksje må fylles ut",
  ORDER_STOCK_AMOUNT_OUTSIDE_LIMIT: "Limit avviker mer enn 30% fra sist omsatte kurs på $LAST_TRADED_PRICE NOK",
  ORDER_STOCK_NUMBER_OF_STOCKS_REQUIRED: "Antall aksjer må fylles ut",
  ORDER_STOCK_PORTFOLIO_REQUIRED: "Velg konto",
  ORDER_STOCK_NUMBER_OF_VISIBLE_MAX: "Synlig antall aksjer må være mindre enn $MAX_VISIBLE_STOCKS",
  ORDER_STOCK_NUMBER_OF_VISIBLE_MIN: "Synlig antall aksjer må minst være 10",
  ORDER_STOCK_HIDE_PART_OF_ORDER_HELP_TEXT:
    "Du kan velge å skjule deler av din ordre ved å her taste inn det volumet som skal være synlig. Ordrestørrelse må være større enn 130 000 NOK",
  ORDER_STOCK_ORDER_SIZE_TOO_SMALL: "Ordrestørrelse må være større enn 130 000 NOK for å skjule deler av ordre",
  ORDER_STOCK_DATE_LABEL: "Gyldig til og med",
  ORDER_STOCK_API_ERROR: "En feil oppsto ved kjøp av aksje. Vennligst prøv igjen senere.",
  ORDER_STOCK_POSITION_NOT_FOUND: "Finner ikke aksjen i din portefølje",
  ORDER_STOCK_NUMBER_OF_STOCKS_MORE_THAN_AVAILABLE: "Antall aksjer er mer enn tilgjengelig beholdning",
  ORDER_STOCK_TICK_SIZE_ERROR: "Feil bruk av desimaler etter komma. Dette papiret handles i intervaller på $TICK_SIZE",
  ORDER_STOCK_AVAILABLE_STOCKS: "Tilgjengelig antall aksjer:",
  ORDER_STOCK_STOP_LOSS_LESS_THAN_PRICE: "Trigger kan ikke være lavere enn limit (Pris per aksje)",
  ORDER_STOCK_RIGHTS_INFO:
    "Dette er en tegningsrett. Tegningsretter faller verdiløse om de ikke aktivt benyttes for å kjøpe nye aksjer innen utløpet av tegningsperioden",
  ORDER_STOCK_RIGHTS_DISCOURAGEMENT_1:
    "Gjennomførte hensiktmessighetstest er ment å avklare i hvilken grad investeringer i tegningsretter er hensiktsmessig for deg ved at du er i stand å forstå risikoen forbundet med en slik investering. På grunnlag av den informasjonen du har gitt oss vurderer vi at en investering i tegningsretter ikke er hensiktsmessig for deg. Vi finner at du ikke har tilstrekkelig relevant erfaring med slike eller lignende investeringer, og/eller at det ikke foreligger andre forhold som kan dokumentere at du forstår risikoen.",
  ORDER_STOCK_RIGHTS_DISCOURAGEMENT_2:
    "Det er viktig at du forstår risiko forbundet med enhver investering. Informasjon om risiko finner du ",
  ORDER_STOCK_RIGHTS_DISCOURAGEMENT_3:
    "Dersom du mener at vår vurdering er feil og du mener at forstår risikoen ved denne investeringen, står du fritt til se bort fra frarådningen og gjennomføre investeringen som planlagt.",

  ORDER_STOCK_BALANCE_LABEL: "Disponibelt: ",

  SELL_STOCK_GENERAL_VALIDATION_ERROR: "Ordren er ugyldig. Vennligst sjekk at alle felt er fylt ut korrekt.",

  HISTORIC_ORDER_TITLE: "Historiske aksjeordre",
  HISTORIC_ORDER_ACCOUNT: "Konto",
  HISTORIC_ORDER_FROM_DATE: "Fra",
  HISTORIC_ORDER_TO_DATE: "Til",
  HISTORIC_ORDER_SEARCH_LABEL: "Aksje",
  HISTORIC_ORDER_COMPANY: "Selskap",
  HISTORIC_ORDER_NUMBER: "Ordrenummer",
  HISTORIC_ORDER_EXPIRY: "Gyldighet",
  HISTORIC_ORDER_TYPE: "Type",
  HISTORIC_ORDER_STATUS: "Status",
  HISTORIC_ORDER_QUANTITY: "Antall",
  HISTORIC_ORDER_REST: "Rest",
  HISTORIC_ORDER_LIMIT: "Limit",
  HISTORIC_ORDER_STOP_LOSS: "Stop loss",
  HISTORIC_ORDER_VISIBLE_QUANTITY: "Synlig antall",

  BUY_STOCK_SHOW_CONFIRM_PAGE_BUTTON: "Kjøp",
  BUY_STOCK_CONFIRM_CONFIRM_BUTTON: "Kjøp",
  SELL_STOCK_SHOW_CONFIRM_PAGE_BUTTON: "Selg",
  SELL_STOCK_CONFIRM_CONFIRM_BUTTON: "Selg",
  ORDER_STOCK_CONFIRM_ABORT_BUTTON: "Avbryt",
  ORDER_STOCK_CONFIRM_HEADER_BUY: "Bekreft kjøp",
  ORDER_STOCK_CONFIRM_HEADER_SELL: "Bekreft salg",
  ORDER_STOCK_CONFIRM_STOCK_LABEL: "Aksje",
  ORDER_STOCK_CONFIRM_PORTFOLIO_LABEL: "Konto",
  ORDER_STOCK_CONFIRM_DATE_LABEL: "Gyldig til og med",
  ORDER_STOCK_CONFIRM_NUMBER_OF_STOCKS_LABEL: "Antall",
  ORDER_STOCK_CONFIRM_PRICE_LABEL: "Pris per aksje",
  ORDER_STOCK_CONFIRM_STOP_LOSS_LABEL: "Stop loss trigger",
  ORDER_STOCK_CONFIRM_BROKERAGE_LABEL: "Kurtasje",
  ORDER_STOCK_CONFIRM_TOTAL_AMOUNT_LABEL: "Totalt estimert beløp",

  STOCK_RECEIPT_HEADER_BUY: "Kjøpsordre opprettet",
  STOCK_RECEIPT_HEADER_SELL: "Salgsordre opprettet",
  STOCK_RECEIPT_NUMBER_OF_STOCK_LABEL: "Antall",
  STOCK_RECEIPT_AMOUNT_PER_STOCK_LABEL: "Pris",
  STOCK_RECEIPT_STOP_LOSS_LABEL: "Stop loss trigger",
  STOCK_RECEIPT_EXPIRES_LABEL: "Utløper",
  STOCK_RECEIPT_REMAINING_LABEL: "Gjenstående",
  STOCK_RECEIPT_STATUS_LABEL: "Status",
  STOCK_RECEIPT_ORDER_NUMBER_LABEL: "Ordrenummer",
  STOCK_RECEIPT_CLOSE_BUTTON_TEXT: "Lukk",
  STOCK_RECEIPT_GO_TO_ORDERS_BUTTON_TEXT: "Gå til ordre",

  STOCK_UPDATE_DATE_INFO_WARNING: "Du må endre limit og/eller antall for å kunne endre utløpsdato.",

  STOCK_UPDATE_SHOW_CONFIRM_PAGE_BUTTON: "Oppdater ordre",
  STOCK_UPDATE_CONFIRM_HEADER: "Bekreft oppdatering",
  STOCK_UPDATE_ABORT_CONFIRM_BUTTON: "Tilbake",
  ORDER_STOCK_CONFIRM_EXPIRY_DATE_LABEL: "Utløpsdato",
  STOCK_UPDATE_CONFIRM_BUTTON: "Bekreft",
  STOCK_UPDATE_API_ERROR: "En feil oppsto ved oppdatering av ordre. Vennligst prøv igjen senere.",
  STOCK_UPDATE_FORM_HEADER_BUY: "Kjøp",
  STOCK_UPDATE_FORM_HEADER_SELL: "Selg",

  STOCK_UPDATE_RECEIPT_HEADER: "Ordre oppdatert",

  PENSION_TITLE: "Min pensjon",
  PENSION_EMPLOYER_TITLE: "Pensjon fra arbeidsgiver",
  PENSION_EMPLOYER_INGRESS: "Tjenestepensjon",
  PENSION_EMPLOYER_NO_PENSION: "Vi finner ingen tjenestepensjon registrert på deg.",
  PENSION_PUBLIC_TITLE: "Pensjon fra staten",
  PENSION_PUBLIC_INGRESS: "Folketrygden",
  PENSION_PUBLIC_NO_PENSION: "Vi finner ingen folketrygd registrert på deg.",
  PENSION_INDIVIDUAL_TITLE: "Pensjon du sparer selv",
  PENSION_INDIVIDUAL_INGRESS: "Indviduelle pensjonsordninger",
  PENSION_INDIVIDUAL_NO_PENSION: "Vi finner ingen individuelle pensjonsordninger registrert på deg.",
  PENSION_EMPLOYER_LABEL: "Arbeidsgiver",
  PENSION_SUPPLIER_LABEL: "Pensjonsleverandør",
  PENSION_PRODUCT_LABEL: "Produktbetegnelse",
  PENSION_YEARLY_INCOME_LABEL: "Lønn",
  PENSION_AGE_LABEL: "Pensjosalder",
  PENSION_CALCULATE_BUTTON: "Beregn pensjon",
  PENSION_FAILED_GETTING_PENSION_DATA: "Vi kunne ikke hente pensjonsdata. Vennligst prøv igjen senere.",

  SUBSCRIPTIONS_TITLE: "Abonnementer",
  SUBSCRIPTIONS_INGRESS: "Her finner du oversikt over abonnementer som du når som helst kan starte og avslutte.",
  SUBSCRIPTIONS_ACTIVATE_FREE_SUBSCRIPTION: "Aktiver gratis realtidsabonnement for privat kunder",
  SUBSCRIPTIONS_NAME_HEADER: "Navn",
  SUBSCRIPTIONS_PRICE_HEADER: "Månedlig kostnad",
  SUBSCRIPTIONS_START_SUBSCRIPTION: "Abonner",
  SUBSCRIPTIONS_STOP_SUBSCRIPTION: "Avslutt abonnement",
  SUBSCRIPTIONS_PRICE_MISSING_TEXT: "-",
  SUBSCRIPTIONS_CONFIRM_SUBSCRIBE_MESSAGE: "Ønsker du å abonnere på $NAME for kroner $PRICE per måned?",
  SUBSCRIPTIONS_CONFIRM_UNSUBSCRIBE_MESSAGE: "Er du sikker på at du vil avslutte abonnementet på $NAME?",
  SUBSCRIPTIONS_CONFIRM_SUBSCRIBE: "Abonner",
  SUBSCRIPTIONS_CONFIRM_UNSUBSCRIBE: "Avslutt abonnement",
  SUBSCRIPTIONS_CONFIRM_SUBSCRIBE_HEADER: "Abonner",
  SUBSCRIPTIONS_CONFIRM_UNSUBSCRIBE_HEADER: "Avslutt abonnement",
  SUBSCRIPTIONS_FAILED_GETTING_SUBSCRIPTIONS: "Vi kunne ikke hente abonnementer. Vennligst prøv igjen senere.",
  SUBSCRIPTIONS_RT2PR_NOT_ALLOWED:
    "Du må være profesjonell bruker for å abonnere på denne tjenesten. Vennligst kontakt kundeservice for mer informasjon.",
  SUBSCRIPTIONS_NO_BANK_ACCOUNT: "Du må ha en bankkonto for å kunne abonnere på denne tjenesten.",
  SUBSCRIPTIONS_NO_MAIN_PORTFOLIO:
    "Du må ha en hovedkonto for å kunne abonnere på denne tjenesten. Vennligst kontakt kundeservice for mer informasjon.",
  SUBSCRIPTIONS_REGISTER_BANK_ACCOUNT: "Registrer bankkonto her",

  REPORTS_TITLE: "Analyser",
  REPORTS_INGRESS: "Oversikt over aktuelle analyser.",
  REPORTS_HEADER_TITLE: "Tittel",
  REPORTS_HEADER_TICKER: "Ticker",
  REPORTS_HEADER_PUBLISHED: "Publisert",
  REPORTS_HEADER_TARGET: "Kursmål",
  REPORTS_HEADER_RECOMMENDATION: "Anbefaling",
  REPORTS_HEADER_DOWNLOAD: "Last ned",
  REPORTS_FAILED_GETTING_REPORTS: "Vi kunne ikke hente analyser. Vennligst prøv igjen senere.",
  REPORTS_NO_REPORTS: "Vi finner ingen analyser.",

  REPORTS_TITLE_ENG: "Analysis",
  REPORTS_INGRESS_ENG: "Overview of current analysis.",
  REPORTS_HEADER_TITLE_ENG: "Title",
  REPORTS_HEADER_TICKER_ENG: "Ticker",
  REPORTS_HEADER_PUBLISHED_ENG: "Published",
  REPORTS_HEADER_TARGET_ENG: "Target",
  REPORTS_HEADER_RECOMMENDATION_ENG: "Recommendation",
  REPORTS_HEADER_DOWNLOAD_ENG: "Download",
  REPORTS_FAILED_GETTING_REPORTS_ENG: "We could not find any analysis. Pleas, try again later.",
  REPORTS_NO_REPORTS_ENG: "No analysis found.",

  REPORT_DETAILS_TITLE: "Analyse",
  REPORT_DETAILS_TICKER_LABEL: "Ticker",
  REPORT_DETAILS_PUBLISHED_LABEL: "Publisert",
  REPORT_DETAILS_RECOMMENDATION_LABEL: "Anbefaling",
  REPORT_DETAILS_TARGET_LABEL: "Kursmål",
  REPORT_DETAILS_COMPANIES_TITLE: "Selskaper",
  REPORT_DETAILS_DOWNLOAD_REPORT: "Last ned",

  REPORT_DETAILS_TITLE_ENG: "Analysis",
  REPORT_DETAILS_TICKER_LABEL_ENG: "Ticker",
  REPORT_DETAILS_PUBLISHED_LABEL_ENG: "Published",
  REPORT_DETAILS_RECOMMENDATION_LABEL_ENG: "Recommendation",
  REPORT_DETAILS_TARGET_LABEL_ENG: "Target",
  REPORT_DETAILS_COMPANIES_TITLE_ENG: "Companies",
  REPORT_DETAILS_DOWNLOAD_REPORT_ENG: "Download",

  MARGIN_ACCOUNT_TITLE: "Margininformasjon",
  MARGIN_ACCOUNT_MARGIN_LABEL: "Belåningsverdi",
  MARGIN_ACCOUNT_MARGIN_LIMIT_LABEL: "Kredittgrense",
  MARGIN_ACCOUNT_AVAILABLE_CREDIT_PCT_LABEL: "Tilgjengelig kreditt (%)",
  MARGIN_ACCOUNT_AVAILABLE_CREDIT_LABEL: "Disponibelt",
  MARGIN_ACCOUNT_MARKET_VALUE_LABEL: "Verdi",
  MARGIN_ACCOUNT_BALANCE_LABEL: "Saldo",
  MARGIN_ACCOUNT_ACCUMULATED_INTEREST_LABEL: "Påløpte renter",
  MARGIN_ACCOUNT_EQUITY_LABEL: "Egenkapital",
  MARGIN_ACCOUNT_POSITION_VALUE_LABEL: "Kostpris",

  ADVISOR_TITLE: "Kundesøk",
  ADVISOR_SEARCH_PLACEHOLDER: "Kundesøk, minst 4 tegn",
  ADVISOR_IMPERSONATED_USER_LABEL: "Du er logget inn som",
  ADVISOR_NO_USER_IMPERSONATED: "Ingen kunde valgt",
  ADVISOR_NO_CUSTOMERS_FOUND: "Ingen kunder funnet",
  ADVISOR_SEARCH_ERROR: "En feil oppsto ved søk. Oppfrisk/refresh siden og prøv på nytt.",

  ADVISOR_HELP_LINK: "norne.no/norne-intern",
  ADVISOR_HELP_TITLE: "Norne interne dokumenter for rådgivere",
  ADVISOR_HELP_INFO: "Her finner du oppdateringer og dokumenter. Siden oppdateres fortløpende med informasjon",

  INTERNAL_MISSING_ACCESS_TITLE: "Mangler tilgang",
  INTERNAL_MISSING_ACCESS_INGRESS: "Du har ikke tilgang til denne siden. Logg ut og gå til rådgiverpålogging.",
  INTERNAL_LOGIN_AS_ADVISOR: "Logg inn som rådgiver",
  INTERNAL_LOG_OUT: "Logg ut",
  INTERNAL_EXIT_IMPERSONATION: "Avslutt kundesesjon",

  INTERNAL_CUSTOMER_LIST_CUSTOMER_NUMBER_HEADER: "Kundenummer",
  INTERNAL_CUSTOMER_LIST_NAME_HEADER: "Navn",

  SWITCH_FUND_TITLE: "Bytt fond",
  SWITCH_FUND_INGRESS: "Du kan bytte fond her.",
  SWITCH_FUND_CHOOSE_FUND_FROM_EQUITIES: "Gå til 'Min beholdning' og velg fond du ønsker å bytte fra",
  SWITCH_FUND_GO_TO_EQUITIES: "Gå til Min beholdning",
  SWITCH_FUND_PORTFOLIO_LABEL: "Vis fond fra konto",

  SWITCH_FUND_HEADER_CHOOSE_FROM_FUND: "Velg fond du ønsker å bytte fra",
  SWITCH_FUND_HEADER_CHOOSE_TO_FUND: "Velg fond du ønsker å bytte til",
  SWITCH_FUND_HEADER_CONFIRMATION: "Bekreft bytte av fond",
  SWITCH_FUND_HEADER_RECEIPT: "Bytteordre opprettet",
  SWITCH_FUND_PORTFOLIO_WITH_NO_FUNDS: "Du har ingen fond på denne kontoen",
  SWITCH_FUND_NEXT_BUTTON: "Neste",
  SWITCH_FUND_BACK_BUTTON: "Tilbake",
  SWITCH_FUND_CONFIRM_BUTTON: "Bekreft",
  SWITCH_FUND_FROM_LABEL: "Bytt fra",
  SWITCH_FUND_TO_LABEL: "Bytt til",
  SWITCH_FUND_QUANTITY_LABEL: "Andeler",
  SWITCH_FUND_RECEIPT_FROM_LABEL: "Bytte fra",
  SWITCH_FUND_RECEIPT_TO_LABEL: "Bytte til",
  SWITCH_FUND_RECEIPT_TO_FUND_HEADER_LABEL: "Fond",
  SWITCH_FUND_RECEIPT_TO_DISTRIBUTION_HEADER_LABEL: "Fordeling",
  SWITCH_FUND_ACCOUNT_LABEL: "Konto",
  SWITCH_FUND_SHARES_LABEL: "Andeler",
  SWITCH_FUND_CHOOSE_FUND: "Velg fond fra listen",
  FUND_SWITCH_ORDER_CREATED_INFO_TEXT: "Ordren er sendt til fondsforvalter. Du kan følge med på statusen i ordreoversikten.",

  SWITCH_FUND_OPTION_A: "10%",
  SWITCH_FUND_OPTION_B: "25%",
  SWITCH_FUND_OPTION_C: "50%",
  SWITCH_FUND_OPTION_D: "Bytt alt",

  SWITCH_FUND_ORDER_TITLE: "Bytteordre",
  SWITCH_ORDER_FUND_NAME_LABEL: "Fond",
  SWITCH_ORDER_QUANTITY_LABEL: "Andeler",
  SWITCH_ORDER_SELL_FUND_TITLE: "Fra",
  SWITCH_ORDER_PURCHASE_FUND_TITLE: "Til",

  SWITCH_FUND_TO_FUND_HEADER: "Bytte til fond",
  SWITCH_FUND_TO_FUND_INSTRUCTION: "Velg prosentfordeling for fondene du ønsker å bytte til.",
  SWITCH_FUND_TO_FUND_PERCENT_LABEL: "Prosent",

  AML_WAITING_CONTROL_TITLE: "Avventer Kundekontroll",
  AML_WAITING_CONTROL_DESCRIPTION:
    "Din konto er sperret i påvente av Kundekontroll. Du vil motta en e-post når kontrollen er utført.",
  AML_REJECTED_TITLE: "Kundekontroll avvist",
  AML_REJECTED_DESCRIPTION: "Din konto er sperret fordi Kundekontrollen ble avvist. Vennligst kontakt kundeservice.",

  SIGNIN_ERROR_HEADER: "Feil ved innlogging",
  SIGNIN_ERROR_PAGE_TEXT: "Det oppstod en feil ved innlogging. Vennligst prøv igjen senere.",
  SIGNIN_ERROR_LOGIN_BUTTON: "Logg inn på nytt",

  MOVE_ASK_OVERVIEW_HEADING: "Aktive ASK flyttinger",
  MOVE_ASK_SELECT_BANK: "Velg bank",
  MOVE_ASK_INGRESS_HEADER_1: "Dette gjelder for aksjesparekonto (ASK)",
  MOVE_ASK_INGRESS_1:
    "Ved flytting til en aksjesparekonto (ASK) må man flytte alt, eller ingenting. Har du fond vi ikke tilbyr, innløser vi disse og overfører de som kontantbeholdning inn på aksjesparekontoen (ASK) du flytter til. Vi tilbyr ikke handel med utenlandske aksjer. Hvis du ønsker å flytte en aksjesparekonto (ASK) med denne typen aksjer, må aksjene selges først.",
  MOVE_ASK_INGRESS_HEADER_2: "Dette gjelder for spareavtaler",
  MOVE_ASK_INGRESS_2: "Har du spareavtaler hos leverandøren du flytter fra, avsluttes disse, og du kan opprette nye hos oss.",
  MOVE_ASK_INGRESS_HEADER_3: "Frys desember",
  MOVE_ASK_INGRESS_3:
    "I det norske markedet er det frysperiode på ASK flyttinger fra 1. desember. Dette medfører ASK flytt som meldes i desember blir først behandlet i januar.",
  MOVE_ASK_FUND_PROVIDER_LABEL: "Hvilken leverandør vil du flytte fra?",
  MOVE_ASK_PROVIDER_ASK_ACCOUNT_LABEL: "Konto hos avleverende aktør",
  MOVE_ASK_PROVIDER_ASK_SINGLE_LABEL: "Kontonummer",
  MOVE_ASK_ALL_ACCOUNTS_ACCOUNT_LABEL: "Alle kontoer",
  MOVE_ASK_SINGLE_ACCOUNT_LABEL: "Enkeltkonto",
  MOVE_ASK_OTHER_FUND_PROVIDER_LABEL: "Legg inn leverandør her hvis du ikke fant den i listen over",
  MOVE_ASK_PORTFOLIO_LABEL: "Hvilken aksjesparekonto (ASK) vil du flytte til?",
  MOVE_ASK_ACCOUNT_AT_MORE_THAN_ONE_BANK_INFO_TEXT:
    "Vi ser du har konto hos flere av våre tilknyttede banker. Velg kundeforhold du ønsker å flytte før du går videre.",
  MOVE_ASK_HEADING: "Flytt fond og aksjer til aksjesparekonto (ASK) hos oss",
  MOVE_ASK_NO_DATA_TEXT: "Du har ingen aktive flyttinger",
  MOVE_ASK_LINK_TEXT: "Registrer ny ASK flytt",
  MOVE_ASK_OVERVIEW_LINK_TEXT: "Se alle ASK flyttinger",
  MOVE_ASK_ACCEPT_TERMS: "Jeg godtar vilkårene",
  MOVE_ASK_CREATE_ASK_BUTTON: "Opprett Aksjesparekonto",
  MOVE_ASK_FORM_NEXT_BUTTON: "Neste",
  MOVE_ASK_CONFIRM_BUTTON: "Bekreft",
  MOVE_ASK_CONFIRM_BACK_BUTTON: "Tilbake",
  MOVE_ASK_CONFIRM_HEADING: "Bekreft flytting av fond og aksjer til aksjesparekonto (ASK)",
  MOVE_ASK_CONFIRM_FROM_FUND_PROVIDER_LABEL: "Leverandør",
  MOVE_ASK_CONFIRM_FROM_ASK_ACCOUNT_LABEL: "Kontonummer hos avleverende aktør",
  MOVE_ASK_CONFIRM_TO_ASK_ACCOUNT_LABEL: "Flytte til Aksjesparekonto (ASK)",
  MOVE_ASK_CREATE_ACCOUNT_ERROR_TEXT: "Det oppstod en feil ved opprettelse av Aksjesparekonto. Vennligst prøv igjen senere.",
  MOVE_ASK_RECEIPT_INFO_TEXT: "Du vil motta en e-post når flyttingen er gjennomført.",
  MOVE_ASK_RECEIPT_HEADING: "Flytting av fond og aksjer til aksjesparekonto (ASK) er registrert",
  MOVE_ASK_FUND_PROVIDER_ERROR: "Du må velge en leverandør",
  MOVE_ASK_FUND_OTHER_PROVIDER_ERROR: "Du må fylle inn leverandør",
  MOVE_ASK_PROVIDER_ASK_ACCOUNT_ERROR: "Du må fylle inn kontonummer hos avleverende aktør",
  MOVE_ASK_PORTFOLIO_ERROR: "Du må velge en aksjesparekonto (ASK)",
  MOVE_ASK_CONFIRM_API_ERROR: "Det oppstod en feil ved bekreftelse av flyttingen. Vennligst prøv igjen senere.",
  MOVE_ASK_DESCRIPTION_LABEL: "Status",
  MOVE_ASK_FROM_LABEL: "Fra",
  MOVE_ASK_TO_LABEL: "Til",

  AML_PAGE_PERSONNEL_TITLE: "Personalia",
  AML_PAGE_SSN_LABEL: "Fødselsnummer",
  AML_PAGE_FIRST_NAME_LABEL: "Fornavn",
  AML_PAGE_LAST_NAME_LABEL: "Etternavn",
  AML_PAGE_ADDRESS_LABEL: "Adresse",
  AML_PAGE_POSTAL_CODE_LABEL: "Postnummer",
  AML_PAGE_CITY_LABEL: "Poststed",
  AML_PAGE_COUNTRY_LABEL: "Land",
  AML_PAGE_CHOOSE_COUNTRY_LABEL: "Velg land",
  AML_PAGE_PHONE_LABEL: "Telefonnummer",
  AML_PAGE_EMAIL_LABEL: "E-postadresse",
  AML_PAGE_TAX_RESIDENCE_LABEL: "Skattemessig hjemland",
  AML_PAGE_CHOOSE_TAX_RESIDENCE_LABEL: "Velg skattemessig hjemland",
  AML_PAGE_CITIZENSHIP_LABEL: "Statsborgerskap",
  AML_PAGE_CHOOSE_CITIZENSHIP_LABEL: "Velg statsborgerskap",

  AML_PAGE_EMAIL_ERROR: "Du må fylle inn e-postadresse",
  AML_PAGE_PHONE_NUMBER_ERROR: "Du må fylle inn telefonnummer",
  AML_PAGE_CITIZENSHIP_ERROR: "Du må fylle inn statsborgerskap",
  AML_PAGE_TAX_RESIDENCE_ERROR: "Du må fylle inn skattemessig hjemland",
  AML_PAGE_CITY_ERROR: "Du må fylle inn poststed",
  AML_PAGE_POSTAL_CODE_ERROR: "Du må fylle inn postnummer",
  AML_PAGE_ADDRESS_ERROR: "Du må fylle inn adresse",
  AML_PAGE_COUNTRY_ERROR: "Du må fylle inn land",
  AML_PAGE_BIRTH_DATE_ERROR: "Du må fylle inn fødselsdato",

  AML_PAGE_PERSONNEL_NEXT_BUTTON: "Neste",

  AML_PAGE_PURPOSE_OF_ACCOUNT_LABEL: "Hva er formålet med kundeforholdet?",
  AML_PAGE_PURPOSE_OF_ACCOUNT_INVESTING_LABEL: "Investering",
  AML_PAGE_PURPOSE_OF_ACCOUNT_SAVING_LABEL: "Sparing",
  AML_PAGE_PURPOSE_OF_ACCOUNT_OTHER_LABEL: "Annet",

  AML_PAGE_FINANCIAL_INSTRUMENTS_LABEL: "Hvilke finansielle instrumenter kommer du til å handle?",
  AML_PAGE_FINANCIAL_INSTRUMENTS_LISTED_SHARES_LABEL: "Noterte aksjer",
  AML_PAGE_FINANCIAL_INSTRUMENTS_UNLISTED_SHARES_LABEL: "Unoterte aksjer",
  AML_PAGE_FINANCIAL_INSTRUMENTS_MUTUAL_FUNDS_LABEL: "Verdipapirfond (aksje- og rentefond)",
  AML_PAGE_FINANCIAL_INSTRUMENTS_BONDS_LABEL: "Obligasjoner",
  AML_PAGE_FINANCIAL_INSTRUMENTS_INTEREST_PRODUCT_LABEL: "Rentespareprodukter",
  AML_PAGE_FINANCIAL_INSTRUMENTS_OTHER_LABEL: "Annet",

  AML_PAGE_SOURCE_OF_FUNDS_LABEL: "Midlenes opprinnelse",
  AML_PAGE_SOURCE_OF_FUNDS_SALARY_LABEL: "Lønn",
  AML_PAGE_SOURCE_OF_FUNDS_PENSION_LABEL: "Pensjon",
  AML_PAGE_SOURCE_OF_FUNDS_INVESTMENT_RETURNS_LABEL: "Avkastning på investering",
  AML_PAGE_SOURCE_OF_FUNDS_INVESTMENT_RETURNS_DETAIL_LABEL: "Fra hvilke(n) investering(er), og hvor stor var avkastningen?",

  AML_PAGE_SOURCE_OF_FUNDS_LOAN_LABEL: "Lån",
  AML_PAGE_SOURCE_OF_FUNDS_LOAN_DETAIL_LABEL: "Hvem er långiver og hvor stort er lånet?",
  AML_PAGE_SOURCE_OF_FUNDS_INHERITANCE_OR_GIFT_LABEL: "Arv eller gave",
  AML_PAGE_SOURCE_OF_FUNDS_INHERITANCE_OR_GIFT_DETAIL_LABEL: "Arv eller gave fra hvem, og av hvilken størrelse?",
  AML_PAGE_SOURCE_OF_FUNDS_OTHER_LABEL: "Annet",
  AML_PAGE_SOURCE_OF_FUNDS_OTHER_DETAIL_LABEL: "Vennligst forklar nærmere på annet",

  AML_PAGE_QUARTERLY_NUMBER_OF_TRANSACTIONS_LABEL: "Hva er forventet antall transaksjoner pr kvartal?",
  AML_PAGE_QUARTERLY_NUMBER_OF_TRANSACTIONS_MORE_THAN_10_LABEL: "Hyppig (mer enn 10)",
  AML_PAGE_QUARTERLY_NUMBER_OF_TRANSACTIONS_BETWEEN_5_AND_10_LABEL: "Middels (mellom 5 og 10)",
  AML_PAGE_QUARTERLY_NUMBER_OF_TRANSACTIONS_LESS_THAN_5_LABEL: "Sjelden (færre enn 5)",
  AML_PAGE_QUARTERLY_NUMBER_OF_TRANSACTIONS_ERROR: "Forventet antall transaksjoner mangler",

  AML_PAGE_TOTAL_INVESTMENT_LABEL: "Hvor mye regner du med å investere i løpet av kundeforholdet?",
  AML_PAGE_TOTAL_INVESTMENT_MORE_THAN_10_LABEL: "Over 10 mill NOK",
  AML_PAGE_TOTAL_INVESTMENT_BETWEEN_1_AND_10_LABEL: "1-10 mill NOK",
  AML_PAGE_TOTAL_INVESTMENT_LESS_THAN_1_LABEL: "Opptil 1 mill NOK",

  AML_PAGE_POLITICAL_EXPOSURE_LABEL: "Politisk eksponering",
  AML_PAGE_POLITICAL_EXPOSURE_YES_LABEL:
    "Ja, undertegnede, reelle rettighetshavere eller andre som handler på mine vegne er politisk eksponert person, nært familiemedlem eller kjent medarbeider til en politisk eksponert person. Vennligst forklar. ",
  AML_PAGE_POLITICAL_EXPOSURE_NO_LABEL:
    "Nei, undertegnede, reelle rettighetshavere eller andre som handler på mine vegne er ikke politisk eksponert person, nært familiemedlem eller kjent medarbeider til en politisk eksponert person.",
  AML_PAGE_POLITICAL_EXPOSURE_DETAILS_LABEL:
    "Redgjør for dine verv eller relasjoner som gjør at du er en politisk eksponert person",

  AML_PAGE_POLITICAL_EXPOSURE_RELATION_LABEL: "Relasjon til politisk eksponert person",
  AML_PAGE_POLITICAL_EXPOSURE_RELATION_NO_LABEL:
    "Nei, reelle rettighetshavere eller andre som handler på mine vegne er ikke politisk eksponert person, nært familiemedlem eller kjent medarbeider til en politisk eksponert person",
  AML_PAGE_POLITICAL_EXPOSURE_RELATION_YES_LABEL:
    "Ja, reelle rettighetshavere eller andre som handler på mine vegne er politisk eksponert person, nært familiemedlem eller kjent medarbeider til en politisk eksponert person. Vennligst forklar.",

  AML_PAGE_OWNERSHIP_TITLE: "Eierandel",
  AML_PAGE_OWNERSHIP_LABEL: "Er du den reelle eier av midlene du skal kjøpe verdipapirer for?",
  AML_PAGE_OWNERSHIP_YES_LABEL: "Ja",
  AML_PAGE_OWNERSHIP_NO_LABEL: "Nei",
  AML_PAGE_OWNERSHIP_ADD_OWNER_BUTTON_TEXT: "Legg reell til eier",
  AML_PAGE_OWNERSHIP_CONFIRM_ADD_POA_BUTTON_TEXT: "Large og lukk",
  AML_PAGE_OWNERSHIP_LIST_TITLE: "Reelle eiere",

  AML_PAGE_POWER_OF_ATTORNEY_TITLE: "Fullmakt",
  AML_PAGE_POWER_OF_ATTORNEY_LABEL: "Er det andre personer som skal ha fullmakt til å handle på dine vegne?",
  AML_PAGE_POWER_OF_ATTORNEY_YES_LABEL: "Ja",
  AML_PAGE_POWER_OF_ATTORNEY_NO_LABEL: "Nei",
  AML_PAGE_POWER_OF_ATTORNEY_ADD_POA_BUTTON_TEXT: "Legg til fullmakt",
  AML_PAGE_ABORT_BUTTON_TEXT: "Avbryt",
  AML_PAGE_STORE_AND_CLOSE_BUTTON_TEXT: "Lagre og lukk",

  AML_PAGE_CONFIRM_ADD_POA_BUTTON_TEXT: "Large og lukk",
  AML_PAGE_POWER_OF_ATTORNEY_LIST_TITLE: "Fullmakter",

  AML_PAGE_CONTROL_FORM_BACK_BUTTON: "Tilbake",
  AML_PAGE_CONTROL_FORM_NEXT_BUTTON: "Neste",
  AML_PAGE_CONTROL_FORM_SUBMIT_BUTTON: "Send inn skjema",

  AML_PAGE_PURPOSE_OF_ACCOUNT_ERROR: "Du må velge formål med kundeforholdet",
  AML_PAGE_PURPOSE_OF_ACCOUNT_MISSING_DETAILS_ERROR: "Du må legge til forklaring for formålet med kundeforholdet",
  AML_PAGE_FINANCIAL_INSTRUMENTS_ERROR: "Du må velge hvilke finansielle instrumenter du kommer til å handle",
  AML_PAGE_FINANCIAL_INSTRUMENTS_MISSING_DETAILS_ERROR:
    "Du må legge til forklaring for de finansielle instrumentene du kommer til å handle",
  AML_PAGE_SOURCE_OF_FUNDS_ERROR: "Du må velge midlenes opprinnelse",
  AML_PAGE_SOURCE_OF_FUNDS_MISSING_DETAILS_ERROR: "Du må legge til forklaring for midlenes opprinnelse",
  AML_PAGE_SOURCE_OF_FUNDS_MISSING_LOAN_DETAILS_ERROR: "Forklaring på lån mangler",
  AML_PAGE_SOURCE_OF_FUNDS_MISSING_INVESTMENT_RETURNS_DETAILS_ERROR: "Forklaring på investeringer mangler",
  AML_PAGE_SOURCE_OF_FUNDS_MISSING_INHERITANCE_OR_GIFT_DETAILS_ERROR: "Forklaring på arv eller gave mangler",
  AML_PAGE_TOTAL_INVESTMENT_ERROR: "Du må velge hvor mye du regner med å investere i løpet av kundeforholdet",
  AML_PAGE_OWNERSHIP_ERROR: "Du må velge om du er den reelle eier av midlene du skal kjøpe verdipapirer for",
  AML_PAGE_OWNERSHIP_MISSING_OWNERS_ERROR: "Du må legge til minst en reell eier",
  AML_PAGE_POWER_OF_ATTORNEY_ERROR: "Du må velge om det er andre personer som skal ha fullmakt til å handle på dine vegne",
  AML_PAGE_POWER_OF_ATTORNEY_MISSING_POAS_ERROR: "Du må legge til minst en fullmakt",
  AML_PAGE_POLITICAL_EXPOSURE_ERROR: "Du må velge om du er en politisk eksponert person",
  AML_PAGE_POLITICAL_EXPOSURE_MISSING_DETAILS_ERROR:
    "Du må fylle inn detaljer om dine verv eller relasjoner som gjør at du er en politisk eksponert person",
  AML_PAGE_POLITICAL_EXPOSURE_RELATION_ERROR: "Du må velge om rettighetshavere er en politisk eksponert person",
  AML_PAGE_POLITICAL_EXPOSURE_MISSING_DETAILS_RELATION_ERROR:
    "Du må fylle inn detaljer om dine verv eller relasjoner som gjør at du er en politisk eksponert person",

  AML_PAGE_PERSONALIA_FIRST_NAME_LABEL: "Fornavn",
  AML_PAGE_PERSONALIA_FIRST_NAME_ERROR: "Du må fylle inn fornavn",
  AML_PAGE_PERSONALIA_LAST_NAME_LABEL: "Etternavn",
  AML_PAGE_PERSONALIA_LAST_NAME_ERROR: "Du må fylle inn etternavn",
  AML_PAGE_PERSONALIA_SSN_LABEL: "Fødselsnummer",
  AML_PAGE_PERSONALIA_SSN_ERROR: "Du må fylle inn fødselsnummer",
  AML_PAGE_PERSONALIA_SSN_NOT_VALID_ERROR:
    "Ugyldig fødselsnummer, er det utenlandsk statsborger kan du fylle ut 'Taxpayer Identification Number' og la dette feltet stå tomt",
  AML_PAGE_PERSONALIA_STREET_ADDRESS_LABEL: "Adresse",
  AML_PAGE_PERSONALIA_STREET_ADDRESS_ERROR: "Du må fylle inn adresse",
  AML_PAGE_PERSONALIA_ZIP_CODE_LABEL: "Postnummer",
  AML_PAGE_PERSONALIA_ZIP_CODE_ERROR: "Du må velge postnummer",
  AML_PAGE_PERSONALIA_CITY_LABEL: "Poststed",
  AML_PAGE_PERSONALIA_CITY_ERROR: "Du må velge poststed",
  AML_PAGE_PERSONALIA_COUNTRY_LABEL: "Land",
  AML_PAGE_PERSONALIA_CHOOSE_COUNTRY_LABEL: "Velg land",
  AML_PAGE_PERSONALIA_CITIZENSHIP_LABEL: "Statsborgerskap",
  AML_PAGE_PERSONALIA_CHOOSE_CITIZENSHIP_LABEL: "Velg statsborgerskap",
  AML_PAGE_PERSONALIA_TAX_RESIDENCE_LABEL: "Skattemessig hjemland",
  AML_PAGE_PERSONALIA_CHOOSE_TAX_RESIDENCE_LABEL: "Velg skattemessig hjemland",
  AML_PAGE_PERSONALIA_TIN_LABEL: "Taxpayer Identification Number",
  AML_PAGE_PERSONALIA_TIN_ERROR: "Dette feltet er påkrevd når skatteland eller statsborgerskap er annet enn Norge",
  AML_PAGE_PERSONALIA_PHONE_LABEL: "Telefon",
  AML_PAGE_PERSONALIA_EMAIL_LABEL: "E-post",
  AML_PAGE_PERSONALIA_OWNERSHIP_PERCENTAGE_LABEL: "Eierandel",
  AML_PAGE_OWNERSHIP_PERCENTAGE_ERROR: "Eierandel må være mellom 0 og 100",
  AML_PAGE_PERSONALIA_BIRTH_DATE_LABEL: "Fødselsdato",
  AML_PAGE_PERSONALIA_BIRTH_DATE_ERROR: "Du må fylle inn fødselsdato",

  AML_PAGE_POWER_OF_ATTORNEY_DOCUMENTATION_DESCRIPTION_TEXT:
    "Fullmakt må legges ved. Fullmektig må senere identifiseres med bekreftet kopi av pass, førerkort, bankkort med bilde eller med BankID.",
  AML_PAGE_POWER_OF_ATTORNEY_DOCUMENTATION_FILE_TEXT: "Vennligst last opp fullmakt",
  AML_PAGE_POWER_OF_ATTORNEY_DOCUMENTATION_FILE_BUTTON_TEXT: "Last opp fullmakt",

  AML_PAGE_ADD_POA_MODAL_TITLE: "Fullmakt",
  AML_PAGE_ADD_OWNER_MODAL_TITLE: "Reell eier",

  AML_PAGE_COMPANY_POWER_OF_ATTORNEY_DOCUMENTATION_LABEL: "Rett til å signere ved",
  AML_PAGE_COMPANY_POWER_OF_ATTORNEY_DOCUMENTATION_CERTIFICATE: "Firmaattest",
  AML_PAGE_COMPANY_POWER_OF_ATTORNEY_DOCUMENTATION_POA: "Fullmakt",
  AML_PAGE_COMPANY_POWER_OF_ATTORNEY_DOCUMENTATION_OTHER: "Annet",
  AML_PAGE_COMPANY_INFO_POWER_OF_ATTORNEY_DOCUMENTATION_ERROR: "Du må velge dokumentasjon for rett til å signere ved",
  AML_PAGE_COMPANY_POWER_OF_ATTORNEY_DOCUMENTATION_OTHER_DESCRIPTION: "Annet forklaring",
  AML_PAGE_COMPANY_INFO_POWER_OF_ATTORNEY_DOCUMENTATION_DESCRIPTION_ERROR: "Beskrivelse må fylles ut",
  AML_PAGE_COMPANY_POWER_OF_ATTORNEY_DOCUMENTATION_FILE_TEXT: "Vennligst last opp fullmakt",
  AML_PAGE_COMPANY_POWER_OF_ATTORNEY_DOCUMENTATION_FILE_BUTTON_TEXT: "Last opp fullmakt",
  AML_PAGE_COMPANY_INFO_POWER_OF_ATTORNEY_DOCUMENTATION_FILE_ERROR: "Du må laste opp fullmakt",

  AML_PAGE_SUMMARY_CONTROL_TITLE: "Hvitvaskingskontroll",
  AML_PAGE_SUMMARY_PERSONALIA_TITLE: "Oppsummering",
  AML_PAGE_SUMMARY_FIRST_NAME_LABEL: "Fornavn",
  AML_PAGE_SUMMARY_LAST_NAME_LABEL: "Etternavn",
  AML_PAGE_SUMMARY_SSN_LABEL: "Fødselsnummer",
  AML_PAGE_SUMMARY_ADDRESS_LABEL: "Adresse",
  AML_PAGE_SUMMARY_EMAIL_LABEL: "E-post",
  AML_PAGE_SUMMARY_PHONE_LABEL: "Telefonnummer",
  AML_PAGE_SUMMARY_CITIZENSHIP_LABEL: "Statsborgerskap",
  AML_PAGE_SUMMARY_TAX_RESIDENCE_LABEL: "Skattemessig hjemland",

  AML_PAGE_SUMMARY_PURPOSE_OF_ACCOUNT_LABEL: "Formål med kundeforholdet",
  AML_PAGE_SUMMARY_FINANCIAL_INSTRUMENT_LABEL: "Finansielle instrumenter",
  AML_PAGE_SUMMARY_SOURCE_OF_FUNDS_LABEL: "Midlenes opprinnelse",
  AML_PAGE_SUMMARY_POLITICAL_EXPOSURE_LABEL: "Politisk eksponert",
  AML_PAGE_SUMMARY_OWNERSHIP_LABEL: "Er reell eier av midlene",
  AML_PAGE_SUMMARY_POWER_OF_ATTORNEY_LABEL: "Fullmakt",
  AML_PAGE_SUMMARY_ATTACHMENTS_LABEL: "Vedlegg",
  AML_PAGE_SUMMARY_QUARTERLY_NUMBER_OF_TRANSACTIONS_LABEL: "Forventet antall transaksjoner per kvartal",
  AML_PAGE_SUMMARY_TOTAL_INVESTMENTS_LABEL: "Forventet investering",

  AML_PAGE_COMPANY_INFO_TITLE: "Antihvitvask - Bedriftsinformasjon",
  AML_PAGE_COMPANY_INFO_INGRESS: "Myndighetspålagt antihvitvask må gjenomføres av alle",
  AML_PAGE_COMPANY_INFO_ORG_NR_LABEL: "Organisasjonsnummer",
  AML_PAGE_COMPANY_INFO_NAME_LABEL: "Navn",
  AML_PAGE_COMPANY_INFO_ADDRESS_LABEL: "Adresse",
  AML_PAGE_COMPANY_INFO_ZIP_CODE_LABEL: "Postnummer",
  AML_PAGE_COMPANY_INFO_CITY_LABEL: "Poststed",
  AML_PAGE_COMPANY_INFO_COUNTRY_LABEL: "Land",
  AML_PAGE_COMPANY_INFO_CHOOSE_COUNTRY_LABEL: "Velg land",
  AML_PAGE_COMPANY_INFO_TAX_RESIDENCE_LABEL: "Skattemessig hjemland",
  AML_PAGE_COMPANY_INFO_CHOOSE_TAX_RESIDENCE_LABEL: "Velg skattemessig hjemland",
  AML_PAGE_COMPANY_INFO_EMAIL_LABEL: "E-post",
  AML_PAGE_COMPANY_INFO_PHONE_LABEL: "Telefon",
  AML_PAGE_COMPANY_INFO_CEO_TITLE: "Daglig leder",
  AML_PAGE_COMPANY_INFO_CEO_BIRTH_DATE_LABEL: "Fødselsdato",
  AML_PAGE_COMPANY_INFO_CEO_FIRST_NAME_LABEL: "Fornavn",
  AML_PAGE_COMPANY_INFO_CEO_LAST_NAME_LABEL: "Etternavn",

  AML_PAGE_COMPANY_INFO_COMPANYCERTIFICATE_TITLE: "Firmaattest",
  AML_PAGE_COMPANY_INFO_COMPANYCERTIFICATE_TEXT: "Firmaattest, ikke eldre enn 3. mnd",
  AML_PAGE_COMPANY_INFO_COMPANYCERTIFICATE_FILE_UPLOAD_TEXT: "Last opp firmaattest",

  AML_PAGE_COMPANY_INFO_POSITION_LABEL: "Stilling i foretaket",
  AML_PAGE_COMPANY_INFO_BENEFICIAL_OWNER_LABEL: "Er reell rettighetshaver i selskapet?",
  AML_PAGE_COMPANY_INFO_BENEFICIAL_OWNER_YES_LABEL: "Ja",
  AML_PAGE_COMPANY_INFO_BENEFICIAL_OWNER_NO_LABEL: "Nei",
  AML_PAGE_COMPANY_INFO_BENEFICIAL_OWNERS_HELP_TEXT_TITLE: "Reell rettighetshaver",
  AML_PAGE_COMPANY_INFO_OWNERSHIP_PERCENTAGE_LABEL: "Eierandel i prosent",
  AML_PAGE_COMPANY_INFO_PERSON_POLITICAL_EXPOSURE_LABEL: "Politisk eksponert?",
  AML_PAGE_COMPANY_INFO_PERSON_POLITICAL_EXPOSURE_YES_LABEL: "Ja",
  AML_PAGE_COMPANY_INFO_PERSON_POLITICAL_EXPOSURE_NO_LABEL: "Nei",
  AML_PAGE_COMPANY_INFO_PERSON_POLITICAL_EXPOSURE_DETAILS_LABEL: "Vennligst forklar",
  AML_PAGE_PEP_ADD_PEP_BUTTON_TEXT: "Legg til politisk eksponering",

  AML_PAGE_COMPANY_INFO_POWER_OF_ATTORNEY_LIST_TITLE: "Fullmaktshavere",
  AML_PAGE_COMPANY_INFO_POWER_OF_ATTORNEY_LIST_TEXT: "Den fysiske personen som handler på vegne av foretaket",
  AML_PAGE_COMPANY_INFO_POWER_OF_ATTORNEY_ADD_POA_BUTTON_TEXT: "Legg til fullmaktshaver",
  AML_PAGE_COMPANY_INFO_POWER_OF_ATTORNEY_MODAL_TITLE: "Fullmakt",
  AML_PAGE_COMPANY_INFO_POWER_OF_ATTORNEY_CONFIRM_BUTTON: "Lagre og lukk",

  AML_PAGE_COMPANY_INFO_OPTT_LIST_TITLE: "Andre som handler på vegne av foretaket",
  AML_PAGE_COMPANY_INFO_OPTT_LIST_TEXT:
    "Vennligst opplys om det finnes andre enn fullmaktshaver som opptrer på vegne av foretaket eller er gitt disposisjonsrett",
  AML_PAGE_COMPANY_INFO_OPTT_BUTTON_TEXT: "Legg til andre",
  AML_PAGE_COMPANY_INFO_OPTT_MODAL_TITLE: "Andre som handler",
  AML_PAGE_COMPANY_INFO_OPTT_CONFIRM_BUTTON: "Lagre og lukk",

  AML_PAGE_COMPANY_BACK_BUTTON: "Tilbake",
  AML_PAGE_COMPANY_NEXT_BUTTON: "Neste",
  AML_PAGE_COMPANY_SIGN_BUTTON: "Signering",

  AML_PAGE_COMPANY_INFO_ADDRESS_ERROR: "Du må fylle inn adresse",
  AML_PAGE_COMPANY_INFO_ZIP_CODE_ERROR: "Du må fylle inn postnummer",
  AML_PAGE_COMPANY_INFO_CITY_ERROR: "Du må fylle inn poststed",
  AML_PAGE_COMPANY_INFO_CEO_BIRTH_DATE_ERROR: "Du må fylle inn fødselsdato",
  AML_PAGE_COMPANY_INFO_CEO_FIRST_NAME_ERROR: "Du må fylle inn fornavn",
  AML_PAGE_COMPANY_INFO_CEO_LAST_NAME_ERROR: "Du må fylle inn etternavn",
  AML_PAGE_COMPANY_INFO_POSITION_ERROR: "Du må fylle inn stilling i foretaket",
  AML_PAGE_COMPANY_INFO_BENEFICIAL_OWNER_ERROR: "Du må svare på om personen er reell rettighetshaver i selskapet",
  AML_PAGE_COMPANY_INFO_PERCENTAGE_OF_OWNERSHIP_ERROR: "Du må fylle inn eierandel",
  AML_PAGE_COMPANY_INFO_PERSON_POLITICAL_EXPOSURE_ERROR: "Du må svare på om personen er politisk eksponert",
  AML_PAGE_COMPANY_INFO_PERSON_POLITICAL_EXPOSURE_MISSING_DETAILS_ERROR: "Du må fylle inn forklaring",
  AML_PAGE_COMPANY_INFO_POLITICAL_EXPOSURE_ERROR: "Du må svare på om personer i foretaket er politisk eksponert",
  AML_PAGE_COMPANY_INFO_POLITICAL_EXPOSURE_MISSING_DETAILS_ERROR: "Du må fylle inn forklaring",
  AML_PAGE_COMPANY_FINANCIAL_INSTRUMENTS_ERROR: "Du må velge finansielle instrument mangler",
  AML_PAGE_COMPANY_FINANCIAL_INSTRUMENTS_MISSING_DETAILS_ERROR: "Du må fylle inn forklaring",
  AML_PAGE_COMPANY_NATURE_OF_BUSINESS_ERROR: "Du må velge foretakets virksomhet",
  AML_PAGE_COMPANY_NATURE_OF_BUSINESS_MISSING_DETAILS_ERROR: "Du må fylle inn forklaring",
  AML_PAGE_COMPANY_PURPOSE_OF_ACCOUNT_ERROR: "Du må velge formålet med kontoen",
  AML_PAGE_COMPANY_PURPOSE_OF_ACCOUNT_MISSING_DETAILS_ERROR: "Du må fylle inn forklaring",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_CAPITAL_ERROR: "Du må velge kilde til kapital",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_CAPITAL_MISSING_DETAILS_ERROR: "Du må fylle inn forklaring",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_ERROR: "Du må velge midlenes opprinnelser",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_MISSING_DETAILS_ERROR: "Du må fylle inn forklaring",
  AML_PAGE_COMPANY_INFO_TOTAL_INVESTMENT_ERROR: "Du må velge totalt beløp som skal investeres",
  AML_PAGE_COMPANY_INFO_HANDLES_CASH_ERROR: "Du må svare på om foretaket håndterer kontanter",
  AML_PAGE_COMPANY_INFO_HANDLES_CASH_DETAILS_ERROR: "Du må fylle inn forklaring",
  AML_PAGE_COMPANY_BENEFICIAL_ERROR: "Du må svare på om foretaket har reelle rettighetshavere",
  AML_PAGE_COMPANY_BENEFICIAL_MISSING_OWNER_ERROR: "Du må legge til reelle rettighetshavere",
  AML_PAGE_COMPANY_INFO_POWER_OF_ATTORNEY_ERROR: "Du må legge til fullmaktshavere",

  AML_PAGE_COMPANY_PURPOSE_OF_ACCOUNT_TRADING_LABEL: "Handel i verdipapirer og tilknyttede tjenester",
  AML_PAGE_COMPANY_PURPOSE_OF_ACCOUNT_SAVING_LABEL: "Sparing",
  AML_PAGE_COMPANY_PURPOSE_OF_ACCOUNT_INVESTING_LABEL: "Investering",
  AML_PAGE_COMPANY_PURPOSE_OF_ACCOUNT_OTHER_LABEL: "Annet",

  AML_PAGE_COMPANY_FINANCIAL_LABEL: "Hvilke finansielle instrumenter regner Foretaket med å handle?",
  AML_PAGE_COMPANY_FINANCIAL_INSTRUMENTS_LISTED_SHARES_LABEL: "Noterte aksjer",
  AML_PAGE_COMPANY_FINANCIAL_INSTRUMENTS_UNLISTED_SHARES_LABEL: "Unoterte aksjer",
  AML_PAGE_COMPANY_FINANCIAL_INSTRUMENTS_MUTUAL_FUNDS_LABEL: "Verdipapirfond",
  AML_PAGE_COMPANY_FINANCIAL_INSTRUMENTS_BONDS_LABEL: "Obligasjoner",
  AML_PAGE_COMPANY_FINANCIAL_INSTRUMENTS_INTEREST_PRODUCT_LABEL: "Renteprodukter",
  AML_PAGE_COMPANY_FINANCIAL_INSTRUMENTS_GEARED_INSTRUMENTS_LABEL: "Lånefinansierte produkter",
  AML_PAGE_COMPANY_FINANCIAL_INSTRUMENTS_OTHER_LABEL: "Annet",

  AML_PAGE_COMPANY_NATURE_OF_BUSINESS_LABEL: "Hva er Foretakets virksomhet?",
  AML_PAGE_COMPANY_NATURE_OF_BUSINESS_INVESTMENT_COMPANY_LABEL: "Investeringsselskap",
  AML_PAGE_COMPANY_NATURE_OF_BUSINESS_FINANCIAL_INSTITUTION_LABEL: "Finansiell institusjon",
  AML_PAGE_COMPANY_NATURE_OF_BUSINESS_CRAFTMANSHIP_LABEL: "Håndverkstjenester",
  AML_PAGE_COMPANY_NATURE_OF_BUSINESS_CONSTRUNCTION_LABEL: "Bygg og anlegg",
  AML_PAGE_COMPANY_NATURE_OF_BUSINESS_PROPERTY_LABEL: "Eiendom",
  AML_PAGE_COMPANY_NATURE_OF_BUSINESS_TRADING_CARWORKSHOP_LABEL: "Bilpleie og bilverksted",
  AML_PAGE_COMPANY_NATURE_OF_BUSINESS_SERICE_AND_GOODS_LABEL: "Servicesektor og varehandel",
  AML_PAGE_COMPANY_NATURE_OF_BUSINESS_GOODS_TRANSPORT_LABEL: "Varetransport",
  AML_PAGE_COMPANY_NATURE_OF_BUSINESS_SERVICE_INDUSTRY_LABEL: "Servicenæring",
  AML_PAGE_COMPANY_NATURE_OF_BUSINESS_TRADING_INDUSTRY_LABEL: "Handel",
  AML_PAGE_COMPANY_NATURE_OF_BUSINESS_CONSULTING_LABEL: "Konsulenttjenester",
  AML_PAGE_COMPANY_NATURE_OF_BUSINESS_OTHER_LABEL: "Annet",

  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_CAPITAL_LABEL: "Hvor stammer Foretaks kapital fra?",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_CAPITAL_OPERATING_INCOME_LABEL: "Driftsoverskudd",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_CAPITAL_GOVERNMENT_GRANTS_LABEL: "Offentlige tilskudd",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_CAPITAL_PRIVATE_INVESTMENT_FROM_BENEFICIAL_OWNER_LABEL:
    "Privat investering fra reell rettighetshaver",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_CAPITAL_ISSUE_LABEL: "Emisjon",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_CAPITAL_LENDING_FINANCIAL_INSTITUTION_LABEL: "Lån fra finansiell institusjon",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_CAPITAL_LENDING_OTHER_CORPORATES_LABEL: "Lån fra annet foretak",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_CAPITAL_SELL_OR_DIVESTMENT_OF_ASSETS_LABEL: "Salg av eiendeler",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_CAPITAL_OTHER_LABEL: "Annet",

  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_LABEL: "Hvor stammer midlene som skal investeres hos Norne fra?",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_REVENUES_LABEL: "Overskudd",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_INVESTED_CAPITAL_LABEL: "Innskutt kapital",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_VAT_AND_INCOME_TAX_RETURNS_LABEL: "Moms og skatterefusjon",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_VAT_AND_INCOME_TAX_RETURNS_DETAILS_LABEL:
    "Vennligst forklar nærmere på moms og skatterefusjon. I forbindelse med hva, og hvor mye?",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_INTEREST_LABEL: "Renteinntekter",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_INTEREST_DETAILS_LABEL:
    "Vennligst forklar nærmere på renteinntekter. Hva fra, og hvor mye?",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_ISSUE_LABEL: "Emisjon",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_ISSUE_DETAILS_LABEL:
    "Vennligst forklar nærmere på emisjon. Når ble emisjon foretatt, og hvor mye penger ble hentet?",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_LOAN_LABEL: "Lån",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_LOAN_DETAILS_LABEL:
    "Vennligst forklar nærmere på lån. Hvem er långiver, og hvor stort er lånet?",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_GOVERNMENT_GRANTS_LABEL: "Offentlige tilskudd",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_GOVERNMENT_GRANTS_DETAILS_LABEL:
    "Vennligst forklar nærmere på offentlige tilskudd. Hvor fikk foretaket tilskudd fra, og hvor mye?",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_DIVIDEND_LABEL: "Utbytte",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_DIVIDEND_DETAILS_LABEL:
    "Vennligst forklar nærmere på utbytte. Fra hvilke papirer, og hvor mye?",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_SALES_OR_DIVESTMENT_OF_ASSETS_LABEL: "Salg av eiendeler",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_SALES_OR_DIVESTMENT_OF_ASSETS_DETAILS_LABEL:
    "Vennligst forklar nærmere på salg av eiendeler. Hva er solgt, og hvor stort var beløpet?",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_PRIVATE_INVESTMENT_FROM_BENEFICIAL_OWNER_LABEL:
    "Privat investering fra reelle rettighetshavere",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_PRIVATE_INVESTMENT_FROM_BENEFICIAL_OWNER_DETAILS_LABEL:
    "Vennligst forklar nærmere på privat investering fra reelle rettighetshavere. Hvilken reell rettighetshaver stammer midlene fra, og hvor mye?",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_OTHER_LABEL: "Annet",
  AML_PAGE_COMPANY_SOURCE_OF_FUNDS_SOURCES_OTHER_DETAILS_LABEL: "Vennligst forklar nærmere",

  AML_PAGE_COMPANY_TOTAL_INVESTMENT_LABEL: "Hvor mye regner Foretaket med å investere i løpet av kundeforholdet?",
  AML_PAGE_COMPANY_TOTAL_INVESTMENT_MORE_THAN_10_LABEL: "Over 10 mill NOK",
  AML_PAGE_COMPANY_TOTAL_INVESTMENT_BETWEEN_1_AND_10_LABEL: "1-10 mill NOK",
  AML_PAGE_COMPANY_TOTAL_INVESTMENT_LESS_THAN_1_LABEL: "Opptil 1 mill NOK",

  AML_PAGE_COMPANY_QUARTERLY_NUMBER_OF_TRANSACTIONS_LABEL: "Hva er forventet antall transaksjoner pr kvartal?",
  AML_PAGE_COMPANY_QUARTERLY_NUMBER_OF_TRANSACTIONS_FREQUENT_LABEL: "Hyppig (mer enn 10)",
  AML_PAGE_COMPANY_QUARTERLY_NUMBER_OF_TRANSACTIONS_MIDRANGE_LABEL: "Middels (mellom 5 og 10)",
  AML_PAGE_COMPANY_QUARTERLY_NUMBER_OF_TRANSACTIONS_RARELY_LABEL: "Sjelden (færre enn 5)",
  AML_PAGE_COMPANY_INFO_QUARTERLY_NUMBER_OF_TRANSACTIONS_ERROR: "Forventet antall transaksjoner pr kvartal mangler",

  AML_PAGE_COMPANY_HANDLES_CASH_LABEL: "Håndterer Foretaket kontanter i forbindelse med drift av sin virksomhet?",
  AML_PAGE_COMPANY_HANDLES_CASH_YES_LABEL: "Ja",
  AML_PAGE_COMPANY_HANDLES_CASH_NO_LABEL: "Nei",
  AML_PAGE_COMPANY_HANDLES_CASH_DETAILS_LABEL:
    "Vennligst forklar nærmere. Omtrent hvor stor andel av omsetningen utgjør kontanter?",

  AML_PAGE_COMPANY_INFO_POLITICAL_EXPOSURE_LABEL: "Politisk eksponering",
  AML_PAGE_COMPANY_INFO_POLITICAL_EXPOSURE_YES_LABEL:
    "Ja, undertegnede, reelle rettighetshavere, styremedlemmer eller andre personer som handler på vegne av foretaket er politisk eksponert person, nært familiemedlem eller kjent medarbeider til en politisk eksponert person",
  AML_PAGE_COMPANY_INFO_POLITICAL_EXPOSURE_NO_LABEL:
    "Nei, undertegnede, reelle rettighetshavere, styremedlemmer eller andre personer som handler på vegne av foretaket er ikke politisk eksponert person, nært familiemedlem eller kjent medarbeider til en politisk eksponert person",
  AML_PAGE_COMPANY_INFO_POLITICAL_EXPOSURE_DETAILS_LABEL: "Vennligst forklar nærmere",
  AML_PAGE_COMPANY_INFO_POLITICAL_EXPOSURE_HELP_TEXT_TITLE: "Er du politisk eksponert?",

  AML_PAGE_COMPANY_OWNERSHIP_UNITS_TITLE: "Eierskaps- og kontrollstruktur",
  AML_PAGE_COMPANY_OWNERSHIP_UNITS_INGRESS:
    "Hvis eierskap eller kontroll over foretaket er utøvd via andre foretak/juridiske personer; vennligst før opp foretak som inngår i eierskaps- og kontrollstrukturen eller send oss en beskrivelse på epost",
  AML_PAGE_COMPANY_OWNERSHIP_TITLE: "Reelle rettighetshavere",
  AML_PAGE_COMPANY_OWNERSHIP_LABEL: "Har Foretaket en eller flere reelle rettighetshavere?",
  AML_PAGE_COMPANY_OWNERSHIP_YES_LABEL: "Ja",
  AML_PAGE_COMPANY_OWNERSHIP_NO_LABEL: "Nei",
  AML_PAGE_COMPANY_OWNERSHIP_LIST_TITLE: "Reelle rettighetshavere",
  AML_PAGE_COMPANY_OWNERSHIP_ADD_OWNER_BUTTON_TEXT: "Legg til reell eier",
  AML_PAGE_COMPANY_ADD_OWNER_MODAL_TITLE: "Reell eier",
  AML_PAGE_COMPANY_OWNERSHIP_CONFIRM_ADD_BO_BUTTON_TEXT: "Lagre og lukk",
  AML_PAGE_COMPANY_INFO_OWNERSHIP_DETAILS_LABEL: "Eventuelle kommentarer:",
  AML_PAGE_COMPANY_OWNERSHIP_ADD_OWNERSHIP_BUTTON_TEXT: "Legg til eierskap",

  AML_PAGE_COMPANY_OWNERSHIP_FILE_UPLOAD_LABEL: "Eventuell beskrivelse kan lastes opp her",
  AML_PAGE_COMPANY_OWNERSHIP_FILE_UPLOAD_BUTTON_TEXT: "Last opp beskrivelse",

  AML_PAGE_COMPANY_BENEFICIAL_OWNERS_FILE_UPLOAD_LABEL: "Eventuell beskrivelse kan lastes opp her",
  AML_PAGE_COMPANY_BENEFICIAL_OWNERS_FILE_UPLOAD_BUTTON_TEXT: "Last opp beskrivelse",

  AML_PAGE_OWNERSHIP_UNIT_ADDRESS_ERROR: "Vennligst fyll ut adresse",
  AML_PAGE_OWNERSHIP_UNIT_ORG_NR_ERROR: "Vennligst fyll ut organisasjonsnummer",
  AML_PAGE_OWNERSHIP_UNIT_NAME_ERROR: "Vennligst fyll ut navn",
  AML_PAGE_OWNERSHIP_UNIT_CITY_ERROR: "Vennligst fyll ut poststed",
  AML_PAGE_OWNERSHIP_UNIT_SHARE_ERROR: "Vennligst fyll ut eierandel",
  AML_PAGE_OWNERSHIP_UNIT_POSTAL_CODE_ERROR: "Vennligst fyll ut postnummer",
  AML_PAGE_OWNERSHIP_UNIT_OWNERSHIP_PERCENTAGE_ERROR: "Vennligst fyll ut eierandel",

  AML_PAGE_OWNERSHIP_UNIT_TITLE: "Eierstruktur",
  AML_PAGE_OWNERSHIP_UNIT_ORG_NR_LABEL: "Organisasjonsnummer",
  AML_PAGE_OWNERSHIP_UNIT_NAME_LABEL: "Navn",
  AML_PAGE_OWNERSHIP_UNIT_ADDRESS_LABEL: "Adresse",
  AML_PAGE_OWNERSHIP_UNIT_POSTAL_CODE_LABEL: "Postnummer",
  AML_PAGE_OWNERSHIP_UNIT_CITY_LABEL: "Poststed",
  AML_PAGE_OWNERSHIP_UNIT_LEI_LABEL: "Lei",
  AML_PAGE_OWNERSHIP_UNIT_OWNERSHIP_PERCENTAGE_LABEL: "Eierandel %",
  AML_PAGE_OWNERSHIP_UNIT_COMMENT_LABEL: "Kommentar",

  AML_PAGE_BOARD_MEMBER_ADD_BOARD_MEMBER_TITLE: "Styremedlem",
  AML_PAGE_BOARD_MEMBER_BIRTH_DATE_LABEL: "Fødselsdato",
  AML_PAGE_BOARD_MEMBER_FIRST_NAME_LABEL: "Fornavn",
  AML_PAGE_BOARD_MEMBER_LAST_NAME_LABEL: "Etternavn",

  AML_PAGE_PEP_TITLE: "Politisk eksponert",
  AML_PAGE_PEP_NAME_LABEL: "Navn på person som er å anse som PEP",
  AML_PAGE_PEP_POSITION_LABEL: "Hvilket verv innehar PEP",
  AML_PAGE_PEP_RELATION_LABEL: "Relasjon til Kunde",
  AML_PAGE_PEP_NAME_ERROR: "Navn mangler",
  AML_PAGE_PEP_POSITION_ERROR: "Verv mangler",
  AML_PAGE_PEP_RELATION_ERROR: "Relasjon mangler",

  AML_PAGE_BOARD_MEMBER_TITLE: "Styremedlemmer",
  AML_PAGE_BOARD_MEMBER_ADD_BOARD_MEMBER_BUTTON_TEXT: "Legg til styremedlem",

  AML_PAGE_BOARD_MEMBER_BIRTH_DATE_ERROR: "Vennligst fyll ut fødselsdato",
  AML_PAGE_BOARD_MEMBER_FIRST_NAME_ERROR: "Vennligst fyll ut fornavn",
  AML_PAGE_BOARD_MEMBER_LAST_NAME_ERROR: "Vennligst fyll ut etternavn",

  AML_PAGE_BOARD_MEMBERS_MISSING_ERROR: "Vennligst legg til styremedlemmer",

  AML_PAGE_COMPANY_SIGNEES_TITLE: "Antihvitvask - Lag signeringsoppdrag",
  AML_PAGE_SIGNEES_ADD_SIGNEES_BUTTON_TEXT: "Legg til prokura",

  AML_PAGE_SIGNEE_ADD_SIGNEE_TITLE: "Styremedlem",
  AML_PAGE_SIGNEE_SSN_LABEL: "Fødselsnummer",
  AML_PAGE_SIGNEE_FIRST_NAME_LABEL: "Fornavn",
  AML_PAGE_SIGNEE_LAST_NAME_LABEL: "Etternavn",
  AML_PAGE_SIGNEE_EMAIL_LABEL: "E-post",

  AML_PAGE_SIGNEE_SSN_ERROR: "Vennligst fyll ut fødselsnummer",
  AML_PAGE_SIGNEE_FIRST_NAME_ERROR: "Vennligst fyll ut fornavn",
  AML_PAGE_SIGNEE_LAST_NAME_ERROR: "Vennligst fyll ut etternavn",
  AML_PAGE_SIGNEE_EMAIL_ERROR: "Vennligst fyll ut e-post",

  AML_PAGE_COMPANY_SIGNEES_DESCRIPTION:
    "Ifølge Brønnøysundregistrene kan person(er) nevnt under signere på vegne av foretaket. For å opprette kundeforholdet må dere ha fødsels- og personnummer (11 siffer) tilgjengelig for den/de som skal signere på kundeavtalen.",
  AML_PAGE_COMPANY_VALID_SIGNEES_COMBINATIONS_LABEL: "Vi har funnet disse gyldige kombinasjonene.",
  AML_PAGE_COMPANY_VALID_SIGNEES_COMBINATIONS_WITH_PERSONS_LABEL:
    "Vi har funnet disse mulige kombinasjonene med personene under.",
  AML_PAGE_COMPANY_VALID_SIGNEES_PERSONS_LABEL: "Personer",
  AML_PAGE_COMPANY_VALID_SIGNEES_FREE_TEXT_LABEL: "Vi har funnet disse i fritekst.",

  AML_PAGE_COMPANY_SUMMARY_COMPANY_INFO_TITLE: "Bedriftsinformasjon",
  AML_PAGE_COMPANY_SUMMARY_ORG_NR_LABEL: "Organisasjonsnummer",
  AML_PAGE_COMPANY_SUMMARY_NAME_LABEL: "Navn",
  AML_PAGE_COMPANY_SUMMARY_ADDRESS_LABEL: "Adresse",
  AML_PAGE_COMPANY_SUMMARY_POSTAL_CODE_LABEL: "Postnummer",
  AML_PAGE_COMPANY_SUMMARY_CITY_LABEL: "Poststed",
  AML_PAGE_COMPANY_SUMMARY_TAX_RESIDENCE_LABEL: "Skattemessig hjemland",
  AML_PAGE_COMPANY_SUMMARY_COUNTRY_LABEL: "Land",
  AML_PAGE_COMPANY_SUMMARY_EMAIL_LABEL: "E-post",
  AML_PAGE_COMPANY_SUMMARY_PHONE_LABEL: "Telefon",

  AML_PAGE_COMPANY_SUMMARY_POA_TITLE: "Fullmakter",
  AML_PAGE_COMPANY_SUMMARY_POA_SSN_LABEL: "Fødselsnummer",
  AML_PAGE_COMPANY_SUMMARY_POA_FIRST_NAME_LABEL: "Fornavn",
  AML_PAGE_COMPANY_SUMMARY_POA_LAST_NAME_LABEL: "Etternavn",
  AML_PAGE_COMPANY_SUMMARY_POA_EMAIL_LABEL: "E-post",
  AML_PAGE_COMPANY_SUMMARY_POA_PHONE_LABEL: "Telefon",
  AML_PAGE_COMPANY_SUMMARY_POA_ADDRESS_LABEL: "Adresse",
  AML_PAGE_COMPANY_SUMMARY_POA_POSTAL_CODE_LABEL: "Postnummer",
  AML_PAGE_COMPANY_SUMMARY_POA_CITY_LABEL: "Poststed",
  AML_PAGE_COMPANY_SUMMARY_POA_COUNTRY_LABEL: "Land",

  AML_PAGE_COMPANY_SUMMARY_OATT_TITLE: "Andre som handler på vegne av foretaket",
  AML_PAGE_COMPANY_SUMMARY_OATT_SSN_LABEL: "Fødselsnummer",
  AML_PAGE_COMPANY_SUMMARY_OATT_FIRST_NAME_LABEL: "Fornavn",
  AML_PAGE_COMPANY_SUMMARY_OATT_LAST_NAME_LABEL: "Etternavn",
  AML_PAGE_COMPANY_SUMMARY_OATT_EMAIL_LABEL: "E-post",
  AML_PAGE_COMPANY_SUMMARY_OATT_PHONE_LABEL: "Telefon",
  AML_PAGE_COMPANY_SUMMARY_OATT_ADDRESS_LABEL: "Adresse",
  AML_PAGE_COMPANY_SUMMARY_OATT_POSTAL_CODE_LABEL: "Postnummer",
  AML_PAGE_COMPANY_SUMMARY_OATT_CITY_LABEL: "Poststed",
  AML_PAGE_COMPANY_SUMMARY_OATT_COUNTRY_LABEL: "Land",

  AML_PAGE_COMPANY_SUMMARY_TITLE: "Antihvitvask - Oppsummering",
  AML_PAGE_COMPANY_SUMMARY_AML_TITLE: "Hvitvaskingskontroll",
  AML_PAGE_COMPANY_SUMMARY_AML_PURPOSE_OF_ACCOUNT_LABEL: "Hva er formålet med kundeforholdet?",
  AML_PAGE_COMPANY_SUMMARY_AML_FINANCIAL_INSTRUMENTS_LABEL: "Hvilke finansielle instrumenter regner foretaket med å handle?",
  AML_PAGE_COMPANY_SUMMARY_AML_TOTAL_INVESTMENTS_LABEL: "Hvor mye regner foretaket med å investere i løpet av kundeforholdet?",
  AML_PAGE_COMPANY_SUMMARY_AML_QUARTERLY_NUMBER_OF_TRANSACTIONS_LABEL: "Hva er forventet antall transaksjoner pr kvartal?",
  AML_PAGE_COMPANY_SUMMARY_AML_NATURE_OF_BUSINESS_LABEL: "Hva er foretakets virksomhet?",
  AML_PAGE_COMPANY_SUMMARY_AML_SOURCE_OF_FUNDS_CAPITAL_LABEL: "Foretakets kapital",
  AML_PAGE_COMPANY_SUMMARY_AML_SOURCE_OF_FUNDS_SOURCES_LABEL: "Midlenes opprinnelse",
  AML_PAGE_COMPANY_SUMMARY_AML_HANDLES_CASH_LABEL: "Håndterer kontanter",
  AML_PAGE_COMPANY_SUMMARY_AML_OWNERSHIP_UNITS_LABEL: "Eierskaps- og kontrollstruktur",
  AML_PAGE_COMPANY_SUMMARY_AML_OWNERSHIP_UNITS_ORG_NR_LABEL: "Org nr",
  AML_PAGE_COMPANY_SUMMARY_AML_OWNERSHIP_UNITS_NAME_LABEL: "Navn",
  AML_PAGE_COMPANY_SUMMARY_AML_OWNERSHIP_UNITS_LEI_LABEL: "Lei",
  AML_PAGE_COMPANY_SUMMARY_AML_OWNERSHIP_UNITS_ADDRESS_LABEL: "Adresse",
  AML_PAGE_COMPANY_SUMMARY_AML_OWNERSHIP_UNITS_OWNERSHIP_LABEL: "Eierskap",
  AML_PAGE_COMPANY_SUMMARY_AML_OWNERSHIP_UNITS_NO_OWNERSHIP_TEXT: "Ingen eierskap",
  AML_PAGE_COMPANY_SUMMARY_AML_BOARD_MEMBERS_LABEL: "Styremedlemmer",
  AML_PAGE_COMPANY_SUMMARY_AML_BENEFICIAL_OWNERS_LABEL: "Reelle rettighetshavere",
  AML_PAGE_COMPANY_SUMMARY_AML_BENEFICIAL_OWNERS_COMMENTS_LABEL: "Reelle rettighetshavere kommentar",
  AML_PAGE_COMPANY_SUMMARY_AML_POLITICAL_EXPOSURE_LABEL: "Politisk eksponert",

  AML_PAGE_COMPANY_SIGNING_TITLE: "Antihvitvask - Signering",
  AML_PAGE_COMPANY_SIGNING_INGRESS:
    "Kundeavtalen er nå klar til signering. Følg lenken under. Du har og fâtt en tilsendt epost, signerer du på lenken under trenger du ikke signere på epost.",

  AML_PAGE_COMPANY_SIGNEE_ERROR: "Minst 1 prokura er påkrevd.",

  AML_PAGE_POST_AML_FAILED_ERROR_MESSAGE: "Det oppstod en feil under innsendelse av skjemaer. Vennligst prøv igjen senere.",

  // Misc
  BLOG_TEASER_HEADER: "Sjekk ut Nornes blogg",
  BLOG_TEASER_TEXT:
    "Lyst å lære litt mer om finans, aksjer og fond? Lurer du på hvordan markedet er, eller på hvilken måte bærekraft kan påvirke økonomien?",
  BLOG_TEASER_LINK: "Bli litt klokere her",

  FUND_TEASER_HEADER: "Her er fondene til Norne",
  FUND_TEASER_TEXT:
    "For å gjøre det enklere har Norne satt sammen fond som gjør sparingen vedlikeholdsfri, enklere og smartere, og som passer for alle.",
  FUND_TEASER_LINK: "Les mer her",

  LOGIN_TEASER_HEADER: "Glemte du noe?",
  LOGIN_TEASER_TEXT: "Ingen fare, besøk oss igjen her.",
  LOGIN_TEASER_LINK: "Logg inn på nytt",

  GENERAL_ERROR_TITLE: "Det oppstod en feil",
  GENERAL_ERROR_DESCRIPTION: "Vennligst prøv igjen senere.",

  BANK_MESSAGE_CLOSE_LABEL: "Lukk",

  REAL_TIME_DIALOG_TITLE: "Gratis realtid",
  REAL_TIME_DIALOG_TEXT_1:
    'Norne Securities spanderer gratis realtid med dybde for alle aktive privatkunder. Bekreft under at du oppfyller Euronext sin definisjon av "Non-professional Subscriber" og trykk på knappen for å aktivere gratis realtid!',
  REAL_TIME_DIALOG_TEXT_2: "Realtid må reaktiveres hver måned, men du kan reaktivere så mange ganger du ønsker.",
  REAL_TIME_DIALOG_TEXT_3: "Du må logge inn på nytt når du aktiverer gratis realtid.",

  REAL_TIME_DIALOG_PROFESSIONAL_SUBSCRIBER_INFO:
    "Non-Professional Subscriber” means a Subscriber who is a natural person that is not required to be regulated or supervised by a body concerned with the regulation or supervision of investment or financial services and (ii) that meets all of the following criteria:",
  REAL_TIME_DIALOG_PROFESSIONAL_SUBSCRIBER_INFO_1:
    "He/she subscribes (i.e. registers and pays) in an individual and personal capacity. He/she does not subscribe as a principal, officer, partner, employee or agent, neither of any business, nor on behalf of any other individual",
  REAL_TIME_DIALOG_PROFESSIONAL_SUBSCRIBER_INFO_2:
    "He/she uses the Information solely for management of his/her own personal property, i.e. in his/her own personal investment activities. He/she does not use the Information as a trader to the public or for the investment of corporate funds. Furthermore, he/she does not operate a credit institution and/or a financial services institution",
  REAL_TIME_DIALOG_PROFESSIONAL_SUBSCRIBER_INFO_3:
    "He/she is not a day trader dealing at a credit institution’s and/or financial services institution’s premises or a self-employed individual maintaining an account on his/her company’s name",
  REAL_TIME_DIALOG_PROFESSIONAL_SUBSCRIBER_INFO_4:
    "He/she does not use the Information for the management of the property of third parties with or without a remunerative character for him/her. This includes, but is not limited to the Use of the Information for the (non-professional) management of third party assets and/or within the framework of a (non-commercial) investment club; and",
  REAL_TIME_DIALOG_PROFESSIONAL_SUBSCRIBER_INFO_5: "He/she does not use or process data for any business purpose.",
  REAL_TIME_DIALOG_SHOW_AGREEMENT_DETAILS_BUTTON_TEXT: "Non-Professional Subscriber",
  REAL_TIME_DIALOG_HIDE_BUTTON_TEXT: "Skjul",

  REAL_TIME_DIALOG_CONFIRM_TEXT: "Jeg bekrefter at jeg opplyller Euronext sin definisjon av ",

  REAL_TIME_DIALOG_NO_THANKS_BUTTON_TEXT: "Nei takk, jeg ønsker ikke gratis realtid",
  REAL_TIME_DIALOG_CONFIRM_BUTTON_TEXT: "Aktiver gratis realtid",

  REAL_TIME_DIALOG_NO_BANK_ACCOUNT_ERROR: "Du må registere en bankkonto for å aktivere gratis realtid",
  REAL_TIME_DIALOG_LOADING_BANK_ACCOUNTS_ERROR:
    "Det oppstod en feil under henting av bankkontoer for registrering av realtidsabonnement. Vennligst prøv igjen senere.",

  REAL_TIME_DIALOG_GENERAL_API_ERROR: "Det oppstod en feil under aktivering av gratis realtid. Vennligst prøv igjen senere.",

  REAL_TIME_DIALOG_MISSING_MAIN_PORTFOLIO_ERROR:
    "Du må ha en hovedkonto for å aktivere gratis realtid. Vennligst kontakt kundeservice.",

  NOT_FOUND_PAGE_TITLE: "Vi finner ikke siden",
  NOT_FOUND_PAGE_TEXT: "Sjekk om du har skrevet riktig adresse. Gå til forsiden ved å klikke på knappen under.",
  NOT_FOUND_PAGE_GO_HOME: "Gå til Min Side",

  ACCOUNT_NOT_FOUND_TITLE: "Vi finner ikke kontoen",
  ACCOUNT_NOT_FOUND_TEXT: "Gå til forsiden ved å klikke på knappen under.",
  ACCOUNT_NOT_FOUND_GO_HOME: "Gå til Min Side",

  ERROR_PAGE_TITLE: "Beklager, dette ble feil",
  ERROR_PAGE_GO_HOME: "Gå til forsiden",

  CREATE_VPS_ACCOUNT_HEADING: "Opprett VPS-konto",
  CREATE_VPS_ACCOUNT_INGRESS: "Opprett en VPS-konto for å handle aksjer",
  CREATE_VPS_ACCOUNT_ABORT_BUTTON: "Avbryt",
  CREATE_VPS_ACCOUNT_CONFIRM_BUTTON: "Bekreft",
  CREATE_VPS_ACCOUNT_FAILED: "Det oppstod en feil under opprettelse av VPS-konto. Vennligst prøv igjen senere.",

  CREATE_ACCOUNT_HEADING: "Opprett verdipapirkonto",
  CREATE_ACCOUNT_INGRESS: "Opprett en verdipapirkonto for å handle aksjer og fond",
  CREATE_ACCOUNT_ERROR_GENERIC: "Det oppstod en feil under opprettelse av verdipapirkonto. Vennligst prøv igjen senere.",
  CREATE_ACCOUNT_RECEIPT: "Din Verdipapirkonto er nå opprettet.",
  CREATE_ACCOUNT_ABORT_BUTTON: "Avbryt",
  CREATE_ACCOUNT_CONFIRM_BUTTON: "Opprett verdipapirkonto",
  CREATE_ACCOUNT_CORPORATE_ERROR_TEXT:
    "Opprett verdipapirkonto er ikke tilgjengelig for bedrift i portalen. Vennligst kontakt kundeservice.",

  ASK_INFO_PAGE_HEADER: "Aksjesparekonto",
  ASK_INFO_PAGE_PORTFOLIO_LABEL: "Konto",
  ASK_INFO_PAGE_YEAR_LABEL: "År",
  ASK_INFO_PAGE_YEAR_PLACEHOLDER: "Velg år",
  ASK_INFO_PAGE_DATE_HEADER: "Opplysninger per $DATE",
  ASK_INFO_PAGE_DEPOSIT_LABEL: "Innskutt beløp",
  ASK_INFO_PAGE_MARKET_VALUE_LABEL: "Markedsverdi",
  ASK_INFO_PAGE_UNREALIZED_PROFIT_LABEL: "Urealisert gevinst",
  ASK_INFO_PAGE_DEPOSIT_THIS_YEAR_LABEL: "Innskudd i løpet av året",
  ASK_INFO_PAGE_WITHDRAWAL_THIS_YEAR_LABEL: "Uttak i løpet av året",
  ASK_INFO_PAGE_UNUSED_SHEILDING_LABEL: "Ubenyttet skjerming",
  ASK_INFO_PAGE_USED_SHEILDING_LABEL: "Benyttet skjerming",
  ASK_INFO_PAGE_INFO_TEXT:
    "Du kan ta ut *innskutt beløp + ubenyttet skjerming* uten å betale skatt av uttaket. Per $DATE utgjorde dette $AMOUNT for din aksjesparekonto.",
  ASK_INFO_PAGE_TRANSACTION_HEADER: "Transaksjoner",
  ASK_INFO_PAGE_TRANSACTION_DATE_HEADER: "Dato",
  ASK_INFO_PAGE_TRANSACTION_AMOUNT_HEADER: "Beløp",
  ASK_INFO_PAGE_TRANSACTION_DESCRIPTION_HEADER: "Beskrivelse",
  ASK_INFO_PAGE_ERROR_GETTING_ASK_INFO: "Det oppstod en feil under henting av opplysninger om aksjesparekonto.",
  ASK_INFO_PAGE_NO_ASK_INFO_FOUND: "Ingen opplysninger om aksjesparekonto funnet.",

  CREATE_AGREEMENT_SIMPLE_TITLE: "Opprett spareavtale",
  CREATE_AGREEMENT_SIMPLE_FUND_NOT_FOUND: "Oppgitt fond er ikke gyldig for enkel spareavtale, vennligst kontakt kundeservice",
  CREATE_AGREEMENT_SIMPLE_TEXT_MILJO_FUND:
    "For deg som ønsker å spare med fokus på grønn omstilling: grønn energi, ressurseffektivitet og elektrifisering. $FUND_NAME har bærekraftige investeringer som formål og investerer i globale selskaper. ",
  CREATE_AGREEMENT_SIMPLE_TEXT_1: "For å gjøre sparing i fond enklere har vi satt sammen en portefølje: ",
  CREATE_AGREEMENT_SIMPLE_TEXT_2:
    " er et vedlikeholdsfritt $FUND_TYPE bestående av et utvalg andre underliggende fond der du slipper å tenke på om du sparer rett. Ved å spare i $FUND_NAME sprer du risikoen på flere fond. ",
  CREATE_AGREEMENT_SIMPLE_PRICE_TEXT: "Forvaltningskostnad $FUND_NAME er $FUND_PRICE_PERCENT %",
  CREATE_AGREEMENT_SIMPLE_PRICE_LINK_LABEL: "Honorar bank finner du ",
  CREATE_AGREEMENT_SIMPLE_PRICE_LINK_TEXT: "her",
  CREATE_AGREEMENT_SIMPLE_DISCLAIMER_TEXT:
    "Historisk avkastning er ingen garanti for framtidig avkastning. Framtidig avkastning vil bl. a. avhenge av markedsutviklingen, forvalters dyktighet, verdipapirfondets risiko, samt kostnader ved tegning, forvaltning og innløsning.",
  CREATE_AGREEMENT_SIMPLE_BANK_ACCOUNT_LABEL: "Velg bankkonto beløpet skal trekkes fra",
  CREATE_AGREEMENT_SIMPLE_OTHER_BANK_ACCOUNT_LABEL: "Skriv inn bankkontonummeret",
  CREATE_AGREEMENT_SIMPLE_PORTFOLIO_LABEL: "Konto",
  CREATE_AGREEMENT_SIMPLE_MONTHLY_AMOUNT_LABEL: "Månedlig sparebeløp",
  CREATE_AGREEMENT_SIMPLE_ONE_TIME_AMOUNT_LABEL: "Engangsbeløp",
  CREATE_AGREEMENT_SIMPLE_OPEN_BUTTON_TEXT: "Bli kunde / logg inn",
  CREATE_AGREEMENT_SIMPLE_BUTTON_TEXT: "Opprett spareavtale",
  CREATE_AGREEMENT_SIMPLE_ONE_TIME_AMOUNT_ERROR: "Engangsbeløp må være 0 eller mer enn 250 kr.",
  CREATE_AGREEMENT_SIMPLE_MONTHLY_AMOUNT_ERROR: "Vennligst fyll ut månedlig sparebeløp. Minimumsbeløp er 250 kr.",
  CREATE_AGREEMENT_SIMPLE_PORTFOLIO_ERROR: "Vennligst velg konto",
  CREATE_AGREEMENT_SIMPLE_BANK_ACCOUNT_ERROR: "Ugyldig kontonummer",
  CREATE_AGREEMENT_SIMPLE_PERIOD_DAY_NUMBER: "Trekkdag i måneden",
  CREATE_AGREEMENT_SIMPLE_ONE_TIME_AMOUNT: "Engangsbeløp",
  CREATE_AGREEMENT_SIMPLE_FUND: "Fond",
  CREATE_AGREEMENT_SIMPLE_ERROR: "Det oppstod en feil under opprettelse av spareavtale. Vennligst prøv igjen senere.",
  CREATE_AGREEMENT_SIMPLE_START_UP_FAILED: "Spareavtalen er opprettet, men innbetalingen av engangsbeløpet feilet.",

  STOCK_FAVORITES_TITLE: "Favoritter",
  STOCK_FAVORITES_NO_FAVORITES: "Du har ingen favoritter. Gå inn på en aksje og trykk på stjernen for å legge til.",

  MY_STOCKS_TITLE: "Mine aksjer",
  MY_STOCKS_NO_STOCKS: "Du har ingen aksjer.",
  MY_STOCKS_EQUITIES_LINK: "Se full porteføljevisning",

  GIFT_CARD_TITLE: "Fond i gave",
  GIFT_CARD_TEXT_1:
    "Venner og familie kan kjøpe fond i gave til deg ved å gjøre innbetaling med følgende kid og bankkontonummer. Du velger om du vil ha fondsgaven i ",
  GIFT_CARD_TEXT_2: " eller ",
  GIFT_CARD_TEXT_3: ".",
  GIFT_CARD_TEXT_4: "Send bankkontonummer og ønsket KID til giver.",
  GIFT_CARD_ACCOUNT_NUMBER_LABEL: "Bankkontonummer",
  GIFT_CARD_KID_LABEL: "KID for innbetaling (velg én)",
  GIFT_CARD_SEND_RECIPIENTS_BUTTON: "Send giverinformasjon",
  GIFT_CARD_SEND_RECIPIENTS_COMPLETED: "Giverinformasjon sendt",
  GIFT_CARD_CLOSE_BUTTON: "Lukk",
  GIFT_CARD_RECIPIENT_LABEL: "For smidigere behandling, skriv hvem som er gavegiver og din relasjon til gavegiver under.",
  GIFT_CARD_RECIPIENT_ERROR: "Vennligst fyll ut giverinformasjon.",
  GIFT_CARD_SEND_RECIPIENTS_ERROR: "Det oppstod en feil under sending av giverinformasjon. Vennligst prøv igjen senere.",

  SEARCH_NO_RESULTS: "Ingen treff",
  SEARCH_PLACEHOLDER: "Søk",

  CLOSE_ACCOUNT_HEADING: "Avslutt konto",
  CLOSE_ACCOUNT_INGRESS: "Sikker på at du vil avslutte kontoen?",
  CLOSE_ACCOUNT_ABORT_BUTTON: "Avbryt",
  CLOSE_ACCOUNT_CONFIRM_BUTTON: "Avslutt konto",
  CLOSE_ACCOUNT_FAILED: "Det oppstod en feil under avslutning av konto. Vennligst prøv igjen senere.",
  CLOSE_ACCOUNT_SUCCESS: "Kontoen er avsluttet.",
  CLOSE_ACCOUNT_GO_TO_DASHBOARD_BUTTON: "Gå til Min side",
  CLOSE_ACCOUNT_HAS_POSITIONS_OR_SAVINGSAGREEMENTS_ERROR:
    "Kontoen kan ikke avsluttes da den har tilknyttet fond, aksjer eller spareavtaler.",
  CLOSE_ACCOUNT_CLOSE_BUTTON: "Lukk",
  CLOSE_ACCOUNT_MAIN_PORTFOLIO_ERROR:
    "Kontoen kan ikke avsluttes da den er hovedkonto. Vennligst ta kontakt med kundeservice om du ønsker å avslutte denne kontoen.",

  TRADER_DASHBOARD_NEW_LINK: "Alle nyheter",

  MY_STOCKS_TICKER_HEADER: "Ticker",
  MY_STOCKS_PORTFOLIO_HEADER: "Konto",
  MY_STOCKS_NAME_HEADER: "Navn",
  MY_STOCKS_QUANTITY_HEADER: "Antall",
  MY_STOCKS_VALUE_HEADER: "Verdi",
  MY_STOCKS_LAST_HEADER: "Siste",
  MY_STOCKS_CHANGE_HEADER: "±%",
  MY_STOCKS_TURNOVER_HEADER: "Omsetning",
  MY_STOCKS_PROFIT_LOSS_HEADER: "Ureal. gev/tap",
  MY_STOCKS_BUY_BUTTON_TEXT: "Kjøp",
  MY_STOCKS_SELL_BUTTON_TEXT: "Selg",

  INVALID_SESSION_PAGE_TITLE: "Utløpt sesjon",
  INVALID_SESSION_PAGE_TEXT: "Sesjonen din har utløpt.",
  INVALID_SESSION_RELOAD_PAGE_BUTTON_TEXT: "Last inn siden på nytt",

  NO_ACCESS_TO_FEATURE_PAGE_TITLE: "Tjenesten er ikke tilgjengelig",
  NO_ACCESS_TO_FEATURE_PAGE_TEXT: "Tjenesten er ikke tilgjengelig. Ta kontakt med kundeservice for mer informasjon.",
  NO_ACCESS_TO_FEATURE_GO_TO_MAIN_PAGE_BUTTON_TEXT: "Gå til Min Side",

  STOCK_DETAILS_ALL_INTRADAY_TRADES_BUTTON_TEXT: "Alle dagens handler",

  ALARM_LIST_TITLE: "Alarmer",
  ALARM_LIST_NO_ALARMS: "Du har ingen alarmer",
  ALARM_ABOVE_LIMIT_TEXT: "Stiger til over",
  ALARM_BELOW_LIMIT_TEXT: "Synker til under",
  CREATE_ALARM_BUTTON_TEXT: "Legg til alarm",
  CREATE_NEWS_ALARM_BUTTON_TEXT: "Legg til nyhetsalarm",
  ALARM_LIST_PRICE_ALARMS_TITLE: "Kursalarmer",
  ALARM_LIST_NEWS_ALARMS_TITLE: "Nyhetsalarmer",

  ALARM_HISTORIC_LIST_TITLE: "Tidligere alarmer",
  ALARM_HISTORIC_LIST_TRIGGERED_DATE_HEADER: "Dato",
  ALARM_HISTORIC_LIST_TICKER_HEADER: "Ticker",
  ALARM_HISTORIC_LIST_TRIGGERED_RATE_HEADER: "Over/Under",

  ADD_ALARM_TITLE: "Opprett alarm",
  ADD_ALARM_PRICE_LABEL: "Kursalarm",
  ADD_ALARM_NEWS_LABEL: "Nyhetsalarm",
  ADD_ALARM_TRIGGER_PRICE_LABEL: "Kurs",
  ADD_ALARM_TRIGGER_PRICE_ERROR: "Vennligst fyll ut kurs",
  ADD_ALARM_LAST_LABEL: "Siste kurs",
  ADD_ALARM_BUTTON_TEXT: "Opprett alarm",
  ADD_ALARM_ABORT_BUTTON_TEXT: "Avbryt",
  ADD_ALARM_PERCENT_ABOVE_TEXT: "opp fra siste kurs",
  ADD_ALARM_PERCENT_BELOW_TEXT: "ned fra siste kurs",
  ADD_ALARM_API_ERROR: "Det oppstod en feil under opprettelse av alarm. Vennligst prøv igjen senere.",

  ALARM_CREATE_ALARM_LABEL: "Legg til alarm",
  ALARM_LIST_TICKER_HEADER: "Ticker",
  ALARM_LIST_TRIGGER_OVER_UNDER_HEADER: "Over/Under",
  ALARM_LIST_TRIGGER_PRICE_HEADER: "Alarmkurs",
  ALARM_LIST_CHANGE_HEADER: "Endring %",

  FALLBACK_ERROR_TITLE: "Beklager, dette ble feil",
  FALLBACK_ERROR_MESSAGE: "Vennligst prøv igjen senere.",

  GIFT_PAGE_TITLE: "Fond i gave",
  GIFT_PAGE_UNDER_TITLE: "Den fornuftige gaven til konfirmasjon, dåp, bursdag og jul:",
  GIFT_PAGE_TEXT_1: "Den som gir fond i gave trenger ikke å være fondskunde.",
  GIFT_PAGE_TEXT_2: "Gavemottaker må ha aksjesparekonto.",
  GIFT_PAGE_TEXT_3: "Vårt gavefond er Norne Aksje. Fondet er et vedlikeholdsfritt alternativ med flere underliggende fond.",
  GIFT_PAGE_TEXT_4: "Gå til motta gave for å finne kontonummer og KID som du må dele med giver.",
  GIFT_PAGE_PORTFOLIO_LABEL: "Velg til hvilken ask-konto du ønsker å motta gaven:",
  GIFT_PAGE_SHOW_POPUP_BUTTON: "Motta gave",

  MY_PROFILE_TITLE: "Profil",
  MY_PROFILE_INGRESS: "Personopplysninger vi har registert om deg",
  MY_PROFILE_NAME_LABEL: "Navn",
  MY_PROFILE_ADDRESS_LABEL: "Adresse",
  MY_PROFILE_PHONE_LABEL: "Telefonnummer",
  MY_PROFILE_EMAIL_LABEL: "E-post",
  MY_PROFILE_TAX_COUNTRY_LABEL: "Skattepliktig land",
  MY_PROFILE_FAVORITES_LINK_TEXT: "Mine favoritter",
  MY_PROFILE_ALARMS_LINK_TEXT: "Mine alarmer",
  MY_PROFILE_SUBSCRIPTIONS_LINK_TEXT: "Mine abonnement",
  MY_PROFILE_MAILBOX_LINK_TEXT: "Postkasse",

  COMPARE_FUNDS_ADD_FUND_LABEL: "Legg til fond",
  COMPARE_FUNDS_INGRESS_TEXT: "Søk opp fond for å sammenligne prisutvikling (maks 5 fond)",

  STOCK_RIGHTS_APPROVAL_TITLE: "Hensiktsmessighetstest for kjøp av tegningsretter",
  STOCK_RIGHTS_APPROVAL_INFO_1:
    "Tegningsretter er et kompleks finansielt instrument. I henhold til lov om verdipapirhandel er Norne pålagt å sikre at du har tilstrekkelig kunnskap og erfaring til å forstå risikoen med handel slike instrument.",
  STOCK_RIGHTS_APPROVAL_INFO_2:
    "For å kunne ta stilling til din kunnskap og erfaring tilknyttet tegningsretter må vi be deg gjennomføre denne hensiktsmessighetstesten.",
  STOCK_RIGHTS_APPROVAL_INFO_3:
    "Hva er tegningsretter? En tegningsrett i et børsnotert selskap deles ut til eksisterende aksjonærer i selskapet. Tegningsretter gir deg som eier en fortrinnsrett til å kjøpe nye aksjer utstedt av selskapet til en forhåndsavtalt pris, normalt lavere enn markedspris. Du må som regel svare aktivt på om du ønsker å benytte tegningsrettene. Det er viktig å sette seg inn i hva som gjelder i det enkelte tilfelle. Tegningsretter har kun verdi i en begrenset tidsperiode.",
  STOCK_RIGHTS_APPROVAL_INFO_4:
    "Innen utløp av fristen kan du kjøpe og selge tegningsretter til markedspris. Etter fristens utløp vil tegningsrettene være verdiløse.",

  STOCK_RIGHTS_APPROVAL_EDUCATION_TITLE: "Utdanning",
  STOCK_RIGHTS_APPROVAL_EDUCATION_LABEL: "Hvilken utdanning har du?",
  STOCK_RIGHTS_APPROVAL_EDUCATION_HIGH_SCHOOL_LABEL: "Grunnskole/videregående",
  STOCK_RIGHTS_APPROVAL_EDUCATION_UNIVERSITY_LABEL: "Høyskole/universitet",
  STOCK_RIGHTS_APPROVAL_EDUCATION_ERROR: "Vennligst fyll ut utdanning",

  STOCK_RIGHTS_APPROVAL_FINANCIAL_EDUCATION_LABEL: "Har du utdanning innen finans?",
  STOCK_RIGHTS_APPROVAL_FINANCIAL_EDUCATION_YES_LABEL: "Ja",
  STOCK_RIGHTS_APPROVAL_FINANCIAL_EDUCATION_NO_LABEL: "Nei",
  STOCK_RIGHTS_APPROVAL_FINANCIAL_EDUCATION_ERROR: "Vennligst fyll ut økonomiutdanning",

  STOCK_RIGHTS_APPROVAL_FINANCE_WORK_EXPERIENCE_TITLE: "Yrkeserfaring",
  STOCK_RIGHTS_APPROVAL_FINANCE_WORK_EXPERIENCE_LABEL: "Har du arbeidserfaring fra finansbransjen?",
  STOCK_RIGHTS_APPROVAL_FINANCE_WORK_EXPERIENCE_YES: "Ja",
  STOCK_RIGHTS_APPROVAL_FINANCE_WORK_EXPERIENCE_NO: "Nei",
  STOCK_RIGHTS_APPROVAL_FINANCE_WORK_EXPERIENCE_ERROR: "Vennligst fyll ut yrkeserfaring",

  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_TITLE: "Erfaring med finansielle produkter",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCKS_LABEL: "Aksjetransaksjoner siste år",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCKS_LOW_LABEL: "Ingen",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCKS_MEDIUM_LABEL: "Mindre enn 5",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCKS_HIGH_LABEL: "Mer enn 5",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCKS_TRANSACTIONS_ERROR:
    "Vennligst fyll ut antall aksjetransaksjoner siste år",

  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCKS_EXPERIENCE_LABEL: "Kunnskap med kjøp av aksjer",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCKS_LOW_EXPERIENCE_LABEL: "Lav",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCKS_MEDIUM_EXPERIENCE_LABEL: "Middels",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCKS_HIGH_EXPERIENCE_LABEL: "Høy",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCKS_EXPERIENCE_ERROR: "Vennligst fyll ut erfaring med aksjer",

  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_PRIVATE_EQUITY_LABEL: "Unoterte aksjetransaksjoner siste år",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_PRIVATE_EQUITY_LOW_LABEL: "Ingen",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_PRIVATE_EQUITY_MEDIUM_LABEL: "Mindre enn 5",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_PRIVATE_EQUITY_HIGH_LABEL: "Mer enn 5",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_PRIVATE_EQUITY_TRANSACTIONS_ERROR:
    "Vennligst fyll ut antall unoterte aksjetransaksjoner siste år",

  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_PRIVATE_EQUITY_EXPERIENCE_LABEL: "Kunnskap med unoterte aksjer",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_PRIVATE_EQUITY_LOW_EXPERIENCE_LABEL: "Lav",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_PRIVATE_EQUITY_MEDIUM_EXPERIENCE_LABEL: "Middels",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_PRIVATE_EQUITY_HIGH_EXPERIENCE_LABEL: "Høy",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_PRIVATE_EQUITY_EXPERIENCE_ERROR:
    "Vennligst fyll ut erfaring med unoterte aksjer",

  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCK_RIGHTS_LABEL: "Kjøp av tegningsretter siste år",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCK_RIGHTS_LOW_LABEL: "Ingen",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCK_RIGHTS_MEDIUM_LABEL: "Mindre enn 5",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCK_RIGHTS_HIGH_LABEL: "Mer enn 5",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCK_RIGHTS_TRANSACTIONS_ERROR:
    "Vennligst fyll ut antall tegningsrettransaksjoner siste år",

  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCK_RIGHTS_EXPERIENCE_LABEL: "Kunnskap med kjøp av tegningsretter",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCK_RIGHTS_LOW_EXPERIENCE_LABEL: "Lav",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCK_RIGHTS_MEDIUM_EXPERIENCE_LABEL: "Middels",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCK_RIGHTS_HIGH_EXPERIENCE_LABEL: "Høy",
  STOCK_RIGHTS_APPROVAL_EXPERIENCE_WITH_FINANCIAL_PRODUCTS_STOCK_RIGHTS_EXPERIENCE_ERROR:
    "Vennligst fyll ut erfaring med tegningsretter",

  STOCK_RIGHTS_APPROVAL_INVESTING_EXPERIENCE_TITLE: "Erfaring med investeringstjenester",
  STOCK_RIGHTS_APPROVAL_INVESTING_EXPERIENCE_LABEL: "Deltagelse i emisjoner eller spredningssalg",
  STOCK_RIGHTS_APPROVAL_INVESTING_EXPERIENCE_YES: "Ja",
  STOCK_RIGHTS_APPROVAL_INVESTING_EXPERIENCE_NO: "Nei",
  STOCK_RIGHTS_APPROVAL_INVESTING_EXPERIENCE_ERROR: "Vennligst fyll ut erfaring med investeringstjenester",

  STOCK_RIGHTS_APPROVAL_ABORT_BUTTON: "Avbryt",
  STOCK_RIGHTS_APPROVAL_SUBMIT_BUTTON: "Send inn",

  APPROPRIATENESS_TEST_RECEIPT_TITLE: "Hensiktsmessighetstest mottatt",
  APPROPRIATENESS_TEST_RECEIPT_TEXT: "Din hensiktsmessighetstest er gyldig og du kan nå handle tegningsretter.",

  APPROPRIATENESS_TEST_VALID_TITLE: "Gyldig hensiktsmessighetstest",
  APPROPRIATENESS_TEST_VALID_TEXT: "Du har allerede en gyldig hensiktsmessighetstest. Du kan nå handle tegningsretter. ",

  APPROPRIATENESS_TEST_NOT_VALID_TITLE: "Ugyldig hensiktsmessighetstest",
  APPROPRIATENESS_TEST_NOT_VALID_TEXT:
    "Hensikstsmessighetstest finnes allerede under finner du vår frarådning på bakgrunn av svarene du har gitt.",

  APPROPRIATE_TEST_GENERAL_VALIDATION_ERROR:
    "Det oppstod en feil ved innsending av hensiktsmessighetstesten. Vennligst prøv igjen senere.",

  APPROPRIATENESS_TEST_EXPIRED: "Hensiktsmessighetstest er utløpt eller mangler. ",
  APPROPRIATENESS_TEST_EXPIRED_REOPEN_TEST: "Åpne hensiktsmessighetstest",

  UNLISTED_SECURITIES_TITLE: "Unoterte aksjer og egenkapitalbevis",
  UNLISTED_SECURITIES_EK_LINK_TEXT: "Les mer om egenkapitalbevis her",
  UNLISTED_SECURITIES_BACK_TO_OVERVIEW_LINK_TEXT: "Tilbake til oversikt",
  UNLISTED_SECURITIES_INTRO_TEXT:
    "Hos Norne Securities kan du handle unoterte aksjer og egenkapitalbevis. Unoterte aksjer er ikke notert på børs eller på en regulert markedsplass.",
  UNLISTED_SECURITIES_INTRO_TEXT_2:
    "Unoterte verdipapir regnes som kompliserte verdipapir. Dette innebærer blant annet at verdipapirene kan være vanskeligere å omsette.",
  UNLISTED_SECURITIES_BULLET_TEXT_1: "Aksjer som ikke er notert på markedsplass",
  UNLISTED_SECURITIES_BULLET_TEXT_2: "0,5 % minimum : 500,- kroner",
  UNLISTED_SECURITIES_BULLET_TEXT_3: "Handel hver virkedag 09.00 – 16.25",
  UNLISTED_SECURITIES_BULLET_TEXT_4: "Du kan ikke kjøpe unoterte aksjer innenfor ASK.",

  UNLISTED_SECURITIES_INGRESS_TITLE: "$BANK_NAME – ANNENHÅNDSINTERESSER",
  UNLISTED_SECURITIES_INGRESS:
    "Oversikt over indikativ interesse for kjøp og salg av $BANK_NAME. Følg instruksene under for å registrere din interesse hos oss. Vi tar 0,5% i kurtasje (minst 500 kr) ved handel.",
  UNLISTED_SECURITIES_BUY_AND_SELL_TITLE: "Kjøp og salg",
  UNLISTED_SECURITIES_BUY_AND_SELL_TEXT_1: "Registrer din interesse ved å sende en e-post på ",
  UNLISTED_SECURITIES_BUY_AND_SELL_TEXT_2: " eller ring inn på tlf. ",
  UNLISTED_SECURITIES_RISK_TITLE: "Mer om risiko forbundet med unoterte verdipapir",
  UNLISTED_SECURITIES_RISK_READ_MORE: "Les mer",
  UNLISTED_SECURITIES_RISK_TEXT:
    "Unoterte verdipapir regnes som komplekse produkter. Dette innebærer økt risiko sammenlignet med verdipapir notert på børs. Den økte risikoen skyldes blant annet vanskeligere tilgang på informasjon om utsteder og generelt sett lavere omsetning, som kan gjøre det vanskeligere å få kjøpt/solgt verdipapiret til ønsket kurs. Mer informasjon om risiko ved finansielle instrumenter og unoterte verdipapir finner du",
  UNLISTED_SECURITIES_RISK_LINK_TEXT: "her",

  UNLISTED_SECURITIES_CLOSE_BUTTON: "Lukk",

  UNLISTED_SECURITIES_SELLERS_TITLE: "Salgsinteresse",
  UNLISTED_SECURITIES_SELLERS_COUNT_LABEL: "Antall selgere",
  UNLISTED_SECURITIES_SELLERS_VOLUME_LABEL: "Volum",
  UNLISTED_SECURITIES_SELLERS_PRICE_LABEL: "Kurs",
  UNLISTED_SECURITIES_NO_SELLERS: "Ingen registrert salgsinteresse",

  UNLISTED_SECURITIES_BUYERS_TITLE: "Kjøpsinteresse",
  UNLISTED_SECURITIES_BUYERS_COUNT_LABEL: "Antall kjøpere",
  UNLISTED_SECURITIES_BUYERS_VOLUME_LABEL: "Volum",
  UNLISTED_SECURITIES_BUYERS_PRICE_LABEL: "Kurs",
  UNLISTED_SECURITIES_NO_BUYERS: "Ingen registrert kjøpsinteresse",

  UNLISTED_SECURITIES_ORDER_BOOK_FOOTER:
    "Listene over inneholder kun indikativ kjøp- og salgsinteresse som må bekreftes hos motpart før en eventuell handel. Norne Securities bærer ikke noe ansvar for registrerte kurser. Verdipapiret er ikke notert på noen børs eller regulert markedsplass og regnes derfor som et komplisert verdipapir. Aksjer og egenkapitalbevis kan ikke ansees som risikofrie.",

  UNLISTED_SECURITIES_TRADE_HISTORY_TITLE: "Historisk omsetning",
  UNLISTED_SECURITIES_TRADE_HISTORY_VOLUME_LABEL: "Volum",
  UNLISTED_SECURITIES_TRADE_HISTORY_DATE_LABEL: "Dato",
  UNLISTED_SECURITIES_TRADE_HISTORY_OPEN_LABEL: "Åpen",
  UNLISTED_SECURITIES_TRADE_HISTORY_CLOSE_LABEL: "Siste",
  UNLISTED_SECURITIES_TRADE_HISTORY_LOW_LABEL: "Lav",
  UNLISTED_SECURITIES_TRADE_HISTORY_HIGH_LABEL: "Høy",
  UNLISTED_SECURITIES_TRADE_HISTORY_MISSING: "Historisk omsetning mangler",

  UNLISTED_SECURITIES_TRADE_HISTORY_FOOTER:
    "Oversikten er begrenset til siste omsetninger registrert hos Norne og kan således ikke legges til grunn som en fullstendig oversikt",

  UNLISTED_SECURITIES_EQUITY_TRADE_GRAPH_TITLE: "Siste omsatte",

  UNLISTED_SECURITIES_DEVELOPMENT_TEXT:
    "Grafen viser historiske kurser på transaksjoner foretatt hos Norne. Aksjene/egenkapitalbevisene/tegningsrettene kan ha blitt omsatt på andre kurser. Norne Securities bærer ikke noe ansvar for registrerte kurser.",

  DOCUMENTS_TITLE: "Dokumenter",
  DOCUMENTS_INGRESS: "Her finner du dokumenter som er relevant for deg som kunde hos Norne Securities.",
  DOCUMENTS_FAILED_GETTING_DOCUMENTS: "Det oppstod en feil under henting av dokumenter. Vennligst prøv igjen senere.",
  CUSTOMER_AGREEMENTS_NO_AGREEMENTS:
    "Vi har ingen elektroniske avtaler tilgjengelig for deg. Dersom du har spørsmål, ta kontakt med kundeservice.",
  CUSTOMER_AGREEMENTS_HEADER_TITLE: "Tittel",
  CUSTOMER_AGREEMENTS_HEADER_CREATED: "Opprettet",
  CUSTOMER_AGREEMENTS_HEADER_DOWNLOAD: "Last ned",
  CUSTOMER_AGREEMENTS_DOCUMENT_TITLE: "Kundeavtale",

  SIGNING_TITLE: "Signeringsoppdrag",
  SIGNING_INGRESS: "Her finner du dokumenter som er klare for signering.",
  SIGNING_NO_SIGNINGS: "Du har ingen ventende signeringsoppdrag.",
  SIGNING_FAILED_GETTING_SIGNINGS: "Det oppstod en feil under henting av signeringsoppdrag. Vennligst prøv igjen senere.",

  SIGNING_HEADER_TITLE: "Signeringer",
  SIGNING_GO_TO_SIGNING_LINK_TEXT: "Gå til signering",

  SAVING_STREAK_TEXT: "Din sparestreak er nå på $STREAK måneder.",
  SAVING_STREAK_KEEP_SAVING_TEXT: "Fortsett den gode sparingen!",
  SAVING_STREAK_START_SAVING_TEXT: "Start sparing",
  SAVING_STREAK_TITLE: "Sammenhengende sparing",

  NON_CUSTOMER_TITLE: "Ikke sparekunde i $BANK_NAME",
  NON_CUSTOMER_INGRESS:
    "Ditt kundeforhold i Norne er ikke gjennom $BANK_NAME. Du kan enten opprette et nytt kundeforhold med $BANK_NAME, eller logge på et eksisterende.",
  NON_CUSTOMER_SIGN_UP_BUTTON_TEXT: "Bli kunde",
  NON_CUSTOMER_SIGN_IN_AS_OTHER_BUTTON_TEXT: "Logg på med annet kundeforhold",

  HOLDINGS_TRANSFER_TITLE: "Overføring av verdipapirer",
  HOLDINGS_TRANSFER_INGRESS: "Her kan du overføre verdipapirer til Norne Securities.",
  HOLDINGS_TRANSFER_BANK_OR_BROKER_LABEL: "Fra bank/megler",
  HOLDINGS_TRANSFER_BANK_OR_BROKER_SELECT_LABEL: "Velg selskap",
  HOLDINGS_TRANSFER_BANK_OR_BROKER_PLACEHOLDER: "Ikke i listen? Skriv inn her",
  HOLDINGS_TRANSFER_BANK_OR_BROKER_REQUIRED: "Vennligst fyll ut bank/megler",
  HOLDINGS_TRANSFER_VPS_ACCOUNT_LABEL: "Fra VPS-konto",
  HOLDINGS_TRANSFER_VPS_ACCOUNT_INVALID: "VPS-kontonummer er ugyldig",
  HOLDINGS_TRANSFER_PORTFOLIO_REQUIRED: "Vennligst fyll ut konto",
  HOLDINGS_TRANSFER_VPS_ACCOUNT_REQUIRED: "Vennligst fyll ut VPS-konto",
  HOLDINGS_TRANSFER_PORTFOLIO_LABEL: "Til konto",
  HOLDINGS_TRANSFER_EQUITY_LABEL:
    "Jeg gir herved Norne Securities As fullmakt til å flytte min beholdning fra min/vår konto hos angitte bank/megler.",
  HOLDINGS_TRANSFER_EQUITY_ALL_LABEL: "Flytt hele min beholdning",
  HOLDINGS_TRANSFER_EQUITY_PART_LABEL: "Flytt deler av min beholdning",
  HOLDINGS_TRANSFER_SUBMIT_FORM_BUTTON_TEXT: "Send inn",
  HOLDINGS_TRANSFER_HOLDINGS_NAME_LABEL: "Legg til verdipapir",
  HOLDINGS_TRANSFER_HOLDINGS_QUANTITY_LABEL: "Antall",
  HOLDINGS_TRANSFER_HOLDINGS_AVERAGE_PRICE_LABEL: "Snittkurs for kjøp (valgfritt)",
  HOLDINGS_TRANSFER_ADD_HOLDINGS_LABEL: "Legg til",
  HOLDINGS_TRANSFER_ADD_HOLDINGS_NAME_PLACEHOLDER: "Navn",
  HOLDINGS_TRANSFER_NAME_REQUIRED: "Vennligst fyll ut navn",
  HOLDINGS_TRANSFER_QUANTITY_REQUIRED: "Vennligst fyll ut antall",
  HOLDINGS_TRANSFER_AVERAGE_PRICE_NOT_NUMBER: "Snittkurs må være et tall",
  HOLDINGS_TRANSFER_ADD_HOLDINGS_QUANTITY_PLACEHOLDER: "Antall",
  HOLDINGS_TRANSFER_HOLDINGS_LIST_NAME_LABEL: "Navn",
  HOLDINGS_TRANSFER_HOLDINGS_LIST_QUANTITY_LABEL: "Antall",
  HOLDINGS_TRANSFER_HOLDINGS_LIST_AVERAGE_PRICE_LABEL: "Snittkurs",
  HOLDINGS_TRANSFER_HOLDINGS_LIST_TITLE_LABEL: "Verdipapirer",
  HOLDINGS_TRANSFER_HOLDINGS_LIST_EMPTY_LABEL: "Ingen verdipapirer lagt til",
  HOLDINGS_TRANSFER_HOLDINGS_REQUIRED: "Vennligst legg minst ett verdipapir",
  HOLDINGS_TRANSFER_QUANITY_REQUIRED:
    "Antall mangler for ett eller flere verdipapir, det er påkrevd når man velger å flytte deler av beholdningen",

  IPS_TRANSFER_NO_IPS_ACCOUNT_1: "For å flytte din IPS sparing til oss må du først opprette IPS-konto hos oss. Klikk ",
  IPS_TRANSFER_NO_IPS_ACCOUNT_2: " for å opprette IPS-konto.",
  IPS_TRANSFER_NO_IPS_ACCOUNT_LINK_TEXT: "her",

  HOLDINGS_TRANSFER_GENERAL_API_ERROR:
    "Det oppstod en feil under oppretting av oppdrag for overføring av verdipapirer. Vennligst prøv igjen senere.",

  IPS_TRANSFER_ACCEPT_INSURANCE_STOP_LABEL_1:
    "Jeg er kjent med at eventuelle forsikringselementer i avtale(r) nevnt over vil opphøre i forbindelse med flyttingen og at det kun er pensjonskapitalen som flyttes. Det vises før øvrig til ",
  IPS_TRANSFER_ACCEPT_INSURANCE_STOP_LABEL_2: " for IPS gjennom Norne Securities AS",
  IPS_TRANSFER_ACCEPT_INSURANCE_STOP_LINK_TEXT: "avtalevilkår",
  IPS_TRANSFER_ACCEPT_INSURANCE_STOP_REQUIRED: "Vennligst bekreft at du er kjent med at forsikringselementer vil opphøre",

  HOLDINGS_TRANSFER_SIGNING_TITLE: "Signer overføring av verdipapirer",
  HOLDINGS_TRANSFER_SIGNING_LIST_INGRESS:
    "For å fullføre overføringsoppdraget må du signere følgende dokumenter. Dokumentene vil også bli sendt på e-post og man kan finne de på ",

  SIGNING_COMPLETE_PAGE_TITLE: "Signering fullført",
  SIGNING_COMPLETE_PAGE_TEXT: "Takk for gjennomført signering",
  SIGNING_COMPLETE_HOLDINGS_TRANSFER_PAGE_TEXT:
    "Takk for gjennomført signering. Norne Securities vil ta kontakt med din bank og videre behandle overføringen av dine verdipapirer.",
  SIGNING_COMPLETE_IPS_TRANSFER_PAGE_TEXT:
    "Takk for gjennomført signering. Norne Securities vil ta kontakt med din nåværende leverandør og videre behandle overføringen av din IPS-konto.",

  HOLDINGS_TRANSFER_COMPANY_SIGNEES_LIST_TITLE_LABEL: "Signaturberettigede",
  HOLDINGS_TRANSFER_COMPANY_SIGNEES_LIST_EMPTY_LABEL: "Ingen signaturberettigede lagt til",
  HOLDINGS_TRANSFER_ADD_SIGNEE_TITLE_LABEL: "Legg til signaturberettiget",
  HOLDINGS_TRANSFER_SIGNEE_SSN_LABEL: "Fødselsnummer",
  HOLDINGS_TRANSFER_SIGNEE_SSN_REQUIRED: "Vennligst fyll ut fødselsnummer",
  HOLDINGS_TRANSFER_SIGNEE_SSN_INVALID: "Ugyldig fødselsnummer",
  HOLDINGS_TRANSFER_SIGNEE_FIRST_NAME_LABEL: "Fornavn",
  HOLDINGS_TRANSFER_SIGNEE_FIRST_NAME_REQUIRED: "Vennligst fyll ut fornavn",
  HOLDINGS_TRANSFER_SIGNEE_LAST_NAME_LABEL: "Etternavn",
  HOLDINGS_TRANSFER_SIGNEE_LAST_NAME_REQUIRED: "Vennligst fyll ut etternavn",
  HOLDINGS_TRANSFER_SIGNEE_PHONE_LABEL: "Telefonnummer",
  HOLDINGS_TRANSFER_SIGNEE_PHONE_REQUIRED: "Vennligst fyll ut telefonnummer",
  HOLDINGS_TRANSFER_SIGNEE_PHONE_INVALID: "Ugyldig telefonnummer",
  HOLDINGS_TRANSFER_SIGNEE_EMAIL_LABEL: "E-post",
  HOLDINGS_TRANSFER_SIGNEE_EMAIL_REQUIRED: "Vennligst fyll ut e-post",
  HOLDINGS_TRANSFER_SIGNEE_EMAIL_INVALID: "Ugyldig e-post",
  HOLDINGS_TRANSFER_ADD_SIGNEE_ABORT_BUTTON_TEXT: "Avbryt",
  HOLDINGS_TRANSFER_ADD_SIGNEE_BUTTON_TEXT: "Legg til",
  HOLDINGS_TRANSFER_EDIT_SIGNEE_BUTTON_TEXT: "Lagre",
  HOLDINGS_TRANSFER_ADD_SIGNEE_DIALOG_BUTTON_TEXT: "Legg til signaturberettiget",
  HOLDINGS_TRANSFER_ADD_ME_BUTTON_TEXT: "Legg til meg som signaturberettiget",

  HOLDINGS_TRANSFER_COMAPNY_SIGNEES_REQUIRED: "Vennligst legg til minst en signaturberettiget",

  IPS_TRANSFER_TITLE: "Flytting av Individuell pensjonsparing (IPS)",
  IPS_TRANSFER_INGRESS: "Her kan du flytte din IPS-konto til Norne Securities.",
  IPS_TRANSFER_SUBMIT_FORM_BUTTON_TEXT: "Send inn",

  IPS_TRANSFER_AGREEMENT_LIST_TITLE:
    "Jeg gir herved Norne Securities AS fullmakt til å flytte og innhente alle relevante opplysninger om følgende avtaler:",
  IPS_TRANSFER_AGREEMENT_LIST_EMPTY_LABEL: "Ingen avtaler lagt til",
  IPS_TRANSFER_AGREEMENT_LIST_NAME_LABEL: "Selskap",
  IPS_TRANSFER_AGREEMENT_LIST_AGREEMENT_NUMBER_LABEL: "Avtalenummer",

  IPS_TRANSFER_AGREEMENT_NAME_LABEL: "Selskap",
  IPS_TRANSFER_AGREEMENT_SELECT_COMPANY: "Velg selskap",
  IPS_TRANSFER_AGREEMENT_NAME_PLACEHOLDER: "Ikke i listen? Skriv inn her",
  IPS_TRANSFER_AGREEMENT_NAME_REQUIRED: "Vennligst fyll ut selskap",
  IPS_TRANSFER_AGREEMENT_NUMBER_LABEL: "Avtalenummer",
  IPS_TRANSFER_AGREEMENT_NUMBER_REQUIRED: "Vennligst fyll ut avtalenummer",
  IPS_TRANSFER_ADD_AGREEMENT_LABEL: "Legg til avtale",

  IPS_TRANSFER_AGREEMENTS_REQUIRED: "Vennligst legg til minst en avtale",
  IPS_TRANSFER_AGREEMENTS_ABOVE_LIMIT: "Du kan ikke legge til mer enn 3 avtaler",

  IPS_TRANSFER_TO_FUND_NAME_REQUIRED: "Vennligst fyll ut fond",
  IPS_TRANSFER_TO_FUND_NAME_LABEL: "Til fond",
  IPS_TRANSFER_TO_FUND_PERCENTAGE_REQUIRED: "Vennligst fyll ut andel",
  IPS_TRANSFER_TO_FUND_PERCENTAGE_LABEL: "Andel i prosent",
  IPS_TRANSFER_ADD_TO_FUND_LABEL: "Legg til fond",

  IPS_TRANSFER_TO_FUND_LIST_TITLE:
    "Flyttende avtaler skal investeres i følgende fond (Fondsutvalg finnes på handelsportal hos Norne):",
  IPS_TRANSFER_TO_FUND_CUSTOM_FUNDS_LABEL: "Egendefinerte fond",
  IPS_TRANSFER_TO_FUND_LIST_EMPTY_LABEL: "Ingen fond lagt til",
  IPS_TRANSFER_TO_FUND_LIST_NAME_LABEL: "Fondsnavn",
  IPS_TRANSFER_TO_FUND_LIST_PERCENTAGE_LABEL: "Andel i prosent",

  IPS_TRANSFER_TO_FUNDS_REQUIRED: "Vennligst legg til minst ett fond",
  IPS_TRANSFER_TO_FUNDS_ABOVE_LIMIT: "Du kan ikke legge til mer enn 5 fond",
  IPS_TRANSFER_TO_FUNDS_PERCENTAGE_NOT_EQUAL_TO_100: "Total andel må være 100%",

  IPS_TRANSFER_DEFAULT_FUND_LABEL: "Norne Kombi 50",
  IPS_TRANSFER_DEFAULT_FUND_TEXT_1: "For å gjøre sparing i fond enklere har vi satt sammen en portefølje: ",
  IPS_TRANSFER_DEFAULT_FUND_TEXT_2:
    " er et vedlikeholdsfritt kombinasjonsfond bestående av et utvalg andre underliggende fond der du slipper å tenke på om du sparer rett. Ved å spare i Norne Kombi 50 sprer du risikoen på flere fond. ",
  IPS_TRANSFER_DEFAULT_FUND_LINK_TEXT: "Norne Kombi 50",

  EPK_PAGE_BACK_BUTTON_TEXT: "Tilbake",
  EPK_PAGE_NEXT_BUTTON_TEXT: "Neste",

  EPK_PAGE_1_TITLE: "Egen pensjonskonto (EPK)",
  EPK_PAGE_1_SUB_TITLE: "Flytt pensjonen din til Norne",
  EPK_PAGE_1_TEXT: "Samle all din sparing. Gjør det enklere for deg å ha kontroll. Vi fikser alt annet.",
  EPK_PAGE_1_BUTTON_TEXT: "Vis hva jeg kan flytte",

  EPK_PAGE_1_PORTFOLIO_HELP_TITLE: "Bakgrunn",
  EPK_PAGE_1_PORTFOLIO_HELP_TEXT_1:
    "Fra 2021 fikk1,5 millioner arbeidstakere i privat sektor samlet sin pensjonsopptjening fra innskuddspensjonsordninger på en egen pensjonskonto.",
  EPK_PAGE_1_PORTFOLIO_HELP_TEXT_2:
    "Pensjonskontoen skal følge arbeidstakeren gjennom ulike arbeidsforhold og gi bedre oversikt over pensjonssparingen.",
  EPK_PAGE_1_PORTFOLIO_HELP_TEXT_3: "Målet er også lavere kostnader og dermed mer pensjon for hver sparte krone.",

  EPK_PAGE_1_COSTS_HELP_TITLE: "Mer om kostnader",
  EPK_PAGE_1_COSTS_HELP_TEXT_1: "Arbeidsgiver dekker kostnadene dine.",
  EPK_PAGE_1_COSTS_HELP_TEXT_2:
    "Om kostnadene bedriften betaler er lavere enn du betaler for din EPK beholder du mellomlegget som ekstra pensjonssparing.",
  EPK_PAGE_1_COSTS_HELP_TEXT_3: "Lavere kostnader og ingen administrasjonsgebyr gir mer penger som pensjonist.",
  EPK_PAGE_1_COSTS_HELP_TEXT_4: "Om du ombestemmer deg kan du når som helst velge en annen leverandør.",

  EPK_PAGE_1_COSTS_TITLE: "Kostnader",
  EPK_PAGE_1_COSTS_OPEN_ACCOUNT_LABEL: "Åpne konto:",
  EPK_PAGE_1_COSTS_OPEN_ACCOUNT_VALUE: "0 kr",
  EPK_PAGE_1_COSTS_TRADE_LABEL: "Kjøp-/salgskostnader:",
  EPK_PAGE_1_COSTS_TRADE_VALUE: "0 kr",
  EPK_PAGE_1_COSTS_MOVE_LABEL: "Flyttekostnad:",
  EPK_PAGE_1_COSTS_MOVE_VALUE: "0 kr",

  EPK_PAGE_1_COSTS_ADMIN_LABEL: "Administrasjonskostnader:",
  EPK_PAGE_1_COSTS_ADMIN_VALUE: "0 kr",
  EPK_PAGE_1_COSTS_AGE_ADJUSTING_LABEL: "Automatisk alderstilpasning:",
  EPK_PAGE_1_COSTS_AGE_ADJUSTING_VALUE: "0 kr",
  EPK_PAGE_1_COSTS_FUND_BALANCING_LABEL: "Løpende balansering av fond:",
  EPK_PAGE_1_COSTS_FUND_BALANCING_VALUE: "0 kr",
  EPK_PAGE_1_COSTS_YEARLY_LABEL: "Årlig pris:",
  EPK_PAGE_1_COSTS_YEARLY_VALUE: "0,35 %",

  EPK_PAGE_2_TITLE: "Pensjonsavtalene dine",
  EPK_PAGE_2_TEXT: "Gjennom Norsk Pensjon henter vi inn opplysninger om hvor mye du har i oppspart pensjon.",
  EPK_PAGE_2_FOOTER_TEXT: "PS: Dersom du kun har jobbet i det offentlige vil ikke du ha noen innskuddspensjoner som kan flyttes.",
  EPK_PAGE_2_FOOTER_2_TEXT: "Dersom du ikke ønsker flytte, sletter vi dataene vi henter fra Norsk Pensjon med en gang.",
  EPK_PAGE_2_BUTTON_TEXT: "Logg inn med BankID",

  EPK_PAGE_2_HELP_TEXT: "Dersom du ikke ønsker flytte, sletter vi dataene vi henter fra Norsk Pensjon med en gang.",

  EPK_PAGE_3_TITLE: "Dette kan flyttes",
  EPK_PAGE_3_AGREEMENT_NUMBER_LABEL: "Avtale nummer",
  EPK_PAGE_3_EARLIEST_PAYOUT_AGE_LABEL: "Tidligste utbetalingsstart",
  EPK_PAGE_3_EMPLOYER: "Arbeidsgiver",

  EPK_PAGE_4_TITLE: "Hvordan vil pensjonen min bli forvaltet?",
  EPK_PAGE_4_INGRESS: "Pensjonen din vil få påfyll så lenge du står i jobb og kan ikke brukes før tidligst ved 62 år.",
  EPK_PAGE_4_ADMINISTRATION_CAPTION_1: "Høy aksjeandel:",
  EPK_PAGE_4_ADMINISTRATION_TEXT_1:
    "med jevnlig påfyll og lenge til uttak kan og bør man ha høy andel aksjefond for å få best mulig avkastning.",
  EPK_PAGE_4_ADMINISTRATION_CAPTION_2: "Alderstilpasning:",
  EPK_PAGE_4_ADMINISTRATION_TEXT_2:
    "Når du nærmer deg pensjonsalder vil vi automatisk bytte ut deler av aksjefondssparingen med rentefond som svinger mindre i verdi.",
  EPK_PAGE_4_ADMINISTRATION_CAPTION_3: "Unngå fallgruver:",
  EPK_PAGE_4_ADMINISTRATION_TEXT_3:
    "stor spredning av plasseringene for å minimere risiko for feiltrinn med noe så viktig som pensjon.",
  EPK_PAGE_4_ADMINISTRATION_CAPTION_4: "Lave kostnader:",
  EPK_PAGE_4_ADMINISTRATION_TEXT_4: "for å ha så god pensjon som mulig må minst mulig bli spist på veien av kostnader.",

  EPK_PAGE_4_PAYOUT_LABEL: "Når vil du ha pengene utbetalt?",
  EPK_PAGE_4_RISK_LABEL: "Jeg ønsker lavere risiko enn anbefalt",

  EPK_PAGE_5_TITLE: "Portefølje",

  EPK_PAGE_5_FUNDS: "Fordeling fond",
  EPK_PAGE_5_REGIONS: "Regioner",

  EPK_PAGE_5_ADMINISTRATION_HELP_TITLE: "Mer om forvaltningen",
  EPK_PAGE_5_ADMINISTRATION_HELP_SELECTION_LABEL: "Utvelgelse:",
  EPK_PAGE_5_ADMINISTRATION_HELP_SELECTION_TEXT:
    "Porteføljen av fond er satt sammen av Norne med fokus på høy grad av diversifisering (mellom selskaper og land) og kostnader.",
  EPK_PAGE_5_ADMINISTRATION_HELP_INDEX_FUND_LABEL: "Indeksfond:",
  EPK_PAGE_5_ADMINISTRATION_HELP_INDEX_FUND_TEXT:
    "Porteføljen vil bestå av indeksfond og/eller andre lavkost fond med regelstyrt forvaltning.",
  EPK_PAGE_5_ADMINISTRATION_HELP_REPLACEMENTS_LABEL: "Utskiftninger:",
  EPK_PAGE_5_ADMINISTRATION_HELP_REPLACEMENTS_TEXT:
    "Fondene kan skiftes ut med andre fond dersom det vurderes som formålstjenlig.",
  EPK_PAGE_5_ADMINISTRATION_HELP_BALANCE_LABEL: "Balanse:",
  EPK_PAGE_5_ADMINISTRATION_HELP_BALANCE_TEXT:
    "Vekting mellom de forskjellige fondene holdes tilnærmet konstant gjennom rebalanseringer ved større markedssvingninger. Dette er en metodikk som benyttes av oljefondet og andre profesjonelle investorer.",

  EPK_PAGE_4_YIELD_HELP_TITLE: "Avkastning",
  EPK_PAGE_4_YIELD_HELP_TEXT_1:
    "Å si noe bastant om fremtiden er alltid vanskelig, men at den vil rime med fortiden er vi trygge på.",
  EPK_PAGE_4_YIELD_HELP_TEXT_2:
    "Brede porteføljer av aksjer har, så lenge det har vært målbart (Siden ~1900), gitt høy avkastning så lenge man har vært investert lenge nok.",
  EPK_PAGE_4_YIELD_HELP_TEXT_3: "Real-avkastning (avkastning fratrukket inflasjon) i verdens kapitalmarkeder siden 1950",

  EPK_PAGE_4_AGE_ADJUSTMENT_HELP_TITLE: "Alderstilpasning",
  EPK_PAGE_4_AGE_ADJUSTMENT_HELP_TEXT:
    "Frem til du blir 60 år vil du ha 100% aksjefond. Deretter trappes risikoen jevnt ned til 50 % når du er 67 år, og videre til 20% når du fyller 75 år.",

  PAGE_5_TYPE_COLUMN_HEADER: "Type",
  PAGE_5_NAME_COLUMN_HEADER: "Fond",
  PAGE_5_SUSTAINABILITY_COLUMN_HEADER: "Bærekraft",
  PAGE_5_SFDR_COLUMN_HEADER: "SFDR",
  PAGE_5_PERCENTAGE_COLUMN_HEADER: "Fordeling",
  PAGE_5_KIID_COLUMN_HEADER: "KIID",

  EPK_PAGE_5_REGIONS_TITLE: "Regioner",

  EPK_PAGE_5_TYPE_DISTRIBUTION_TITLE: "Fordeling",
  EPK_PAGE_5_TYPE_DISTRIBUTION_STOCKS_TEXT: "Aksjer: $PERCENT%",
  EPK_PAGE_5_TYPE_DISTRIBUTION_INTERESTS_TEXT: "Renter: $PERCENT%",

  EPK_PAGE_5_PANIC_BUTTON_TITLE: "Panikk knapp",
  EPK_PAGE_5_PANIC_BUTTON_TEXT:
    "Ved aksje-bekymring kan du ved ett trykk justere risikoen i pensjonen din ned til 20% aksjeandel.",

  EPK_PAGE_5_PANIC_BUTTON_HELP_TITLE: "Panikk-knapp",
  EPK_PAGE_5_PANIC_BUTTON_HELP_TEXT_1: "Ved ekstra bekymring er det de som helst ønsker å minimere risiko i all sparing.",
  EPK_PAGE_5_PANIC_BUTTON_HELP_TEXT_2:
    "Det store problemet med at man som sparer endrer aksje-andel er at det gjøres på feil tidspunkt og/eller man tar aldri risikoen opp igjen.",
  EPK_PAGE_5_PANIC_BUTTON_HELP_TEXT_3:
    "For å løse dette vil du, ved å trykke på Panikk-knappen redusere aksjeandel til 2%, og 6 måneder senere vil vi øke risiko tilbake til opprinnelig aksjeandel.",

  EPK_PAGE_5_COSTS_TITLE: "Kostnader",
  EPK_PAGE_5_COSTS_FUND_LABEL: "Fondskostnad",
  EPK_PAGE_5_COSTS_FUND_VALUE: "0,10%",
  EPK_PAGE_5_COSTS_BANK_LABEL: "Bankhonorar",
  EPK_PAGE_5_COSTS_BANK_VALUE: "0,25%",
  EPK_PAGE_5_COSTS_TOTAL_LABEL: "Totalkostnad",
  EPK_PAGE_5_COSTS_TOTAL_VALUE: "0,35%",

  EPK_PAGE_5_COSTS_HELP_TITLE: "Kostnadsdetaljer per fond:",

  EPK_PAGE_5_YEARLY_YIELD_TITLE: "Forventet årlig avkastning",

  EPK_PAGE_5_YEARLY_YIELD_HELP_TITLE: "Forventet avkastning",
  EPK_PAGE_5_YEARLY_YIELD_HELP_TEXT:
    "Spare-anbefalingen har en forventet avkastning på 5.45% i året, men vil svinge. Denne svigningsrisikoen skal i 2 av 3 år ligge mellom -8% og 18.9%. Her er ikke skatt, inflasjon og kostnader hensyntatt og forventningene er hentet fra Finans Norge sine avkastningsprognoser.",

  EPK_PAGE_6_TITLE: "Oppsummering",
  EPK_PAGE_6_TEXT: "Flytt tjenestepensjon fra $CURRENT_ISP_PROVIDER med beholdning $AMOUNT kr til Norne Egen Pensjonskonto",
  EPK_PAGE_6_BUTTON_TEXT: "Bekreft flytting",

  EPK_PAGE_7_TITLE: "Gratulerer med et godt valg!",
  EPK_PAGE_7_TEXT: "Flyttingen vil forventningsvis være fullført ila de neste 14 dagene.",
  EPK_PAGE_7_BUTTON_TEXT: "Til min oversikt",
};
